import { Component, OnInit, Input, ElementRef, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';


import { RespuestaApi } from '../../interfaces/respuesta-api';
import { DireccionEmpresaCompleta, TipoDireccion, DireccionEmpresa } from '../../interfaces/direcciones-empresa';
import { DireccionesService } from '../../services/direcciones.service';
import { RootService } from './../../services/root.service';
import { Comuna } from '../../interfaces/comuna';


@Component({
  selector: 'app-direccion-empresa',
  templateUrl: './direccion-empresa.component.html',
  styleUrls: ['./direccion-empresa.component.scss']
})
export class DireccionEmpresaComponent implements OnInit {


  @Input() modalRef: BsModalRef;
  @Input() set empresaId(value) {

    this.idEmpresa = value;
    this.inicializarformulario();
    this.listarComunas();


    if (value == null) {
      this.obtenerTipoDirecciones();
      return;
    } else {
      this.listarDirecciones(value);
    }
  }

  @Input() set btnNuevo(val) {
    if (val) {
      this.nuevo();
    }
  }

  @Input() set btnGuardar(val) {
    // console.log(val);
    if (val) {
      this.guardar();
    }
  }

  @Input() set btnValidar(val) {
    if (val) {
      this.validar();
    }
  }


  @Output() actualizar = new EventEmitter<any>();
  @Output() formulario = new EventEmitter<any>();


  formDireccion: FormGroup;
  listadoDirecciones: DireccionEmpresaCompleta[];
  tipoDirecciones: TipoDireccion[] = [];
  listadoComunas: Comuna[];
  titulo = '';
  cargando = false;
  idEmpresa: string;
  mensajeDirecciones = {
    mostrar: false,
    mensaje: ''
  };


  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private direccionesService: DireccionesService,
    private root: RootService
  ) { }

  ngOnInit() {
    this.titulo = 'Direcciones';
    this.inicializarformulario();
  }


  inicializarformulario() {
    this.formDireccion = this.fb.group({
      direcciones: this.fb.array([]),
      error: null,
    });
  }

  procesarDatosFormulario(data: DireccionEmpresaCompleta[], tipos: TipoDireccion[]) {

    const direcciones = this.formDireccion.controls.direcciones as FormArray;

    let i = 0;
    for (const item of data) {

      const objDir = this.fb.group({
        id: item.id,
        calle: [item.calle, Validators.required],
        numero: [item.numero, Validators.required],
        oficina: item.oficina,
        comuna_id: [item.comuna_id, Validators.required],
        tipoDirecciones: this.fb.array([]),
        edicionActivada: false,
        cambios: false,
        comunaNombre: item.comuna.nombre
        // principal: item.principal,

      });

      direcciones.push(objDir);

      // tslint:disable-next-line: no-string-literal
      const tipoDireccion = direcciones.controls[i]['controls']['tipoDirecciones'] as FormArray;

      for (const tipo of tipos) {
        const obj = this.fb.group({
          id: tipo.id,
          slug: tipo.slug,
          nombre: tipo.nombre,
          check: this.existeTipoDireccion(tipo.id, item.direccionEmpresa)
        });
        tipoDireccion.push(obj);
      }

      i++;
    }
  }

  existeTipoDireccion(tipoDireccionId, direcciones: DireccionEmpresa[]) {
    const resultado = direcciones.find(item => item.tipo_direccion_id === tipoDireccionId);
    if (resultado) {
      return true;
    }
    return false;
  }

  quitarControl(posicion: number) {
    const direcciones = this.formDireccion.controls.direcciones as FormArray;
    direcciones.removeAt(posicion);
  }

  listarDirecciones(empresaId) {
    this.cargando = true;
    this.direccionesService.obtenerDireccionesEmpresaCompleta(empresaId).subscribe((r: RespuestaApi) => {
      // console.log(r);
      this.listadoDirecciones = r.data;

      // obtiene tipo de direccion
      this.direccionesService.obtenerTipoDirecciones().subscribe((r: RespuestaApi) => {
        this.tipoDirecciones = r.data;
        this.cargando = false;
        this.procesarDatosFormulario(this.listadoDirecciones, this.tipoDirecciones);
        this.formulario.emit(this.formDireccion);

      }, e => {
        this.root.validaError(e);
      });



    }, e => {
      this.root.validaError(e);
    });
  }

  obtenerTipoDirecciones() {
    // obtiene tipo de direccion
    this.direccionesService.obtenerTipoDirecciones().subscribe((r: RespuestaApi) => {
      this.tipoDirecciones = r.data;
    }, e => {
      this.root.validaError(e);
    });

  }



  listarComunas() {
    this.direccionesService.obtenerComunas().subscribe((r: RespuestaApi) => {
      this.listadoComunas = r.data;
    }, e => {
      this.root.validaError(e);
    });
  }


  /**
   * Valida el formulario, necesario cuando se hace de un componente padre
   */
  validar() {

    this.mensajeDirecciones.mostrar = false;

    if (this.formDireccion.value.direcciones.length === 0) {
      this.mensajeDirecciones.mostrar = true;
      this.mensajeDirecciones.mensaje = 'Debe ingresar al menos una dirección';
    }

    this.formDireccion.updateValueAndValidity();
    this.formulario.emit(this.formDireccion);
    if (this.formDireccion.invalid) {
      this.formDireccion.markAllAsTouched();
      return;
    }
  }


  guardar() {
    if (this.formDireccion.invalid) {
      // this.toastr.error(mensajes.formularioConErrores, mensajes.tituloError);
      this.formDireccion.markAllAsTouched();
      return;
    }

    this.cargando = true;
    const dataPost = { ...this.formDireccion.value, empresa_id: this.idEmpresa };
    this.direccionesService.guardarDireccionesTipos(dataPost).subscribe((r: RespuestaApi) => {
      // this.toastr.success(r.mensaje, mensajes.tituloCorrecto);
      this.actualizar.emit(true);
      this.cargando = false;

    }, e => {
      this.root.validaError(e);
    });

  }

  nuevo() {
    this.mensajeDirecciones.mostrar = false;
    const direcciones = this.formDireccion.controls.direcciones as FormArray;

    const objDir = this.fb.group({
      id: [null],
      calle: [null, Validators.required],
      numero: [null, Validators.required],
      oficina: null,
      comuna_id: [null, Validators.required],
      tipoDirecciones: this.fb.array([]),
      edicionActivada: true,
      cambios: true,
      comunaNombre: null
    });
    direcciones.push(objDir);

    const position = direcciones.controls.length - 1;
    const tipoDireccion = direcciones.controls[position]['controls']['tipoDirecciones'] as FormArray;

    for (const tipo of this.tipoDirecciones) {
      const obj = this.fb.group({
        id: tipo.id,
        nombre: tipo.nombre,
        slug: tipo.slug,
        check: true
      });
      tipoDireccion.push(obj);
    }
  }

  habilitarEdicion(posicion: number, habilitar = true) {
    const direcciones = this.formDireccion.controls.direcciones as FormArray;
    direcciones.controls[posicion].patchValue({ edicionActivada: habilitar });
    direcciones.controls[posicion].patchValue({ cambios: true });
  }



}

