import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
// import localeIt from '@angular/common/locales/it';
import localeCL from '@angular/common/locales/es-CL';

registerLocaleData(localeCL, 'CLP');

// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';

// modules
import { AppRoutingModule } from './app-routing.module';
import { BlocksModule } from './modules/blocks/blocks.module';
import { HeaderModule } from './modules/header/header.module';
import { HeaderB2bModule } from '../app/modules/account/components/header/header-b2b.module';
import { MobileModule } from './modules/mobile/mobile.module';
import { SharedModule } from './shared/shared.module';
import { WidgetsModule } from './modules/widgets/widgets.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalStorageModule } from 'angular-2-local-storage';

// components
import { AppComponent } from './app.component';

// pages
import { PageHomeOneComponent } from './pages/page-home-one/page-home-one.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageRegistroComponent } from './pages/page-registro/page-registro.component';
import { B2bComponent } from './layout/b2b/b2b.component';
import { B2cComponent } from './layout/b2c/b2c.component';
import { FooterModule } from './modules/footer/footer.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { BasicAuthInterceptor } from './basic-auth.interceptor';
import { NgtUniversalModule } from '@ng-toolkit/universal';// import { ErrorInterceptor } from './error.interceptor';
import { environment } from '../environments/environment';
import { RaramaPageCargoComponent } from './modules/rarama-web/pages/rarama-page-cargo/rarama-page-cargo.component';


@NgModule({
    declarations: [
        // components
        AppComponent,
        // pages
        PageHomeOneComponent,
        PageNotFoundComponent,
        PageRegistroComponent,
        RaramaPageCargoComponent,
        B2bComponent,
        B2cComponent
    ],
    imports: [
        // modules (angular)
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        LocalStorageModule.forRoot({
            prefix: environment.nombreSessionLocalStorage,
            storageType: 'localStorage'
        }),
        // modules (third-party)
        CarouselModule,
        ToastrModule.forRoot(),
        // modules
        AppRoutingModule,
        BlocksModule,
        HeaderModule,
        HeaderB2bModule,
        FooterModule,
        MobileModule,
        SharedModule,
        WidgetsModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ModalModule,
        TooltipModule.forRoot(),
        NgtUniversalModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'CLP' },
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        Title,
        BsModalRef
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
