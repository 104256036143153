import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresaSubsegmentosService {

  constructor(private http: HttpClient) { }

  listar(params = {}) {
    return this.http.get(environment.apiMountain + 'empresa-subsegmentos', { params });
  }
}
