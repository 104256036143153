import { Injectable, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as io from 'socket.io-client';
import { RootService } from './root.service';
import { Usuario } from '../interfaces/login';
import { Subject, Observable } from 'rxjs';
import { Rom } from '../interfaces/chat';
import { LoginService } from './login.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class ChatService {
    [x: string]: any;

    socket;
    user: Usuario;
    isRoomActive = false;
    public conversations: Rom = {
        bot: true,
        estado: '',
        mensajes: []
    };
    private chatList$: Subject<any> = new Subject();
    readonly chatListObs$: Observable<any> = this.chatList$.asObservable();


    private numMsg$: Subject<number> = new Subject();
    readonly numMsgObs$: Observable<number> = this.numMsg$.asObservable();
    public msgNoRead = 0;



    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private http: HttpClient,
        private root: RootService,
        private loginService: LoginService,
        private router: Router
    ) {
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {

            if (isPlatformBrowser(this.platformId)) {
                this.getListMsj();
            }
        });

        this.user = this.root.getDataSesionUsuario();
        if (isPlatformBrowser(this.platformId)) {

            this.socket = io(environment.apiChatSocket);

            this.socketSubscribe();
            this.socketListMsj();
            this.updateChatEstado();


            this.loginService.loginSessionObs$.pipe().subscribe(usuario => {
                this.user = this.root.getDataSesionUsuario();
                this.socket.emit('newRom', this.cleanId(this.user.id));
                this.socket.emit('listMsjB2B', this.cleanId(this.user.id));
            });

        }

    }

    // ngOnDestroy(): void {


    // }

    cleanId(text) {
        return text.replace(/-/g, '');
    }

    sendMsgb2b(data) {
        this.socket.emit('sendMsgB2B', data);
    }

    socketSubscribe() {

        this.socket.emit('newRom', this.cleanId(this.user.id));
        this.socket.on('privateRom', (data: any) => {
            if (typeof data.oculto !== 'undefined') {
                if (data.oculto === false) {
                    this.conversations.mensajes.push(data);
                    this.chatList$.next(this.conversations);
                }
            } else {
                this.conversations.mensajes.push(data);
                this.chatList$.next(this.conversations);
            }

            this._msgNoRead();
        });

        this.socket.on('reconexionChat', data => {
            this.socket.emit('newRom', this.cleanId(this.user.id));
        });
    }

    getListMsj() {
        this.socket.emit('listMsjB2B', this.cleanId(this.user.id));
    }

    socketListMsj() {

        this.socket.on('listMsjB2B', (data: any) => {
            if (data != null) {
                this.conversations = data;
            } else {
                this.conversations = {
                    bot: true,
                    estado: '',
                    mensajes: []
                };
            }

            this.chatList$.next(this.conversations);
            this._msgNoRead();
        });
    }

    startChat(data) {
        this.conversations.estado = 'Iniciada';
        this.socket.emit('iniciaConversacion', data);

    }

    _msgNoRead() {
        this.msgNoRead = 0;
        this.conversations.mensajes.map(item => {
            if (typeof item.oculto !== 'undefined') {
                if (item.oculto === false) {
                    if (!item.leido) {
                        this.msgNoRead++;
                    }
                }
            } else {
                if (!item.leido) {
                    this.msgNoRead++;
                }

            }
        });

        this.numMsg$.next(this.msgNoRead);


    }

    // se marcan los mensajes no leidos en la
    saveNumRead() {
        let items = [];

        this.conversations.mensajes.map(item => {
            if (item.leido == false) {
                items.push(item.id);
                item.leido = true;
            }
        });

        const data = {
            chatId: this.conversations.chatId,
            items
        };

        this.socket.emit('mensajesleidos', data);
        this.socket.emit('listMsjB2B', this.cleanId(this.user.id));


    }

    // se marcan los mensajes no leidos en la
    countNoRead(obs = false) {
        let count = this.conversations.mensajes.filter(i => i.leido === false).length;
        this.numMsg$.next(count);
    }

    updateChatEstado() {
        this.socket.on('estadoConversacionB2b', (data: any) => {
            this.chatList$.next(data);

        });
    }




}
