import { Component, OnInit } from '@angular/core';
import { Link } from 'src/app/shared/interfaces/link';

@Component({
  selector: 'app-footer-a',
  templateUrl: './footer-a.component.html',
  styleUrls: ['./footer-a.component.scss']
})
export class FooterAComponent implements OnInit {

  linksPaginas: Link[];
  linkMiCuenta: Link[];
  anio: number;
  constructor() { }

  ngOnInit() {
    this.anio = new Date().getFullYear();
    this.linksPaginas = [
        { label: 'Acerca de nosotros', url: ['/'] },
        { label: 'Términos y Condiciones', url: ['/'] },
        { label: 'Políticas de Privacidad', url: ['/'] },
        { label: 'Asociados', url: '' },
        { label: 'Contacto', url: ['/', 'sitio', 'contacto'] },
    ];

    this.linkMiCuenta = [
        { label: 'Mi cuenta', url: ['/'] },
        { label: 'Mis pedidos', url: ['/'] },
    ];
  }

}
