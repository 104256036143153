import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LogisticsService {

  private urlApi = environment.apiImplementosLogistica;

  constructor(
    private http: HttpClient
  ) { }


  obtieneDireccionesCliente(params) {
    return this.http.get(environment.apiMountain + `direcciones-empresa`, { params });
  }

  obtieneRetiros(params) {
    return this.http.get(environment.apiMountain + `entregas/retiro`, { params });
  }

  obtieneDespachos(params) {
    return this.http.get(environment.apiMountain + `entregas/despacho`, { params });
  }

  obtieneDireccionesTienda() {
    return this.http.get(environment.apiMountain + `sucursales`);

  }

  obtieneComunas() {
    return this.http.get(environment.apiMountain + `comunas`);
  }

  obtenerTiendas() {
    return this.http.get(this.urlApi + `tiendas`);
  }

}

