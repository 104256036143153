import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { environment } from '../../../../../environments/environment';
import { Usuario } from '../../../../shared/interfaces/login';
import { LocalStorageService } from 'angular-2-local-storage';
import { LoginService } from '../../../../shared/services/login.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';




@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnDestroy, OnInit {
    private destroy$: Subject<any> = new Subject();
    @ViewChild('modalLogin', { static: false }) modalLoginTemp: TemplateRef<any>;
    modalLoginRef: BsModalRef;

    isOpen = false;
    links: MobileMenuItem[] = mobileMenu;
    logoSrc = environment.logoSrcWhite;
    usuario: Usuario;

    constructor(
        public mobilemenu: MobileMenuService,
        private localS: LocalStorageService,
        private loginService: LoginService,
        private modalService: BsModalService
    ) { }

    ngOnInit(): void {
        this.mobilemenu.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this.isOpen = isOpen);
        this.subscribeLogin();
        this.updateLink();
    }




    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemClick(event: MobileMenuItem): void {
        console.log(event)
        if (event.type === 'link') {
            this.mobilemenu.close();
        }

        if (event.cod) {
            if (event.cod === 'iniciaSesion') {
                this.showModalLogin();
            }
        }

        // if (event.data && event.data.language) {
        //     console.log(event.data.language); // change language
        // }
    }

    showModalLogin() {
        this.modalLoginRef = this.modalService.show(this.modalLoginTemp, { class: 'modal-100' });

    }

    verifySession(isLogin) {
        // verificamos si la sesion es exitosa
        if (isLogin) {
            this.modalLoginRef.hide();
        }
    }

    subscribeLogin() {
        this.loginService.loginSessionObs$.pipe().subscribe(usuario => {
            this.updateLink();
        });
    }

    updateLink() {

        const isLogin = this.loginService.isLogin();

        // creamos los link de acceso en la aplicacion movil
        this.usuario = this.localS.get('usuario');

        if (isLogin) {

            const nameUser = this.usuario.persona.nombres + ' ' + this.usuario.persona.apellidos;
            const myAccount = this.loginService.setRoles();
            this.links = [
                { type: 'button', label: nameUser },
                {
                    type: 'button', label: 'Mi Cuenta', children: myAccount
                },
                { type: 'link', label: 'Acerca de nosotros', url: '/sitio/acerca-de-nosotros' },
                { type: 'link', label: 'Términos y condiciones', url: '/sitio/termino-y-condiciones' },
                { type: 'link', label: 'Contacto', url: '/sitio/contacto' }
            ];

        } else {

            this.links = [
                { type: 'button', label: 'Ingresar', cod: 'iniciaSesion', icon: 'fas fa-user' },
                { type: 'link', label: 'Acerca de nosotros', url: '/sitio/acerca-de-nosotros' },
                { type: 'link', label: 'Términos y condiciones', url: '/sitio/termino-y-condiciones' },
                { type: 'link', label: 'Contacto', url: '/sitio/contacto' },

            ];

        }

    }


}
