export class ConfiguracionesModel {
    // tslint:disable: variable-name
    categoria_uno?: any;
    categoria_dos?: any;
    categoria_tres?: any;
    nombre_marca?: any;
    pos_integrado?: any;
    facturador?: any;
    logo_header?: any;
    logo_loading?: any;
    precio_minimo?: any;

    // Sitio Web;
    sitio_web_logo_header?: any;
    sitio_web_subtitulo?: any;
    sitio_web_descripcion?: any;
    sitio_web_nombre?: any;
    mapa?: any;
    sitio_web_keywords?: any;

    // GENERAL
    general_impuestos_incluido?: boolean;


    constructor() {
        this.categoria_uno = 'Categoria uno';
        this.categoria_dos = 'Categoria dos';
        this.categoria_tres = 'Categoria tres';
        this.precio_minimo = 10;
        this.nombre_marca = 'Marca';

        this.logo_header = 'assets/image/logo/logo_defecto.png';
        this.logo_loading = 'assets/img/logo/logo_defecto.png';

        // Sitio Web;
        this.sitio_web_logo_header = '';
        this.sitio_web_subtitulo = '';
        this.sitio_web_descripcion = '';
        this.sitio_web_nombre = '';
        this.mapa = '';
        this.sitio_web_keywords = '';

    }

}
