import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductoModel } from '../../../../models/productos.model';


export type Layout = 'grid' | 'grid-with-features' | 'list';

@Component({
    selector: 'app-products-view',
    templateUrl: './products-view.component.html',
    styleUrls: ['./products-view.component.scss']
})
export class ProductsViewComponent {
    @Input() products: ProductoModel[] = [];
    @Input() layout: Layout = 'grid';
    @Input() grid: 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full' = 'grid-3-sidebar';
    @Input() limit = 15;
    @Input() cargandoCatalogo = true;
    @Input() showProductOptions = true;


    @Input() totalPaginas = 0;
    @Input() desde = 0;
    @Input() hasta = 0;
    @Input() totalRegistros = 0;
    @Output() cambiaPagina: EventEmitter<number> = new EventEmitter();
    @Output() itemPorPagina: EventEmitter<number> = new EventEmitter();
    @Output() filterState: EventEmitter<number> = new EventEmitter();


    @Input() paramsCategory;

    listItemPage = [
        // { id: 16, value: 16 },
        // { id: 32, value: 32 },
        // { id: 48, value: 48 },
        // { id: 64, value: 64 },
        // { id: 128, value: 128 }
        { id: 15, value: 15 },
        { id: 30, value: 30 },
        { id: 45, value: 45 },
        { id: 60, value: 60 },
        { id: 120, value: 120 }

    ];
    selectedItem = this.limit;


    constructor() {        }

    setLayout(value: Layout): void {
        this.layout = value;
        if (value === 'grid-with-features') {
            this.grid = 'grid-4-full';
        } else {
            this.grid = 'grid-4-full';
        }
    }

    onPageChange(pageNumber: number): void {
        this.cambiaPagina.emit(pageNumber);
    }

    obtieneItemPorPagina() {
        this.itemPorPagina.emit(this.selectedItem);
    }

    setVisibleFilter(state) {
        this.filterState.emit(state);
    }
}
