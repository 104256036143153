import { Component, OnInit, Inject } from '@angular/core';
import { ProductsService } from '../../services/products.service';
import { WINDOW } from '@ng-toolkit/universal';
import { GeoLocationService } from '../../services/geo-location.service';
import { GeoLocation } from '../../interfaces/geo-location';
import { LocalStorageService } from 'angular-2-local-storage';


@Component({
  selector: 'app-product-slideshow',
  templateUrl: './product-slideshow.component.html',
  styleUrls: ['./product-slideshow.component.scss']
})
export class ProductSlideshowComponent implements OnInit {
  lstProductos: any;
  innerWidth: number;

  constructor(
    private productsService: ProductsService,
    @Inject(WINDOW) private window: Window,
    private geoLocationService: GeoLocationService,
    private localStorage: LocalStorageService
  ) {
    this.innerWidth = this.window.innerWidth;
  }
  sidebarPosition: 'start' | 'end' = 'start';

  ngOnInit() {

    const geo: GeoLocation = this.localStorage.get('geolocalizacion');
    if (geo != null) {
      this.cargarHome();
    }

    this.geoLocationService.localizacionObs$.subscribe((r: GeoLocation) => {
      this.cargarHome();
    });
  }

  onResize(event) {
    console.log('procesando productos')

    this.innerWidth = event.target.innerWidth;
  }

  cargarHome() {
    this.productsService.getHomePage2().subscribe((r: any) => {
      this.lstProductos = r.data;
    });
  }

}
