import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { FormControl, Validators } from '@angular/forms';
import { ProductCart, CartData } from '../../../../shared/interfaces/cart-item';
import { Subject } from 'rxjs';
import { map, takeUntil, filter } from 'rxjs/operators';
import { RootService } from '../../../../shared/services/root.service';
import { ToastrService } from 'ngx-toastr';


interface Item {
    ProductCart: ProductCart;
    quantity: number;
    quantityControl: FormControl;
}

@Component({
    selector: 'app-cart',
    templateUrl: './page-cart.component.html',
    styleUrls: ['./page-cart.component.scss']
})
export class PageCartComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    removedItems: ProductCart[] = [];
    items: Item[] = [];
    updating = false;
    saveTimer: any;

    constructor(
        public root: RootService,
        public cart: CartService,
        private toast: ToastrService
    ) { }

    ngOnInit(): void {

        this.cart.items$.pipe(
            takeUntil(this.destroy$),
            map(ProductCarts => ProductCarts.map(item => {
                return {
                    ProductCart: item,
                    quantity: item.cantidad,
                    quantityControl: new FormControl(item.cantidad, Validators.required)
                };
            }))
        ).subscribe(items => { this.items = items; });

        setTimeout(() => {
            this.cart.dropCartActive$.next(false);
        });
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

        setTimeout(() => {
            this.cart.dropCartActive$.next(true);
        });
    }


    update(): void {
        console.log('update');
        // this.updating = true;
        // this.cart.update(
        //     this.items
        //         .filter(item => item.quantityControl.value !== item.quantity)
        //         .map(item => ({
        //             item: item.ProductCart,
        //             quantity: item.quantityControl.value
        //         }))
        // ).subscribe({complete: () => this.updating = false});
    }

    needUpdate(): boolean {
        let needUpdate = false;

        // for (const item of this.items) {
        //     if (!item.quantityControl.valid) {
        //         return false;
        //     }

        //     if (item.quantityControl.value !== item.quantity) {
        //         needUpdate = true;
        //     }
        // }

        return needUpdate;
    }

    updateCart(cantidad: number, item: any) {

        if (cantidad < 1) {
            cantidad = 1;
            this.toast.error('No se permiten números negativos en la cantidad');
        }

        item.ProductCart.cantidad = cantidad;
        const productos: ProductCart[] = [];
        this.items.map(r => {
            productos.push(r.ProductCart);
        });

        this.cart.updateCart(productos);
        this.saveCart();
    }


    remove(item: ProductCart): void {

        item.cargandoEliminarBtn = true;
        this.cart.remove(item).subscribe(r => {
            item.cargandoEliminarBtn = false;
        }, e => {
            this.toast.error('Ha ocurrido un error al eliminar el producto', 'Error');
        });
    }

    saveCart() {
        clearTimeout(this.saveTimer);
        this.saveTimer = setTimeout(() => {
            const productos = this.items.map(item => {
                return {
                    id: item.ProductCart.id,
                    cantidad: item.quantity
                };
            });
            this.cart.saveCart(productos).subscribe(r => {

            });

        }, 1000);
    }
}
