import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../../shared/interfaces/product';
import { RootService } from '../../../shared/services/root.service';



@Component({
  selector: 'app-widget-product-search',
  templateUrl: './widget-product-search.component.html',
  styleUrls: ['./widget-product-search.component.sass']
})
export class WidgetProductSearchComponent implements OnInit {

  @Input() products: Product[] = [];
  @Input() linkBusquedaProductos: any;
  constructor(public root: RootService) { }

  ngOnInit() {
  }

}
