export const environment = {
    production: true,

    apiMountain: 'https://api.rarama.cl/',
    apiMountainWs: 'ws://api.mountainpos.cl/',
    nombreSessionLocalStorage: 'MountainSession',

    // apiMountain: 'http://localhost:3333/',
    apiImplementosCatalogo: 'https://b2b-api.implementos.cl/api/',
    apiImplementosCarro: 'https://dev-api.implementos.cl/api/carro/',
    apiImplementosCMS: 'https://b2b-api.implementos.cl/api/1.0/',
    apiImplementosClientes: 'https://b2b-api.implementos.cl/api/cliente/',
    apiImplementosLogistica: 'https://dev-api.implementos.cl/api/logistica/',
    apiImplementosBuscador: 'https://localhost:1200/api/',
    apiImplementosGraphQl: 'http://api.implementos.cl:8280/Implenet/obtener',
    apiElastic: 'https://b2b-api.implementos.cl/api/articulo/',
    apiChat: 'http://wschat.implementos.cl/api/chat/',
    apiChatSocket: 'http://wschat.implementos.cl',
    logoSrc: 'assets/images/logos/logo_header2.png',
    logoSrcWhite: 'assets/images/logos/logo_header2.png',
    apiCMS: 'https://b2b-api.implementos.cl/cms/',
    apiImplementosPagos: 'https://b2b-api.implementos.cl/api/pagos/',
    urlPaymentVoucher: 'http://localhost:4200/carro-compra/forma-de-pago/',
    urlPaymentCanceled: 'http://localhost:4200/carro-compra/forma-de-pago/',
    urlPagosImplementos: 'https://pagos.implementos.cl/',
    urlVolverPago: '/carro-compra/metodo-de-envio',
    plantilla: {
        nombre: 'Plantilla B',
        slug: 'b'
    }
};
