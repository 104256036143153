import { Component, TemplateRef, ViewChild, AfterViewInit, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { LocalStorageService } from 'angular-2-local-storage';

import { ProductsService } from '../../../../shared/services/products.service';
import { RootService } from '../../../../shared/services/root.service';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CartService } from '../../../../shared/services/cart.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { Usuario } from '../../../../shared/interfaces/login';
import { DropdownDirective } from '../../../../shared/directives/dropdown.directive';
import { LoginService } from '../../../../shared/services/login.service';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { environment } from '../../../../../environments/environment';
import { ResponseApi } from '../../../../shared/interfaces/response-api';

import { ConfiguracionesService } from '../../../../shared/services/configuraciones.service';
import { ConfiguracionesModel } from '../../../../models/configuraciones.model';


@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit, OnDestroy {

    @ViewChild('menuSearch', { static: false }) dropdown: DropdownDirective;
    @ViewChild('modalSearch', { read: TemplateRef, static: false }) template: TemplateRef<any>;


    mostrarMenu = false;
    showWelcome = false;
    usuario: Usuario;
    destroy$: Subject<boolean> = new Subject<boolean>();
    logoSrc = environment.logoSrc;
    configuraciones: ConfiguracionesModel;

    linkMiCuenta = [
        { label: 'Mi perfil', url: ['/', 'mi-cuenta', 'resumen'], icon: 'far fa-user' },
        { label: 'Mis Pedidos', url: ['/', 'mi-cuenta', 'mis-compras'], icon: 'fas fa-shopping-cart' },
        // { label: 'Configuraciones', url: ['/', 'mi-cuenta', 'mis-direcciones'], icon: 'fas fa-cog' },
        // { label: 'Servicios delivery', url: ['/', 'mi-cuenta', 'mis-direcciones'], icon: 'fas fa-crown' },
        { label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off', dark: true }
    ];

    modalRef: BsModalRef;

    public texto = '';
    public textToSearch = '';
    public categorias = [];
    public marcas = [];
    public productosEncontrados = [];
    public mostrarContenido = false;
    public mostrarCargando = false;
    public linkBusquedaProductos = '#';
    public searchControl: FormControl;
    private debounce = 300;
    public buscando = true;

    public mostrarResultados = false;

    constructor(
        public menu: MobileMenuService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private productsService: ProductsService,
        public root: RootService,
        private slugify: SlugifyPipe,
        private toastr: ToastrService,
        public cart: CartService,
        public wishlist: WishlistService,
        public loginService: LoginService,
        public localS: LocalStorageService,
        private configuracionesService: ConfiguracionesService

    ) {
        this.route.queryParams.subscribe(params => {
            if (params.buscar) {
                // this.texto = params.buscar;
            }
        });

    }

    ngOnInit() {

        this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
        this.configuracionesService.$variables.subscribe(data => {
            if (data != null) {
                this.configuraciones = data;
            }
        });

        this.linkMiCuenta = [
            { label: 'Mi perfil', url: ['/', 'mi-cuenta', 'resumen'], icon: 'far fa-user' },
            { label: 'Mis Compras', url: ['/', 'mi-cuenta', 'mis-compras'], icon: 'fas fa-shopping-cart' },
            { label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off', dark: true }
        ];

        this.searchControl = new FormControl('');
        this.searchControl.valueChanges
            .pipe(debounceTime(this.debounce), distinctUntilChanged())
            .subscribe(query => {

                if (query.trim() !== '') {
                    this.textToSearch = query;
                    this.buscarEnModal();
                } else {
                    this.categorias = [];
                    this.productosEncontrados = [];
                }
            });

        this.usuario = this.localS.get('usuario');

        this.loginService.loginSessionObs$.pipe().subscribe(usuario => {

            this.usuario = usuario;
            this.showWelcome = true;

            if (usuario.hasOwnProperty('user_role')) {
                if (usuario['user_role'] === 'superadmin') {
                    this.linkMiCuenta = [
                        { label: 'Órdenes de Venta', url: ['/', 'mi-cuenta', 'ordenes'], icon: 'far fa-file-alt' },
                        { label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off', dark: true }
                    ];
                }
            }

        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public reset() {
        this.buscando = true;
    }


    public buscar() {
        this.router.navigate([`inicio/productos/${this.textToSearch}`]);
        this.mostrarContenido = true;
        this.mostrarCargando = true;
        this.mostrarResultados = false;

        setTimeout(() => {
            this.dropdown.close();
        }, 500);

    }



    public buscarEnModal() {
        this.mostrarContenido = true;
        this.mostrarCargando = true;
        this.linkBusquedaProductos = this.texto;
        this.productsService.buscarProductosSimple(this.texto).subscribe((r: ResponseApi) => {

            if (r.error) {
                this.toastr.error('Error de conexión con el servidor');
                return;
            }
            this.mostrarCargando = false;
            this.productosEncontrados = r.data.productos;
            this.categorias = r.data.categorias;
            this.categorias = this.root.obtenerUrlCategorias(r.data.categorias, this.textToSearch);
        }, error => {
            this.toastr.error('Error de conexión con el servidor de Elastic');
            console.error('Error de conexión con el servidor de Elastic');

        });
    }



    cambiaElementosMenu(value) {
        this.mostrarMenu = value;
    }

    closeWelcome() {
        this.showWelcome = false;
    }

    public mostraModalBuscador() {
        this.modalRef = this.modalService.show(this.template, { class: 'modal-100 modal-buscador' });
        this.root.setModalRefBuscador(this.modalRef);
    }

}
