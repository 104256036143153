import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import { ContactosService } from '../../services/contactos.service';
import { RespuestaApi } from '../../interfaces/respuesta-api';
import { RootService } from '../../services/root.service';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TipoContacto, Contacto } from '../../interfaces/contacto';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-contactos-empresa',
  templateUrl: './contactos-empresa.component.html',
  styleUrls: ['./contactos-empresa.component.scss']
})
export class ContactosEmpresaComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @Input() set empresaId(value) {
    // this.listarGiros();

    if (value == null) {
      return;
    }
    this.idEmpresa = value;
    this.obtenerContactoEmpresa();
  }

  @Input() set btnNuevo(val) {
    if (val) {
      this.nuevo();
    }
  }

  @Input() set btnGuardar(val) {
    if (val) {
      this.guardar();
    }
  }

  @Input() set btnValidar(val) {
    if (val) {
      this.validar();
    }
  }

  @Input() set porDefecto(val) {
    if (val) {
      this.procesarDatosDefecto(val);
    }
  }


  defecto
  @Output() formulario = new EventEmitter<any>();

  cargando = false;
  idEmpresa: string;
  formularioContacto: FormGroup;
  tipoContactos: TipoContacto;
  mensajeError = {
    mostrar: false,
    mensaje: ''
  };


  constructor(
    private contactosService: ContactosService,
    private root: RootService,
    private fb: FormBuilder,
  ) {

    this.inicializarformulario();
  }

  ngOnInit() {
    this.obtenerTipoContactos();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private obtenerContactoEmpresa() {
    this.dtOptions = {
      ... this.root.obtenerOpcionesDt(),
      serverSide: false
    }

    this.cargando = true;
    this.contactosService.obtenerListado({ empresa_id: this.idEmpresa }).subscribe((r: RespuestaApi) => {
      // console.log('obtener contacto empresa');
      // console.log(r.data);
      this.procesarDatosFormulario(r.data);
      this.cargando = false;

    }, e => {
      this.root.validaError(e);
      this.cargando = false;

    });
  }

  private obtenerTipoContactos() {
    this.contactosService.obtenerListadoTipoContactos().subscribe((r: RespuestaApi) => {
      this.tipoContactos = r.data;
    }, e => {
      this.root.validaError(e);
    })
  }

  inicializarformulario() {
    this.formularioContacto = this.fb.group({
      contactos: this.fb.array([])
    });
  }

  procesarDatosFormulario(data: Contacto[]) {

    const contactos = this.formularioContacto.controls.contactos as FormArray;

    let i = 0;
    for (const item of data) {
      const obj = this.fb.group({
        persona_id: item.persona_id,
        id: item.id,
        dv: item.persona.dv,
        rut: [item.persona.rut + '-' + item.persona.dv, [Validators.required]],
        nombres: [item.persona.nombres, Validators.required],
        apellidos: [item.persona.apellidos],
        tipo_contacto_id: [item.tipo_contacto_id, Validators.required],
        tipoContacto: item.tipoContacto.nombre,
        telefonos: this.fb.array([]),
        emails: this.fb.array([]),
        edicionActivada: false,
        cambios: false
      });

      const telefonos = obj.controls.telefonos as FormArray;

      for (let telefono of item.contactoTelefono) {
        telefonos.push(this.fb.group({
          id: [telefono.id, Validators.required],
          telefono: [telefono.telefono, Validators.required],
        }));
      }

      const emails = obj.controls.emails as FormArray;
      for (let correo of item.contactoCorreo) {
        emails.push(this.fb.group({
          id: [correo.id, Validators.required],
          email: [correo.email, [Validators.required, Validators.email]]
        }))
      }


      contactos.push(obj);

    }

    this.dtTrigger.next();

  }

  procesarDatosDefecto(datos) {
    console.log(datos);
    const contactos = this.formularioContacto.controls.contactos as FormArray;

    if (contactos.length == 0) {
      this.nuevo();
    }
    contactos.controls[0].patchValue(datos);
  }
  validar() {

    this.mensajeError.mostrar = false;

    if (this.formularioContacto.value.contactos.length === 0) {
      this.mensajeError.mostrar = true;
      this.mensajeError.mensaje = 'Debe ingresar al menos un contacto ';
    }


    this.formularioContacto.updateValueAndValidity();
    this.formulario.emit(this.formularioContacto);
    if (this.formularioContacto.invalid) {
      this.formularioContacto.markAllAsTouched();
      return;
    }
  }
  guardar() {

  }

  nuevo() {
    const contactos = this.formularioContacto.controls.contactos as FormArray;

    const nuevo = this.fb.group({
      id: null,
      dv: null,
      rut: [null],
      nombres: [null, Validators.required],
      apellidos: [null, Validators.required],
      tipo_contacto_id: [null, Validators.required],
      telefonos: this.fb.array([]),
      emails: this.fb.array([]),
      edicionActivada: true,
      cambios: true
    });

    const telefonos = nuevo.controls.telefonos as FormArray;
    telefonos.push(this.fb.group({
      id: null,
      telefono: [null, Validators.required]
    }));
    const emails = nuevo.controls.emails as FormArray;
    emails.push(this.fb.group({
      id: null,
      email: [null, [Validators.required, Validators.email]]
    }))


    contactos.push(nuevo);

    // setTimeout(() => {
    //   this.actualizarTabla();
    // }, 3000);


  }

  nuevoTelefono(posicion: number) {
    const contactos = this.formularioContacto.controls.contactos as FormArray;
    const telefonos = contactos.controls[posicion]['controls'].telefonos as FormArray;
    telefonos.push(this.fb.group({
      id: null,
      telefono: [null, Validators.required]
    }))
  }

  nuevoEmail(posicion: number) {
    const contactos = this.formularioContacto.controls.contactos as FormArray;
    const emails = contactos.controls[posicion]['controls'].emails as FormArray;
    console.log(contactos.controls[posicion]['controls']);
    emails.push(this.fb.group({
      id: null,
      email: [null, Validators.required]
    }))

  }

  quitarControl(posicion: number) {
    const contactos = this.formularioContacto.controls.contactos as FormArray;
    contactos.removeAt(posicion);
  }

  quitarControlInterno(posicionPadre: number, posicion: number, nombreArray: string) {
    const contactos = this.formularioContacto.controls.contactos as FormArray;
    const arrControl = contactos.controls[posicionPadre]['controls'][nombreArray] as FormArray;
    arrControl.removeAt(posicion);
  }

  habilitarEdicion(posicion: number, habilitar = true) {
    const contactos = this.formularioContacto.controls.contactos as FormArray;
    contactos.controls[posicion].patchValue({ edicionActivada: habilitar });
    contactos.controls[posicion].patchValue({ cambios: true });

  }

  actualizarTabla(): void {
    // this.dtTrigger.next();
    // this.dtTrigger.complete();


    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   // Destroy the table first
    //   dtInstance.destroy();
    //   // Call the dtTrigger to rerender again
    //   this.dtTrigger.next();
    // });
  }

}
