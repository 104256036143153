import { Product } from '../app/shared/interfaces/product';
// export const products: Product[] = []
export const products = [
    {
        id: 1,
      
        nombre:'BATERIA 150 AMP SUNTECH BORNE STANDAR',
        precio: { precio:  749},
        compareAtPrice: null,
        images: [
            'assets/images/products/product-1.jpg',
            'assets/images/products/product-2.jpg',
            'assets/images/products/product-1.jpg',
            'assets/images/products/product-2.jpg',
            'assets/images/products/product-1.jpg',
                ],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 2,
        nombre:'BATERIA 150 AMP SUNTECH BORNE STANDAR',
        precio: { precio:  1019},
        compareAtPrice: null,
        images: [
            'assets/images/products/product-1.jpg',
            'assets/images/products/product-2.jpg',
            'assets/images/products/product-1.jpg',
            'assets/images/products/product-2.jpg',
            'assets/images/products/product-1.jpg',

        ],
        badges: ['hot'],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 3,
        nombre:'DISCO EMBRAGUE 362 WGTZ',
        precio: { precio:  850},
        compareAtPrice: null,
        images: ['assets/images/products/product-3.jpg', 'assets/images/products/product-3.jpg'],
        badges: [],
        rating: 4,
        reviews: 8,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'AGRALE' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' },
            { nombre:'Diametro Del Disco', value: '362 MM' },
            { nombre:'Estriado Eje Disco', value: '10 Estrias' }


        ],
        options: []
    },
    {
        id: 4,
        nombre:'DISCO EMBRAGUE 362 WGTZ',
        precio: { precio:  949},
        compareAtPrice: 1189,
        images: ['assets/images/products/product-4.jpg', 'assets/images/products/product-4.jpg'],
        badges: ['sale'],
        rating: 3,
        reviews: 15,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'AGRALE' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Diametro Del Disco', value: '362 MM' },
            { nombre:'Estriado Eje Disco', value: '10 Estrias' }
        ],
        options: []
    },
    {
        id: 5,
        nombre:'LLANTA DISCO 8.50×24 EUROPEA',
        precio: { precio:  1700},
        compareAtPrice: null,
        images: ['assets/images/products/product-5.jpg', 'assets/images/products/product-5.jpg'],
        badges: [],
        rating: 4,
        reviews: 2,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 6,
        nombre:'LLANTA DISCO 8.50×24 EUROPEA',
        precio: { precio:  3199},
        compareAtPrice: null,
        images: ['assets/images/products/product-6.jpg', 'assets/images/products/product-6.jpg'],
        badges: [],
        rating: 3,
        reviews: 21,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 7,
        nombre:'DISCO EMBRAGUE 362 WGTZ',
        precio: { precio:  24},
        compareAtPrice: null,
        images: ['assets/images/products/product-7.jpg', 'assets/images/products/product-7.jpg'],
        badges: [],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 8,
        nombre:'Amortiguador de suspensión neumática, Watson & Chalin',
        precio: { precio:  15},
        compareAtPrice: null,
        images: ['assets/images/products/product-8.jpg', 'assets/images/products/product-8.jpg'],
        badges: [],
        rating: 2,
        reviews: 5,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 9,
        nombre:'DISCO EMBRAGUE 362 WGTZ',
        precio: { precio:  19},
        compareAtPrice: null,
        images: ['assets/images/products/product-9.jpg', 'assets/images/products/product-9-1.jpg'],
        badges: [],
        rating: 4,
        reviews: 34,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 10,
        nombre:'CHICHARRA FRE. 26E 145MM TRAS. ',
        precio: { precio:  15},
        compareAtPrice: null,
        images: ['assets/images/products/product-10.jpg', 'assets/images/products/product-10.jpg'],
        badges: [],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 11,
        nombre:'MANGUERA ESPIRAL (PAR)',
        precio: { precio:  149},
        compareAtPrice: null,
        images: ['assets/images/products/product-11.jpg', 'assets/images/products/product-11.jpg'],
        badges: [],
        rating: 4,
        reviews: 7,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 12,
        nombre:'CHICHARRA FRE. 26E 145MM TRAS. ',
        precio: { precio:  666.99},
        compareAtPrice: null,
        images: ['assets/images/products/product-12.jpg', 'assets/images/products/product-12-1.jpg'],
        badges: [],
        rating: 5,
        reviews: 17,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 13,
        nombre:'BALATA 6.3 M.BENZ DELANTERO',
        precio: { precio:  649},
        compareAtPrice: null,
        images: ['assets/images/products/product-13.jpg', 'assets/images/products/product-13.jpg'],
        badges: [],
        rating: 2,
        reviews: 8,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 14,
        nombre:'BATERÍA 100 AMP, 700 CCA, BORNE STANDARD',
        precio: { precio:  1800},
        compareAtPrice: null,
        images: ['assets/images/products/product-14.jpg', 'assets/images/products/product-14.jpg'],
        badges: [],
        rating: 3,
        reviews: 14,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 15,
        nombre:'Kit de Reparación Frenos 16,5 Q, Q-Plus',
        precio: { precio:  290},
        compareAtPrice: null,
        images: ['assets/images/products/product-15.jpg', 'assets/images/products/product-15.jpg'],
        badges: [],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    },
    {
        id: 16,
        nombre:'Pulmon Freno Maxi 30/30',
        precio: { precio:  1499},
        compareAtPrice: null,
        images: [
            'assets/images/products/product-16.jpg',
            'assets/images/products/product-16.jpg',
            'assets/images/products/product-16.jpg',
            'assets/images/products/product-16.jpg',
            'assets/images/products/product-16.jpg'
        ],
        badges: [],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        features: [
            { nombre:'Marca', value: 'Bat' },
            { nombre:'Ancho', value: '22.00 CM' },
            { nombre:'Largo', value: '50.70 CM' },
            { nombre:'Alto', value: '23.5 CM' },
            { nombre:'Peso', value: '38 Kgrs.' },
            { nombre:'Voltaje', value: '12 V' }
        ],
        options: []
    }
];
