import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { RespuestaApi } from '../../shared/interfaces/respuesta-api';

@Injectable({
  providedIn: 'root'
})
export class CarroCompraEstadoService {

  constructor(private http: HttpClient) { }

  listar(query: any = {}): Observable<RespuestaApi> {
    return this.http.get<RespuestaApi>(environment.apiMountain + 'carro-compra-estado', {
      params: query,
    });
  }
}
