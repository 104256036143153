import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Producto, ProductoPosListado } from '../../shared/interfaces/producto';
import { RespuestaApi } from '../../shared/interfaces/respuesta-api';
import { ConfiguracionesService } from './configuraciones.service';
import { Impuesto } from '../../shared/interfaces/impuesto';
import { ProductoSimple } from '../../shared/interfaces/producto-simple';
import { ConfiguracionesModel } from '../../models/configuraciones.model';
import { LocalStorageService } from 'angular-2-local-storage';


@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(
    private http: HttpClient,
    private configuracionesService: ConfiguracionesService,
    private localStorageService: LocalStorageService
  ) { }

  convertirEntero(numero) {
    return Math.round(numero);
}

  buscarProductoSimple(texto, params = {}) {
    return this.http.get<RespuestaApi<ProductoSimple[]>>(
      environment.apiMountain + 'Productos',
      { params: { formato: 'simple', buscar: texto, ...params } }
    );
  }

  buscarProducto(id, params = {}) {
    return this.http.get<RespuestaApi<Producto>>(environment.apiMountain + `Productos/${id}`, { params });
  }

  crear(producto: Producto) {
    return this.http.post(environment.apiMountain + 'Productos', producto);
  }

  actualizar(producto: Producto) {
    return this.http.put(environment.apiMountain + 'Productos', producto);
  }

  productosListado(params) {
    return this.http.get<RespuestaApi<Producto[]>>(environment.apiMountain + 'Productos', { params });
  }

  obtenerPrecioEscala(params){
    return this.http.get(environment.apiMountain + 'Productos/obtener-precio-escala', { params });
  }

  eliminarProducto(id: string) {
    console.log('servicio eliminar producto');
    console.log(id);
    return this.http.delete(environment.apiMountain + `Productos/BorrarImagen/${id}`);
  }

  /**
   * Agrega o descuenta los impuestos al precio de un producto
   * 
   *  @param impuestos_incluido_override Sobreescribe variable de configuración general_impuestos_incluido.
   *  Si incluye impuestos, se calcula el neto, si no, se usa `precio` como el neto
   */
  calcularImpuesto(precio: number, productoImpuesto: Impuesto[], impuestos_incluido_override = false) {
    if (impuestos_incluido_override || this.configuracionesService.obtenerVariablesEnMemoria().general_impuestos_incluido) {
      return this.calcularConImpuestoIncluidos(precio, productoImpuesto);
    } else {
      return this.calcularImpuestosConNetos(precio, productoImpuesto);
    }
  }

  /**
   * Agrega los impuestos al precio del producto
   */
  private calcularImpuestosConNetos(precioOriginal: number, productoImpuesto: Impuesto[]) {
    let totalImpuestos = 0;

    productoImpuesto.forEach(impuesto => {
      const factor = Number(impuesto.factor);
      impuesto.valorImpuestoProducto = precioOriginal * (factor / 100);
      totalImpuestos += impuesto.valorImpuestoProducto;
    });
    const valorImpuestosUnitario = this.convertirEntero(totalImpuestos);
    const precionConImpuesto = this.convertirEntero(totalImpuestos + precioOriginal);
    const precio = precionConImpuesto;

    return {
      precio,
      precioNeto: precioOriginal,
      valorImpuestosUnitario,
      productoImpuesto
    }
  }

  /**
   * calcula el valor neto del precio del producto y luego obtiene los impuestos
   */
  private calcularConImpuestoIncluidos(precioOriginal: number, productoImpuesto: Impuesto[]) {
    let totalImpuestos = 0;
    let precioNeto = 0;
    let porcentajeTotalImpuestos = 0;

    productoImpuesto.forEach(impuesto => {
      const factor = Number(impuesto.factor);
      porcentajeTotalImpuestos += factor;
    });

    precioNeto = precioOriginal / (1 + (porcentajeTotalImpuestos / 100));
    precioNeto = this.convertirEntero(precioNeto);


    productoImpuesto.forEach(impuesto => {
      const factor = Number(impuesto.factor);
      impuesto.valorImpuestoProducto = precioNeto * (factor / 100);
      totalImpuestos += impuesto.valorImpuestoProducto;
    });
    const valorImpuestosUnitario = this.convertirEntero(totalImpuestos);
    const precionConImpuesto = this.convertirEntero(totalImpuestos + precioNeto);
    const precio = precionConImpuesto;

    return {
      precio,
      precioNeto,
      valorImpuestosUnitario,
      productoImpuesto
    };

    // item.valorImpuestosUnitario = this.numeros.convertirEntero(totalImpuestos);
    // const precionConImpuesto = this.numeros.convertirEntero(totalImpuestos + precioOriginal);
    // item.precio = precionConImpuesto;
  }

  obtenerVariablesEnMemoria(): ConfiguracionesModel {
    if (
      typeof this.localStorageService.get('variablesSistema') === 'undefined'
      || this.localStorageService.get('variablesSistema') === null
      || Object.keys(this.localStorageService.get('variablesSistema')).length === 0
    ) {
      const configuraciones: ConfiguracionesModel = new ConfiguracionesModel();
      return configuraciones;
    }

    const configuracionesModel: ConfiguracionesModel = new ConfiguracionesModel();
    return { ...configuracionesModel, ...this.localStorageService.get('variablesSistema') };
  }

}
