import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Product } from '../interfaces/product';
import { SlugifyPipe } from '../pipes/slugify.pipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DecimalPipe, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { LocalStorageService } from 'angular-2-local-storage';
import { Usuario } from '../interfaces/login';
import { Router } from '@angular/router';
import * as uuid from 'uuid/v4';
import { FiltrosCatalogoDefault } from '../interfaces/product-filter';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { mensajes } from 'src/data/mensajes';
import * as queryString from 'query-string';

@Injectable({
    providedIn: 'root'
})
export class RootService {
    path = './inicio';
    modalBuscador: any = null;
    urlProduct: any[] = [];
    public simpleDtOptions: any;
    ViewportScroller: any;

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        public slugify: SlugifyPipe,
        public decimal: DecimalPipe,
        private http: HttpClient,
        private localS: LocalStorageService,
        private router: Router,
        private viewportScroller: ViewportScroller,
        private toastr: ToastrService,

    ) {


        this.setDataTableBasic();
    }

    setDataTableBasic() {
        this.simpleDtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            retrieve: true,
            // ordering: false,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json'
                // sProcessing: loadingDiv(false, 'Cargando registros', true)
            },
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }]
        };
    }

    product(id: string, nombre: string = 'Nombre del producto', paramsCategory: any = false) {

        this.urlProduct = ['/', 'inicio', 'productos', 'ficha', `${this.slugify.transform(nombre)}-${id}`];

        if (paramsCategory !== false) {
            if (paramsCategory.firstCategory) {
                this.urlProduct.push(paramsCategory.firstCategory);
            }
            if (paramsCategory.secondCategory) {
                this.urlProduct.push(paramsCategory.secondCategory);
            }

            if (paramsCategory.thirdCategory) {
                this.urlProduct.push(paramsCategory.thirdCategory);
            }
        }

        const url = this.router.createUrlTree(this.urlProduct);
        return url.toString();
    }

    limpiarRut(texto) {
        const searchRegExp = /\./g;
        const replaceWith = '';
        let r1 = texto.replace(searchRegExp, replaceWith);
        // let a = r1.split('-');
        // return a[0].trim();
        return r1;
    }


    getDataSesionUsuario() {
        const data: Usuario = this.localS.get('usuario');
        const id = uuid();

        if (data == null) {

            const dataTemp: Usuario = {
                login_temp: true,
                rut: 0,
                id
            };
            this.localS.set('usuario', dataTemp);
            return dataTemp;
        } else {

            if (typeof data.id === 'undefined') {
                this.router.navigate(['/', 'mi-cuenta', 'login']);
                return;
            }
            return data;
        }

    }


    obtenerOpcionesDt() {
        const opciones = {
          pagingType: 'full_numbers',
          pageLength: 25,
          retrieve: true,
          serverSide: true,
          processing: true,
          language: {
            url: 'assets/js/datatable/Spanish.json',
            // sProcessing: loadingDiv(false, 'Cargando registros', true)
          },
          columnDefs: [
            {
              targets: 'sort-false',
              orderable: false,
            },
          ],
          // responsive: true,
          // scrollX: true
        };
        return opciones;
      }

    post(): string {
        return `${this.path}/blog/post-classic`;
    }

    url(url: string): string {
        return this.path + url;
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public limpiaTextos(producto: Product) {
        // console.log(producto);
        producto.nombre = producto.nombre.replace(/(^"|"$)/g, '');
        console.log(producto.nombre)
        return producto;
    }

    obtenerUrlVolver(urlVolver) {
        const urlDecode = decodeURIComponent(urlVolver);
        const urlSplit = urlDecode.split('?');
        const url = urlSplit[0];
        const urlParams = urlSplit[1];
        const parametros = queryString.parse(urlParams);
        return {
          url: url.split('/'),
          parametros
        };
    }

    public limpiaAtributos(product: Product) {

        console.log()
        // product.atributos = [];
        if (product.atributos == null) {
            return [];
        }
        const att = product.atributos.filter(val => {

            console.log(val)
        });

        product.atributos = att;
    }


    public getUrlImagenMiniatura(producto: Product) {

        // console.log(producto)

        if (typeof (producto.productoImagenes) !== 'undefined' && producto.productoImagenes.length > 0) {
            if (producto.productoImagenes[0].file_url) {
                return environment.apiMountain + producto.productoImagenes[0].file_url;
            } else {
                return 'assets/images/products/no-image-producto.png';
            }

        } else {
            return 'assets/images/products/no-image-producto.png';
        }
    }

    public returnUrlNoImagen() {
        return 'assets/images/products/no-image-ficha.jpg';
    }

    public setModalRefBuscador(modalRef) {
        this.modalBuscador = modalRef;
        // console.log('Establecemos referencia del modal');
        // console.log(modalRef);
    }

    public hideModalRefBuscador() {
        // console.log(this.modalBuscador);
        if (this.modalBuscador != null) {
            this.modalBuscador.hide();
        }
    }

    public getModalRefBuscador() {
        // console.log('leemos referencia del modal');
        // console.log(this.modalBuscador);
        return this.modalBuscador;
    }

    errorLoadImage($event) {
        $event.target.src = 'assets/images/products/no-image-producto.png';
    }

    // loadImganeFicha(url) {
    //     if (url) {
    //         console.log('existe');
    //     } else {
    //         console.log('NO existe');

    //     }
    // }

    public replaceSlash(str) {
        return str.replace(/\//g, '');
    }

    public replaceAll(str, char, char2 = ' ') {
        return str.replace(char, char2);
    }

    public setQuality(product: Product) {
        if (typeof product !== 'undefined' && typeof product.atributos !== 'undefined' && product.atributos !== null) {
            return product.atributos.find((item: any) => (item.nombre === 'CALIDAD')) || { valor: 0 };
        } else {
            return { valor: 0 };
        }

    }

    public ObjectToArrayAtributos(object) {
        let arr = [];
        
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const valor = object[key];
                let valorString = '';

                // console.log( typeof valor)

                if (typeof valor === 'object') {
                    valorString = valor.join(',');
                } else {
                    valorString = valor;
                }
                arr.push({
                    nombre: key,
                    valor: valorString
                });

            }
        }
        return arr;
    }

    public obtenerUrlCategorias(categorias: FiltrosCatalogoDefault[], textoABuscar = 'todos') {

        categorias.map(item => {
            const slugCategorias = item.slugs.map(slug => slug.slug);
            item.url = ['/', 'inicio', 'productos', textoABuscar, 'categoria', ...slugCategorias];
        });

        return categorias;
    }

    scrollTop(velocidad = 5) {

        if (isPlatformBrowser(this.platformId)) {
            const scrollToTop = window.setInterval(() => {
                const pos = window.pageYOffset;
                if (pos > 0) {
                    window.scrollTo(0, pos - 30); // how far to scroll on each step
                } else {
                    window.clearInterval(scrollToTop);
                }
            }, velocidad);

        }


    }

    simularHttpRequestLogin() {
        type Post = { title: string, content: string };
        const post: Observable<Post> = of(
            {
                title: 'Simulating HTTP Requests',
                content: 'This is off the hook!!',
                errors: [
                    'La funcionalidad de login esta desactivada'
                ]
            })
            .pipe(delay(100));

        return post;
    }

    scrollToId(id: string) {
        this.viewportScroller.scrollToAnchor(id)
    }

    validaError(e) {

        if (e.hasOwnProperty('errorResponse') && e.errorResponse.mensaje) {
            this.toastr.error(e.errorResponse.mensaje, mensajes.tituloError);
        } else if (e.error && e.error.hasOwnProperty('mensaje')) {
            this.toastr.error(this.limpiarMensajeError(e.error.mensaje), mensajes.tituloError);

        } else if (e.error && e.hasOwnProperty('mensaje') && e.mensaje) {
            this.toastr.error(this.limpiarMensajeError(e.mensaje), mensajes.tituloError);
        } else if (e.error && e.status === 0) {
            this.toastr.error(mensajes.servidorSinComunicacion, mensajes.tituloError);
        } else {
            this.toastr.error(mensajes.comunicacionServidor, mensajes.tituloError);
        }
    }

    limpiarMensajeError(mensaje) {
        const obj = {
            'ErrorHttpException: ': '',
            'Error: HttpException: ': '',
            'Error: ': ''
        };
        return this.replaceAll(mensaje, obj);
    }

    // replaceAll(str, mapObj) {
    //     const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    //     // console.log(re);
    //     return str.replace(re, (matched) => {
    //         return mapObj[matched];

    //     });
    // }

}
