import { Component, Input } from '@angular/core';
import { PlantillasService } from 'src/app/shared/services/plantillas.service';
import { Plantilla } from '../../shared/interfaces/plantilla';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    plantilla: Plantilla;
    @Input() layout: 'classic' | 'compact' = 'classic';

    constructor(
        public plantillasService: PlantillasService

    ) {
        this.plantilla = this.plantillasService.obtenerPlantilla();

    }



}
