import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistorialComprasService {

  constructor(
    private http: HttpClient
  ) { }

    obtenerListadoPedidos(params) {
      return this.http.get(environment.apiMountain + 'historialDeCompras', { params });
    }

    obtenerDetallesCompras(params) {
      return this.http.get(environment.apiMountain + 'detalleCarroEntregas', { params });
    }

}
