import { Component, OnInit, OnDestroy, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { Usuario } from '../../interfaces/login';
import { RootService } from '../../services/root.service';
import { ChatService } from '../../services/chat.service';

// import { ResponseApi } from 'src/app/shared/interfaces/response-api';
import { Rom } from '../../interfaces/chat';
import { LoginService } from '../../services/login.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  msg = '';
  user: Usuario;
  conversation: Rom;
  hidden = true;
  textUser = 'Asistente Implementos';
  textOnline = 'Linea';
  msgNoRead = 0;
  botsActivate = true;
  tabsActive = false;
  private destroy$ = new Subject();


  constructor(
    @Inject(PLATFORM_ID) private plataformId: any,
    @Inject(WINDOW) private window: Window,
    private root: RootService,
    private chatService: ChatService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {

    const _this = this;

    this.window.onfocus = function () {
      console.log('focus');
      _this.tabsActive = true;

      if (_this.tabsActive === true && _this.hidden === false) {
        _this.saveReadMsg();
      }
    };
    this.window.onblur = function () {
      console.log('onblur');
      _this.tabsActive = false;
    };


  }



  public init() {


    this.user = this.root.getDataSesionUsuario();
    this.loadConversation();

  }

  cleanId(text) {
    return text.replace(/-/g, '');
  }

  getUserName() {
    const userId = this.user.id;
    const idPart = userId.substr(33, 3);
    const fullname = (typeof this.user.persona.nombres === 'undefined' ?
      'Usuario' : this.user.persona.nombres) + ' ' + (typeof this.user.persona.apellidos === 'undefined' ?
        idPart : this.user.persona.nombres);

    return fullname;
  }

  sendMsg() {
    console.log(this.user);
    if (this.msg.trim() === '') {
      return false;
    }

    const fullname = this.getUserName();


    const data = {
      mensaje: this.msg,
      type: 'text',
      userId: this.cleanId(this.user.id),
      nombre: fullname

    };

    this.chatService.sendMsgb2b(data);
    this.msg = '';
  }

  loadConversation() {

    this.chatService.chatListObs$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.conversation = data;
      this.botsActivate = this.conversation.bot;

      if (typeof this.conversation.nombreUsuario !== 'undefined') {
        this.setNameUser(this.conversation);
      }

      for (const item of this.conversation.mensajes) {
        if (!item.bodyHtmlFormat) {
          item.body = this.linkify(item.body);
          item.bodyHtmlFormat = true;
        }
      }

      this.chatService.countNoRead();
      if (this.tabsActive === true && this.hidden === false) {
        this.saveReadMsg();
      }


    });


    // MARCAN LOS MENSAJES
    this.chatService.numMsgObs$.pipe(takeUntil(this.destroy$)).subscribe(num => {
      this.msgNoRead = num;
    });

  }

  saveReadMsg() {
    const count = this.conversation.mensajes.filter(i => i.leido === false).length;
    if (count > 0) {
      this.chatService.saveNumRead();
    }
  }




  close() {
    this.hidden = true;
  }

  open() {
    this.hidden = false;

    if (this.chatService.conversations.mensajes.length == 0) {
      this.startChat();
    }

    const count = this.conversation.mensajes.filter(i => i.leido === false).length;
    if (count > 0) {
      this.chatService.saveNumRead();
    }

  }

  setNameUser(data) {
    this.textUser = data.nombreUsuario;
    this.textOnline = 'Linea';
  }

  startChat() {
    const fullname = this.getUserName();

    const data = {
      mensaje: 'hola',
      type: 'text',
      userId: this.cleanId(this.user.id),
      nombre: fullname,
      login: this.loginService.isLogin()

    };
    this.chatService.startChat(data);
  }

  private linkify(plainText): string {
    let replacedText;
    let replacePattern1;
    let replacePattern2;
    let replacePattern3;

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    // Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  }

}
