import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  @Output() outLogin: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


}
