import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {


  private urlApi = environment.apiImplementosCatalogo;
  private apiImplementosBuscador = environment.apiImplementosBuscador;


  constructor(private http: HttpClient) {

  }

  buscarProductosSimple(texto) {
    return this.http.get(environment.apiMountain + `productos/?formato=avanzado&buscar=${texto}`);
  }

  buscaListadoProducto(params) {
    return this.http.get(environment.apiMountain + `productos/?formato=paginado&filtros=1`, { params });

  }
  getStockProduct(sku) {
    return this.http.get(environment.apiImplementosCarro + `stockb2b?sku=${sku}`);

  }

  obtieneDetalleProducto(sku, params = null) {
    return this.http.get(environment.apiMountain + `productos/${sku}?tipo=codigo_interno`, { params });
  }

  getRecommendedProducts(params) {
    return this.http.get(environment.apiImplementosCatalogo + 'catalogo/recomendadoproducto', { params });

  }
  getMatrixProducts(params) {
    return this.http.get(environment.apiImplementosCatalogo + 'catalogo/matrizproducto', { params });
  }

  getRelatedProducts(params) {
    return this.http.get(environment.apiImplementosCatalogo + 'catalogo/relacionadoproducto', { params });
  }

  getPropularProducts(params) {
    return this.http.get(environment.apiImplementosCatalogo + 'catalogo/popularesproducto', { params });
  }

  getHomePage2() {
    return this.http.get(environment.apiElastic + 'homepage');
  }
}
