import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductsService } from '../../services/products.service';


@Component({
  selector: 'app-product-stock',
  templateUrl: './product-stock.component.html',
  styleUrls: ['./product-stock.component.scss']
})
export class ProductStockComponent implements OnInit {

  codSku: string;
  stores = [];
  loadingData = true;
  @Input() modalRef: BsModalRef;

  @Input() set sku(value) {

      this.loadData(value);

    // setTimeout(() => {
    //   // this.loadData(value);
      
    // }, 1000);
  }


  constructor(private productsService: ProductsService) { }

  ngOnInit() {
  }

  loadData(sku) {
    this.stores = [];
    this.loadingData = true;

    this.productsService.getStockProduct(sku).subscribe((r: any) => {
      this.loadingData = false;
      // console.log(r);
      this.stores = r;
    });
  }

}
