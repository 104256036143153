import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoginService } from '../../../../shared/services/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router, NavigationEnd, Event } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../../../shared/services/cart.service';
import { Usuario } from '../../../../shared/interfaces/login';
import { ResponseApi, ErrorApi } from '../../../../shared/interfaces/response-api';

@Component({
  selector: 'app-header-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  @Output() muestraLogin: EventEmitter<any> = new EventEmitter();
  @Input() class;
  @Input() linkRegister = false;
  @Input() linkRecoverPassword = false;
  @Input() showLabel = true;
  @Input() ruta: any;
  Remember = false;
  contentRegister = false;
  cargando = false;

  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
    private localS: LocalStorageService,
    private router: Router,
    private toastr: ToastrService,
    private cart: CartService
  ) {

    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  ngOnInit() {

    this.verificaSession();
  }

  entrar() {

    const user: Usuario = this.localS.get('usuario');

    let userIdOld = null;
    if (user !== null) {
      userIdOld = user.id;
    }

    this.cargando = true;
    this.loginService.iniciarSesion(this.form.value).subscribe((r: ResponseApi) => {
      this.cargando = false;

      if (r.error) {
        this.toastr.error(r.mensaje, 'Error');
        return false;
      }

      const data: Usuario = { ...r.data, login_temp: false };
      // this.ruta ? this.router.navigate(['/inicio/mi-cuenta', this.ruta]) : null;


      this.localS.set('usuario', data);
      this.loginService.notify(data);
      this.verificaSession();

      if (userIdOld !== null) {
        const dataPut = { origen: userIdOld, destino: data.id };
        this.transpasarCarroDeCompra(dataPut);
      } else {
        this.cart.load();
      }



    }, (e: ErrorApi) => {
      this.cargando = false;
      this.toastr.error(e.error.mensaje, 'Error');
    });

  }

  transpasarCarroDeCompra(data) {
    this.cart.cartTransfer(data).subscribe((res: ResponseApi) => {
      this.cart.load();
    }, (e: ErrorApi) => {
      this.toastr.error(e.error.mensaje, 'Error');
    });
  }

  verificaSession() {
    const user: Usuario = this.localS.get('usuario');
    if (user == null) {
      this.muestraLogin.emit(false);
    } else {

      if (user.login_temp) {
        this.muestraLogin.emit(false);
      } else {
        this.muestraLogin.emit(true);

      }
    }
  }

  RemenberFn() {
    this.Remember = !this.Remember;
  }

  register() {
    this.contentRegister = true;

  }

  returnLogin() {
    this.contentRegister = false;
  }

}
