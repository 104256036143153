import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HistorialComprasService } from '../../services/historial-compras.service';
import { RespuestaApi } from '../../interfaces/respuesta-api';
import { ToastrService } from 'ngx-toastr';
import { MigaDePan } from '../../interfaces/miga-de-pan';
@Component({
    selector: 'app-detalle-compra',
    templateUrl: './detalle-compra.component.html',
    styleUrls: ['./detalle-compra.component.scss'],
})
export class DetalleCompraComponent implements OnInit {
    data: any;
    productos = [];
    rutas: MigaDePan[];
    titulo = 'Detalles';

    constructor(
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private historialComprasService: HistorialComprasService,
    ) {}

    ngOnInit() {
        this.rutas = [
            {
                titulo: 'Pedidos',
                url: ['/', 'tienda'],
                activa: false,
            },
            {
                titulo: this.titulo,
                url: [],
                activa: true,
            },
        ];
        this.carroCompraEntregas();
    }

    carroCompraEntregas() {
        const idCarroCompraEntregas = this.activatedRoute.snapshot.params;
        const params = JSON.stringify(idCarroCompraEntregas);

        this.historialComprasService
            .obtenerDetallesCompras({ json: params })
            .subscribe((r: any) => {
                this.data = r.data;
                // this.productos = r;
                console.log(this.data);
                // if (r.error) {
                //   this.toastr.error(r.mensaje, 'Error');
                //   return;
                // }
            });
    }
}
