import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as Ws from '@adonisjs/websocket-client';

import { environment } from '../../../environments/environment';
import { Notificaciones } from '../interfaces/notificaciones';
import { ConfiguracionesService } from './configuraciones.service';

@Injectable({
    providedIn: 'root'
})
export class NotificacionesWsService {

    private wsUrl = environment.apiMountainWs;
    socket;
    canalNotificaciones;

    notificacionesSubjec = new BehaviorSubject<Notificaciones>(null);
    $notificaciones = this.notificacionesSubjec.asObservable();


    constructor(private configuracionesService: ConfiguracionesService) { }

    public conexionWs() {
        this.socket = Ws(this.wsUrl, {});
        this.socket.connect();
        this.canalesConectados();
    }

    private canalesConectados() {
        this.conexionCanalNotificaciones();
    }

    private conexionCanalNotificaciones() {
        this.canalNotificaciones = this.socket.subscribe('notificaciones');

        this.canalNotificaciones.on('actualizacion', (data: Notificaciones) => {
            console.log(data);

            const newData = { ...data, tipo: 'actualizacion' };
            this.notificacionesSubjec.next(newData);
        });

        this.canalNotificaciones.on('variables', (data: Notificaciones) => {
            this.configuracionesService.guardarVariablesEnMemoria(data.data);
        });
    }



}
