import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Product } from '../../interfaces/product';
import { RootService } from '../../services/root.service';
import { CartService } from '../../services/cart.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit {

  @Input() product: Product;
  @Input() loading: true;

  addingToCart = false;

  constructor(
    private root: RootService,
    private cart: CartService,
    private cd: ChangeDetectorRef,
    private toast: ToastrService
  ) { }

  ngOnInit() {

  }

  addToCart(): void {
    const usuario = this.root.getDataSesionUsuario();

    if (usuario == null) {
      this.toast.warning('Debe iniciar sesion para poder comprar', 'Información');
      return;
    }

    this.addingToCart = true;
    this.cart.add(this.product, 1).subscribe(
      {
        complete: () => {
          this.addingToCart = false;
          this.cd.markForCheck();
        }
      });
  }

}
