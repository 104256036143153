import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';


@Component({
  selector: 'app-card-dashboard-chart',
  templateUrl: './card-dashboard-chart.component.html',
  styleUrls: ['./card-dashboard-chart.component.scss']
})
export class CardDashboardChartComponent implements OnInit {
  @Input() title;
  @Input() iconClass;
  @Input() iconContainerClass;
  dataColumns: any[];

  @Input() set data(value) {

    // console.log(value)
    this.internalData = value.chartData;
    this.dataColumns =  value.dataColumns;
    this.draw();
  }

  @ViewChild('lineChart', { static: true }) private chartRef;
  chart: any;
  internalData: any;

  constructor() { }

  ngOnInit() {

  }

  draw() {
    this.chart = new Chart(this.chartRef.nativeElement, this.internalData );
  }


}




