import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CartService } from '../../services/cart.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { Usuario } from '../../interfaces/login';
import { CartData } from '../../interfaces/cart-item';
import { ToastrService } from 'ngx-toastr';
import { ResponseApi } from '../../interfaces/response-api';




@Component({
  selector: 'app-upload-excel-cart',
  templateUrl: './upload-excel-cart.component.html',
  styleUrls: ['./upload-excel-cart.component.scss']
})
export class UploadExcelCartComponent implements OnInit {

  form: FormGroup;
  @Input() modalRef: BsModalRef;
  file: File;
  userSession: Usuario;
  cartSession: CartData;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private cartService: CartService,
    private localS: LocalStorageService,
    private toast: ToastrService

  ) { }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.form = this.fb.group({
      file: ['', Validators.required],
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
    }
  }

  uploadFile() {

    this.userSession = this.localS.get('usuario');
    this.cartSession = this.localS.get('carroCompraB2B');

    // console.log(this.userSession)

    const data = {
      file: this.file,
      usuario: this.userSession.email,
      // recidCliente: this.cartSession.cliente.recidCliente,
      rut: this.userSession.rut,
      // recidFacturacion: this.cartSession.cliente.recidFacturacion,
      // sucursal: 'CONCEPCION',
      eliminar: 0
    };

    this.cartService.uploadExcel(data).subscribe((r: ResponseApi) => {
      if (r.error) {
        this.toast.error(r.msg);
        return;
      }

      this.cartService.load();
      this.modalRef.hide();
      this.toast.success('Productos cargados correctamentes', 'Carro compra');

    }, e => {
      this.toast.error('Ha ocurrido un error al conectarse al servidor');
    });
  }


}
