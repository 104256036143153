import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { LogisticsService } from '../../services/logistics.service';
import { ToastrService } from 'ngx-toastr';
import { Usuario } from '../../interfaces/login';
import { LocalStorageService } from 'angular-2-local-storage';
import { ClientsService } from '../../services/clients.service';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {
  @Input() modalAddressRef: BsModalRef;
  @Output() public respuesta = new EventEmitter<any>();

  comunas: any[];
  localidades: any[];
  formDireccion: FormGroup;
  tienda: any;
  coleccionComuna: any[];

  constructor(
    private fb: FormBuilder,
    private logisticsService: LogisticsService,
    private toastr: ToastrService,
    private localS: LocalStorageService,
    private clientsService: ClientsService
  ) {

    this.formDireccion = this.fb.group({
      calle: new FormControl(null, {
        validators: [Validators.required]
      }),
      numero: new FormControl(null),
      comuna: new FormControl(null, {
        validators: [Validators.required]
      }),
      localizacion: new FormControl(null, {
        validators: [Validators.required]
      }),
      latitud: new FormControl(null),
      longitud: new FormControl(null),

    });
    this.tienda = null;
  }

  ngOnInit() {
    this.loadComunas();

  }
  async agregarDireccion() {

    const { calle, numero, comuna, localizacion, latitud, longitud } = this.formDireccion.value;
    let comunaArr = comuna.split('@');
    const user: Usuario = this.localS.get('usuario');

    let direccion = {
      "rut": user.rut,
      "tipo": "DES",
      "region": comunaArr[2],
      "comuna": comunaArr[0],
      "numero": numero.toString(),
      "provincia": comunaArr[1],
      "direccion": calle,
      "localidad": localizacion,
      "latitud": latitud.toString(),
      "longitud": longitud.toString(),
      "codPostal": "0"

    }

    let resultado = await this.clientsService.addAdreess(direccion);

    if (resultado.error) {
      this.toastr.error('No se logro agregar la direccion');
      this.respuesta.emit(false)
    } else {
      this.toastr.success('Se agrego con exito la dirección');
      this.respuesta.emit(true);
      this.modalAddressRef.hide()

    }
  }

  cargarDireccion() {

    this.tienda = null;
    if (!this.formDireccion.valid) {
      return
    }
    const { calle, numero, comuna, localizacion } = this.formDireccion.value;
    let comunaArr = comuna.split('@');


    this.tienda = {
      direccion: `${calle} ${numero}`,
      zona: `${comunaArr[0]} ${localizacion}`
    }

  }

  loadComunas() {
    this.logisticsService.obtieneComunas()
      .subscribe((r: any) => {
        this.coleccionComuna = r.data;
        this.comunas = r.data.map(record => {

          const v = record.comuna + '@' + record.provincia + '@' + record.region;
          return { id: v, value: record.comuna };
        });
      }, error => {
        this.toastr.error(error.error.msg);
      });
  }
  obtenerLocalidades(event) {
    let localidades = [];
    let comunaArr = event.id.split('@');
    let comunas = this.coleccionComuna.filter(comuna => comuna.provincia == comunaArr[1]);
    comunas.map(
      comuna => comuna.localidades.map(localidad => localidades.push(localidad))
    );
    this.localidades = localidades

  }
  geolocalizacion(event) {

    this.formDireccion.controls['latitud'].setValue(event.lat)
    this.formDireccion.controls['longitud'].setValue(event.lng)

  }
}
