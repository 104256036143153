import { Component, Input, ViewChild } from '@angular/core';
import { ProductReview, Product } from '../../../../shared/interfaces/product';
import { specification } from '../../../../../data/shop-product-spec';
import { reviews } from '../../../../../data/shop-product-reviews';
import { TabsetComponent } from 'ngx-bootstrap';


@Component({
    selector: 'app-product-tabs',
    templateUrl: './product-tabs.component.html',
    styleUrls: ['./product-tabs.component.scss']
})
export class ProductTabsComponent {

    @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;

    @Input() withSidebar = false;
    @Input() tab: 'description' | 'specification' | 'reviews' | 'stock' = 'specification';
    @Input() set product(value: Product) {
        this.producto = value;
        // this.selectTab(1);
    }

    producto: Product;
    specification = specification;
    reviews: ProductReview[] = reviews;
    tabsStock = false;
    tabsDescription = true;


    constructor() {

    }

    selectTab(tabId: number) {
        this.staticTabs.tabs[tabId].active = true;
    }
}
