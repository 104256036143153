import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-product-feature-special',
  templateUrl: './product-feature-special.component.html',
  styleUrls: ['./product-feature-special.component.sass']
})
export class ProductFeatureSpecialComponent implements OnInit {

  @Input() texto: string;
  @Input() url: string;
  @Input() iconClass: string;


  constructor() { }

  ngOnInit() {
  }

}
