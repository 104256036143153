import { Component, Input, TemplateRef, PLATFORM_ID, Inject, Optional, HostListener } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';
import { SlidesService } from '../../../shared/services/slides.service';
// import { environment } from '../../../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { isPlatformBrowser } from '@angular/common';
import { slides } from '../../../../data/slides';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {

    @Input() withDepartments = false;
    modalRef: BsModalRef;

    public innerWidth: number;
    options = {
        dots: true,
        dragging: true,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0: { items: 1 }
        },
        rtl: this.direction.isRTL()
    };
    slides = [];

    isBrowser;
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(WINDOW) private window: Window,
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        private slidesService: SlidesService,
        private modalService: BsModalService,

    ) {
        this.innerWidth = this.window.innerWidth;
        this.isBrowser = isPlatformBrowser('b2b');
        this.slides = slides;
    }

    onResize(event) {
        console.log('procesando slideshow')
        this.innerWidth = event.target.innerWidth;
    }

    openForm(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    }

}
