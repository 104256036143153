import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageHomeOneComponent } from './pages/page-home-one/page-home-one.component';
import { PageRegistroComponent } from './pages/page-registro/page-registro.component';

import { B2cComponent } from './layout/b2c/b2c.component';
import { B2bComponent } from './layout/b2b/b2b.component';

import { RaramaPageCargoComponent } from './modules/rarama-web/pages/rarama-page-cargo/rarama-page-cargo.component';
import { environment } from '../environments/environment';

let routes: Routes = [];

if (environment.plantilla.slug === 'b') {
    routes = [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'cargo',
        },
        {
            path: 'inicio',
            component: B2cComponent,
            data: {
                headerLayout: 'classic',
            },
            loadChildren: () =>
                import('./modules/rarama-web/rarama-web.module').then(
                    (m) => m.RaramaWebModule
                ),
        },
        {
            path: 'cargo',
            component: B2cComponent,
            data: {
                headerLayout: 'classic',
            },
            children: [
                {
                    path: '',
                    component: RaramaPageCargoComponent,
                },
            ],
        },
    ];
} else {
    routes = [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'cargo',
        },
        {
            path: 'inicio',
            component: B2cComponent,
            data: {
                headerLayout: 'classic',
            },
            children: [
                {
                    path: '',
                    component: PageHomeOneComponent,
                },
                {
                    path: 'productos',
                    loadChildren: () =>
                        import('./modules/shop/shop.module').then(
                            (m) => m.ShopModule
                        ),
                },

                {
                    path: 'mi-cuenta',
                    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
                },

                {
                    path: '**',
                    component: PageNotFoundComponent,
                },
            ],
        },

        {
            path: 'carro-compra',
            component: B2cComponent,
            data: {
                headerLayout: 'classic',
            },
            children: [
                {
                    path: '',
                    loadChildren: () =>
                        import('./modules/cart/page-cart.module').then(
                            (m) => m.CartModule
                        ),
                },
            ],
        },
        {
            path: 'mi-cuenta',
            component: B2cComponent,
            data: {
                headerLayout: 'classic',
            },
            children: [
                {
                    path: '',
                    loadChildren: () =>
                        import('./modules/account/account.module').then(
                            (m) => m.AccountModule
                        ),
                },
            ],
        },
        {
            path: '**',
            component: PageNotFoundComponent,
        },

        // {
        //     path: 'sitio',
        //     component: B2cComponent,
        //     data: {
        //         headerLayout: 'classic'
        //     },
        //     children: [
        //         {
        //             path: '',
        //             loadChildren: () => import('./modules/site/site.module').then(m => m.SiteModule)
        //         },
        //     ],
        // },
        // {
        //     path: 'tiendas',
        //     // redirectTo: 'sitio/tiendas'
        // },
        // {
        //     path: '**',
        //     redirectTo: 'inicio'
        // },
    ];
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
