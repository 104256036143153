import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductCart } from '../../interfaces/cart-item';


@Component({
  selector: 'app-alert-cart-min',
  templateUrl: './alert-cart-min.component.html',
  styleUrls: ['./alert-cart-min.component.scss']
})

export class AlertCartMinComponent implements OnInit {

  showAlert = false;
  closeAlert = false;
  ocultarAutomaticamente = 2500;

  timer: any;
  @Input() product: ProductCart;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {


  }

  public show(value) {
    this.showAlert = false;


    setTimeout(() => {
      clearTimeout(this.timer);
      this.showAlert = value;
      this.timer = setTimeout(() => {
        this.showAlert = false;
      }, this.ocultarAutomaticamente);
    }, 100);


  }

  public hide() {
    this.showAlert = false;
  }




}

