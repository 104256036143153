import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ProductFeature } from '../../interfaces/product';



@Component({
  selector: 'app-product-line-boss',
  templateUrl: './product-line-boss.component.html',
  styleUrls: ['./product-line-boss.component.sass']
})
export class ProductLineBossComponent implements OnInit {

  @Input() atributos: ProductFeature[];
  jefeLinea: string;

  constructor() {
  }

  ngOnInit() {

  
    if (this.atributos != null) {
      const resultado: ProductFeature = this.atributos.find(obj => obj.nombre === 'JEFE DE LINEA');

      console.log(resultado)
      if (typeof (resultado) === 'undefined') {
        this.jefeLinea = 'Nicolas Pizzarro';
      } else {
        this.jefeLinea = resultado.valor;
      }

    }


  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.calc();
    // console.log(changes);
  }


}
