import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    
    address = 'Los pajarito 3434 N° 123, Santiago';
    email = 'info@farmacia.cl';
    phone = ['800 200 400'];
    hours = 'Lu-Vie: 9:00 a 18:30 hrs. Sab: 9:00 a 13:00 hrs.';

    get primaryPhone(): string|null {
        return this.phone.length > 0 ? this.phone[0] : null;
    }

    constructor() { }
}
