import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../environments/environment";
import { RespuestaApi } from "../../shared/interfaces/respuesta-api";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DireccionesService {
    constructor(private http: HttpClient) {}

    obtenerDirecciones() {}

    obtenerComunas() {
        return this.http.get(environment.apiMountain + `comunas`);
    }

    obtenerDireccionesEmpresa(params) {
        return this.http.get(environment.apiMountain + `direcciones-empresa`, {
            params,
        });
    }

    obtenerDireccionesEmpresaCompleta(empresaId) {
        const params = { empresa_id: empresaId, formato: "completa" };
        return this.http.get(environment.apiMountain + `direcciones-empresa`, {
            params,
        });
    }

    obtenerTipoDirecciones(): Observable<RespuestaApi> {
        return this.http.get(environment.apiMountain + `tipo-direcciones`);
    }

    async obtenerTipoDireccionesAsync(): Promise<RespuestaApi> {
        return this.http
            .get(environment.apiMountain + `tipo-direcciones`)
            .toPromise();
    }

    guardarDireccionesTipos(data): Observable<RespuestaApi> {
        return this.http.post(
            environment.apiMountain + `direcciones-empresa/guardado-completo`,
            data
        );
    }
}
