import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientsService } from '../../services/clients.service';
import { ToastrService } from 'ngx-toastr';
import { LogisticsService } from '../../services/logistics.service';
import { FormGroup, FormBuilder, Validators, SelectMultipleControlValueAccessor } from '@angular/forms';
import { ResponseApi, ErrorApi } from '../../interfaces/response-api';
import { LocalStorageService } from 'angular-2-local-storage';
import { LoginService } from '../../services/login.service';
import { CartService } from '../../services/cart.service';
import { PasswordValidator } from '../../validations/password';
import { RutValidator } from 'ng2-rut';
import { Usuario } from '../../interfaces/login';
import { error } from 'util';
import { Giro } from '../../interfaces/empresa';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @Output() returnLoginEvent: EventEmitter<any> = new EventEmitter();
  @Input() linkLogin;

  giros: Giro[];
  comunas: any[];
  public formUsuario: FormGroup;
  public passwordFormGroup: FormGroup;
  public isInvoice = false;
  loadingForm = false;
  public blockedForm = true;
  public isValidRut = false;


  constructor(
    private clientService: ClientsService,
    private toastr: ToastrService,
    private logisticsService: LogisticsService,
    private fb: FormBuilder,
    private localS: LocalStorageService,
    private loginService: LoginService,
    private cartService: CartService,
    private rutValidator: RutValidator,


  ) { }




  ngOnInit() {
    this.loadGiro();
    // this.loadComunas();
    this.formDefault();
    this.formBlock(true);
  }

  formDefault() {
    this.formUsuario = this.fb.group({
      empresa_rut: '',
      empresa_razon_social: '',
      empresa_giro_id: '',
      rut: ['', [Validators.required, this.rutValidator, Validators.minLength(9), Validators.maxLength(9)]],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      email: ['', [Validators.required, Validators.email]],
      pwd: ['', [Validators.required, Validators.minLength(6)]],
      confirmPwd: ['', [Validators.required, Validators.minLength(6)]],

    }, {
      validator: PasswordValidator.validate.bind(this)
    });

  }




  loadGiro() {
    this.clientService.buscarGiros()
      .subscribe((r: ResponseApi) => {
        this.giros = r.data;
      }, (e: ErrorApi) => {
        this.toastr.error(e.error.mensaje, 'Error');
      });

  }

  loadComunas() {
    this.logisticsService.obtieneComunas()
      .subscribe((r: any) => {
        this.comunas = r.data.map(record => {
          const v = record.comuna + '@' + record.provincia + '@' + record.region;
          return { id: v, value: record.comuna };
        });
      }, error => {
        this.toastr.error(error.error.msg);
      });
  }

  registerUser() {
    const dataSave = { ...this.formUsuario.value };
    this.loadingForm = true;


    // Guardamos id de carro actual
    const user: Usuario = this.localS.get('usuario');
    let userIdOld = null;
    if (user !== null) {
      userIdOld = user.id;
    }

    this.clientService.register(dataSave).subscribe((r: ResponseApi) => {
      this.loadingForm = false;

      if (r.error) {
        this.toastr.error(r.mensaje);
        return;
      }

      this.toastr.success(r.mensaje);

      const data: Usuario = { ...r.data, login_temp: false };
      this.localS.set('usuario', data);
      this.loginService.notify(data);


      if (userIdOld !== null) {
        const dataPut = { origen: userIdOld, destino: data.id };
        this.transpasarCarroDeCompra(dataPut);
      } else {
        this.cartService.load();
      }



    }, (e: ErrorApi) => {
      this.loadingForm = false;
      this.toastr.error(e.error.mensaje, 'Error');
    });
  }

  transpasarCarroDeCompra(data) {
    this.cartService.cartTransfer(data).subscribe((res: ResponseApi) => {
      this.cartService.load();
    }, (e: ErrorApi) => {
      this.toastr.error(e.error.mensaje, 'Error');
    });
  }

  fake() {
    const dataSave = { ...this.formUsuario.value };
    this.loadingForm = true;

    dataSave.password = dataSave.pwd;
    // formateamos rut
    const rut = dataSave.rut;
    dataSave.rut = rut.substring(0, rut.length - 1) + '-' + rut.substring(rut.length, rut.length - 1);

    // validamos si es boleta o factura
    if (this.isInvoice) {
      dataSave.tipoCliente = 2;
    } else {
      dataSave.tipoCliente = 1;
    }

    delete dataSave.pwd;
    delete dataSave.confirmPwd;

    // guardamos id de carro actual
    const user: Usuario = this.localS.get('usuario');
    let userIdOld = null;
    if (user !== null) {
      userIdOld = user.email;
    }

    const dataLogin = {
      username: this.formUsuario.value.email,
      password: this.formUsuario.value.pwd
    };


    this.loginService.iniciarSesion(dataLogin).subscribe((r: any) => {

      if (r.status === 'OK') {
        const data = { ...r.data, login_temp: false };
        this.localS.set('usuario', data);
        this.loginService.notify(data);

        if (userIdOld !== null) {
          const dataPut = {
            origen: userIdOld,
            destino: data.username
          };
          this.cartService.cartTransfer(dataPut).subscribe((res: ResponseApi) => {
            this.cartService.load();

          });

        } else {
          this.cartService.load();
        }
      } else {
        this.toastr.error(`${r.errors[0]}`);
      }

    }, e => {
      this.toastr.error(e.error.msg);
    });


  }

  login() {

    const user: Usuario = this.localS.get('usuario');

    let userIdOld = null;
    if (user !== null) {
      userIdOld = user.email;
    }

    const dataLogin = {
      username: this.formUsuario.value.email,
      password: this.formUsuario.value.pwd
    };


    this.loginService.iniciarSesion(dataLogin).subscribe((r: any) => {

      if (r.status === 'OK') {

        const data = { ...r.data, login_temp: false };

        this.localS.set('usuario', data);
        this.loginService.notify(data);


        if (userIdOld !== null) {
          const dataPut = {
            origen: userIdOld,
            destino: data.email
          };
          this.cartService.cartTransfer(dataPut).subscribe((res: ResponseApi) => {
            this.cartService.load();
          });

        } else {
          this.cartService.load();
        }
      } else {
        this.toastr.error(`${r.errors[0]}`);
      }

    }, e => {
      this.toastr.error(e.error.msg);
    });

  }



  invoice() {
    this.isInvoice = !this.isInvoice;

    if (this.isInvoice) {
      this.formUsuario.get('empresa_rut').setValidators([Validators.required]);
      this.formUsuario.get('empresa_razon_social').setValidators([Validators.required]);
      this.formUsuario.get('empresa_giro_id').setValidators([Validators.required]);

    } else {
      this.formUsuario.get('empresa_rut').clearValidators();
      this.formUsuario.get('empresa_razon_social').clearValidators();
      this.formUsuario.get('empresa_giro_id').clearValidators();
    }

    this.formUsuario.get('empresa_rut').updateValueAndValidity();
    this.formUsuario.get('empresa_razon_social').updateValueAndValidity();
    this.formUsuario.get('empresa_giro_id').updateValueAndValidity();


    setTimeout(() => {
      this.formBlock();
    }, 10);

  }

  returnLogin() {
    this.returnLoginEvent.emit(true);
  }

  validateCustomer(e) {
    // let value = e.target.value;
    // if (this.formUsuario.controls.rut.status === 'VALID') {
    //   value = value.replace(/\./g, '');
    //   this.clientService.validateCustomer(value).subscribe((r: ResponseApi) => {
    //     if (r.error === false) {

    //       if (r.data) {
    //         this.isValidRut = false;
    //         this.formBlock();
    //         this.toastr.warning('El RUT ingresado ya se encuentra registrado en nuestros sistemas ');
    //       } else {
    //         this.isValidRut = true;
    //         this.formBlock();
    //       }

    //     }
    //   }, error => {
    //     this.toastr.error('Error de conexión con el servidor');
    //   });

    // } else {
    //   this.isValidRut = false;
    //   this.formBlock();
    // }
  }



  formBlock(force = false) {

    if (force) {
      this.isValidRut = true;
    }

    if (this.isValidRut) {
      this.formUsuario.get('nombre').enable();
      this.formUsuario.get('apellido').enable();
      this.formUsuario.get('telefono').enable();
      this.formUsuario.get('email').enable();
      this.formUsuario.get('pwd').enable();
      this.formUsuario.get('confirmPwd').enable();


      if (this.isInvoice) {
        this.formUsuario.get('empresa_razon_social').enable();
        this.formUsuario.get('empresa_giro_id').enable();
        this.formUsuario.get('empresa_rut').enable();

      }



    } else {
      this.formUsuario.get('nombre').disable();
      this.formUsuario.get('apellido').disable();
      this.formUsuario.get('telefono').disable();
      this.formUsuario.get('email').disable();
      this.formUsuario.get('pwd').disable();
      this.formUsuario.get('confirmPwd').disable();


      if (this.isInvoice) {
        this.formUsuario.get('empresa_razon_social').disable();
        this.formUsuario.get('empresa_giro_id').disable();
        this.formUsuario.get('empresa_rut').disable();

      }


    }

  }


}
