import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-guided-steps',
  templateUrl: './guided-steps.component.html',
  styleUrls: ['./guided-steps.component.scss']
})
export class GuidedStepsComponent implements OnInit {

  @Input() step = 1;

  constructor() { }

  ngOnInit() {
  }

}
