import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) {

  }

  buscarVentas(rut) {
    var call = environment.apiMountain + `ventas?rut=${rut}&ventasporPagina=10000`;
    return this.http.get(call);
  }

  buscarOvsGeneradas() {
    var estado = 'generado';
    var call = environment.apiMountain + `busquedaAll?estado=${estado}&carroPorPagina=100000&pagina=1`;
    return this.http.get(call);
  }

  confirmarOV(idCarro) {
    return this.http.post(environment.apiMountain + `confirmar`, { "id": idCarro });
  }

  /* Recuperar contraseña */

  recuperarPassword(data) {
    return this.http.post(environment.apiCMS + `users/recover-pass`, data);
  }

  updatePassword(data) {

    // console.log(data);

    var call = environment.apiCMS + `users/` + data['clientId'];
    return this.http.patch(call, data);

  }

  /* Admin Usuarios */

  buscarUsuarios(rut = '') {
    var call = environment.apiCMS + `users?rut=${rut}`;
    return this.http.get(call);
  }

  crearUsuario(data) {
    var call = environment.apiCMS + `users`;
    return this.http.post(call, data);
  }

  registrarUsuario(data) {
    var call = environment.apiCMS + `users/register`;
    return this.http.post(call, data);
  }

  updateUsuario(data) {
    var call = environment.apiCMS + `users/` + data['_id'];
    return this.http.patch(call, data);
  }

  deleteUsuario(data) {
    var call = environment.apiCMS + `users/` + data['_id'];
    return this.http.delete(call, data);
  }

  async addAdreess(data) {
    let consulta = null;
    const url = `${environment.apiMountain}direccion`
    consulta = await this.http.post(url, data).toPromise();
    console.log('direccion add', consulta);
    return consulta;
  }
  /* Aux */

  buscarComunas() {
    var call = environment.apiImplementosLogistica + `comunas`;
    return this.http.get(call);
  }

  buscarGiros() {
    return this.http.get(environment.apiMountain + `giros`);
  }

  /* home user's data */

  buscarVentasPeriodo(rut) {
    var call = environment.apiMountain + `ventasPeriodo?rut=${rut}`;
    return this.http.get(call);
  }

  buscarPendientesEntrega(rut) {
    var call = environment.apiMountain + `pendienteentrega?rut=${rut}`;
    return this.http.get(call);

  }

  buscarSaldo(rut) {
    var call = environment.apiMountain + `saldo?rut=${rut}`;
    return this.http.get(call);

  }

  buscarFacturas(rut) {
    var call = environment.apiMountain + `facturas?rut=${rut}`;
    return this.http.get(call);

  }

  register(data) {
    return this.http.post(environment.apiMountain + `login/registro-web`, data);
  }

  validateCustomer(rut) {
    return this.http.get(environment.apiMountain + `rut?rut=${rut}`);
  }

  getDataClient(data) {
    return this.http.post(environment.apiMountain + `datosPerfilUsuario`, data);
  }

  getCustomerDebt(data) {
    return this.http.post(environment.apiMountain + `deuda/listado`, data);
  }

  generatePayment(data) {
    return this.http.post(environment.apiMountain + `deuda/generaPago`, data);
  }


  agregarDireccion(data) {
    return this.http.post(environment.apiMountain + `direcciones-empresa?formato=web`, data);

  }

  crearContacto(data) {
    return this.http.post(environment.apiMountain + `contactos`, data);
  }

  obtienerContactos(params) {
    return this.http.get(environment.apiMountain + 'contactos', { params });

  }











}
