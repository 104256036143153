import { Component, OnInit, Input } from '@angular/core';
import { categories } from '../../../../data/shop-block-categories';
import { BlockHeaderGroup } from '../../interfaces/block-header-group';

@Component({
  selector: 'app-top-menu-b2b',
  templateUrl: './top-menu-b2b.component.html',
  styleUrls: ['./top-menu-b2b.component.scss']
})
export class TopMenuB2bComponent implements OnInit {


  constructor() {
  
  }

  ngOnInit() {

  
  }

}