import { Component, Input, OnInit} from '@angular/core';

import { StoresService } from 'src/app/shared/services/stores.service';
import { ConfiguracionesService } from 'src/app/shared/services/configuraciones.service';
import { ConfiguracionesModel } from 'src/app/models/configuraciones.model';

interface Store {
    cod_almacen: string;
    nombre_sucursal: string;
    recid: number;
    _id: number;
}
@Component({
  selector: 'app-header-a',
  templateUrl: './header-a.component.html',
  styleUrls: ['./header-a.component.scss']
})
export class HeaderAComponent  {
  @Input() layout: 'classic' | 'compact' = 'classic';

    storesArr = [];
    countries = [];
    configuraciones: ConfiguracionesModel;


    constructor(
        public stores: StoresService,
        private configuracionesService: ConfiguracionesService
    ) {
        // this.obtieneTienda();

        this.countries.push({ label: 'Chile ', img: 'assets/images/countries/cl.png' });

        this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
        this.configuracionesService.$variables.subscribe(data => {
            if (data != null) {
                this.configuraciones = data;
            }
        });
    }

    obtieneTienda() {
        this.stores.obtieneTiendas().subscribe((r: any) => {
            const data: Store[] = r.data;
            if (r.status === 'OK') {
                data.map(item => {
                    this.storesArr.push({ label: item.nombre_sucursal, url: '#', cod: item.cod_almacen });
                });
            } else {
                alert('error');
            }
        });
    }


}
