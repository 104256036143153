import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GmailCargoService {

  constructor(private httpClient: HttpClient) { }

  getData(datoBusqueda, tipo_consulta?: string): Observable<any> {
    tipo_consulta = (tipo_consulta)? tipo_consulta: 'codigo_tracking';
    return this.httpClient.get<any>(` ${environment.apiMountain}orden-transportes?${tipo_consulta}=${datoBusqueda}`)
  }

}
