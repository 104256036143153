import { Injectable } from "@angular/core";
import { ProductoSelect, Producto } from "../../shared/interfaces/producto";
import { ProductosService } from "../../shared/services/productos.service";
import { Product } from "../interfaces/product";
import { CurrencyFormatPipe } from "../pipes/currency-format.pipe";

@Injectable({
    providedIn: "root",
})
export class CalcularTotalComprasService {
    productos: Product[] = [];
    productosParaTotales: Product[] = [];

    constructor(
        private productosService: ProductosService,
        private currency: CurrencyFormatPipe
    ) {}

    /**
     * Redodea los numeros decimales a enteros
     */

    convertirEntero(numero) {
        return Math.round(numero);
    }

    /**
     *  Actualiza el precio por producto, valida impuestos, ofertas y descuentos
     */
    calcularPrecio(item: Product) {
        item.precio = this.convertirEntero(item.precios[0].precioMeson);
        item.precioOriginal = this.convertirEntero(item.precios[0].precioMeson);
        item.precioNeto = this.convertirEntero(item.precios[0].precioMeson);
        item.precio_descuento = this.convertirEntero(
            item.precios[0].precioCliente
        );

        this.calcularImpuesto(item);
        this.verificarDescuentos(item);

        return item;
    }

    /**
     * Agrega o descuenta los impuestos al precio de un producto
     */
    calcularImpuesto(item: Product) {
        const {
            precio,
            precioNeto,
            valorImpuestosUnitario,
            productoImpuesto,
        } = this.productosService.calcularImpuesto(
            item.precio,
            item.productoImpuesto
        );

        item.precioOriginal = item.precio;
        item.precio = precio;
        item.precioNeto = precioNeto;
        item.valorImpuestosUnitario = valorImpuestosUnitario;
        item.productoImpuesto = productoImpuesto;
    }

    /**
     * Aplica descuentos y/o ofertas un producto a la vez
     */
    private verificarDescuentos(item: Product, retornarItem = false) {
        if (item.oferta != null && item.oferta.es_pack) {
            item.bloquear_descuento = true;
            item.descuento_automatico = true;
            this.calcularTotalDescuentoPack(item);
        } else if (item.oferta != null) {
            // si existe oferta pero no es pack
            const descuentoOferta = item.oferta.porcentaje_descuento;
            item.descuento = descuentoOferta;
            item.bloquear_descuento = true;
            item.descuento_automatico = true;
        } else {
            if (item.descuento_automatico) {
                item.descuento = 0;
            }
            item.bloquear_descuento = false;
            item.descuento_automatico = false;
        }

        // aplica descuento
        if (item.descuentoIncluido !== true) {
            if (item.descuento !== 0 && !item.descuento) {
                item.descuento = 0;
            }

            item.precio_descuento =
                item.precio - item.precio * (item.descuento / 100);
            item.precio_descuento = this.convertirEntero(item.precio_descuento);
        }

        item.total = item.precioOriginal * item.cantidad;
        item.total_con_descuento = item.precio_descuento * item.cantidad;
        item.solo_descuentos = (item.total_con_descuento - item.total) * -1;
    }

    /**
     * Aplica logica de descuentos cuando es descuento por pack
     */
    calcularTotalDescuentoPack(item: Product) {
        if (item.cantidad < item.oferta.cantidad_pack) {
            item.descuento = 0;
            item.total = item.precio * item.cantidad;
            item.detalleDescuentoPack = null;
            item.solo_descuentos = 0;
        } else {
            const restoDescuento = item.cantidad / item.oferta.cantidad_pack;
            item.descuento = item.oferta.porcentaje_descuento;

            // Cantidad SIN descuento en el pack - RESTO
            let cantidadSinDescuento =
                (restoDescuento - Math.floor(restoDescuento)) *
                item.oferta.cantidad_pack;
            cantidadSinDescuento = Math.round(cantidadSinDescuento);
            const totalSinDescuento = cantidadSinDescuento * item.precio;

            // Cantidad CON descuento
            const cantidadConDescuento = item.cantidad - cantidadSinDescuento;
            const precioDescuento =
                item.precio - item.precio * (item.descuento / 100);
            const totalConDescuento = cantidadConDescuento * precioDescuento;

            item.precio_descuento = precioDescuento;
            item.solo_descuentos = totalConDescuento;

            // Total
            item.total = item.precio * item.cantidad;
            item.total_con_descuento = totalConDescuento + totalSinDescuento;

            item.detalleDescuentoPack = null;

            // Agrega detalle de los descuentos aplicados del pack
            if (cantidadSinDescuento > 0) {
                item.detalleDescuentoPack = `${cantidadConDescuento} x
		  ${this.currency.transform(precioDescuento)} y
		  ${cantidadSinDescuento} x 
		  ${this.currency.transform(item.precio)} `;
            }
        }
    }
}
