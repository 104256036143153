import { Component, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DirectionService } from '../../../shared/services/direction.service';

@Component({
    selector: 'app-block-brands',
    templateUrl: './block-brands.component.html',
    styleUrls: ['./block-brands.component.scss']
})
export class BlockBrandsComponent {
    @Input() brands: any[] = [];

    carouselOptions: OwlOptions = {
        items: 4,
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        margin: 30,
        responsive: {
            1100: { items: 4 },
            920: { items: 4 },
            680: { items: 3 },
            500: { items: 2 },
            0: { items: 1 }
        },
        rtl: this.direction.isRTL()
    };

    constructor(
        private direction: DirectionService
    ) { }
}
