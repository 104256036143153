import { ProductFeaturesSection } from '../app/shared/interfaces/product';

export const specification = [
    {name: 'General', features: [
        {name: 'Marca', value: 'Bat'},
        {name: 'Voltaje', value: '12 V'},
        {name: 'tiempo de Carga', value: '1.08 h'},
        {name: 'Peso', value: '38 Kgrs.'},
    ]},
    {name: 'Dimensiones', features: [
        {name: 'Ancho', value: '22.00 CM'},
        {name: 'Largo', value: '50.70 CM'},
        {name: 'Alto', value: '23.5 CM'}
    ]}
];
