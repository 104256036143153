export const mensajes = {
    comunicacionServidor: 'Ha ocurrido un error de comunicacion con el servidor',
    servidorSinComunicacion: 'El servidor no se encuentra en línea.',
    tituloError: 'Error',
    tituloAdvertencia: 'Advertencia',
    tituloInformacion: 'Información',

    tituloCorrecto: 'Proceso correcto',
    formularioConErrores: 'Existen errores en el formulario, verifique por favor.',
    itemsNoSeleccionadosNC: 'No ha seleccionado ningún items.',
    productoError: 'Producto con error',
    impresoraNoConectada: 'La impresora esta desconectada',
    impresoraEnUso: 'La impresora se encuentra en uso por otra aplicación',
    ovCargada: 'Se ha cargado la ov correctamente'
};
