import { Component, OnInit, Input, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { RespuestaApi } from '../../interfaces/respuesta-api';
import { RootService } from '../../services/root.service';
import { mensajes } from '../../../../data/mensajes';
import { GiroService } from '../../services/giro.service';
import { Giro, GiroEmpresaSelect } from '../../interfaces/giro';
import { GiroEmpresa } from '../../interfaces/empresa';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-giro-empresa',
  templateUrl: './giro-empresa.component.html',
  styleUrls: ['./giro-empresa.component.scss']
})
export class GiroEmpresaComponent implements OnInit {

  @Input() set empresaId(value) {
    this.listarGiros();

    if (value == null) {
      return;
    }
    this.idEmpresa = value;
    this.listarGirosEmpresa();
  }

  @Input() set btnNuevo(val) {
    if (val) {
      this.nuevo();
    }
  }

  @Input() set btnGuardar(val) {
    if (val) {
      this.guardar();
    }
  }

  @Input() set btnValidaGiro(val) {
    if (val) {
      this.validar();
    }
  }
  @Output() template = new EventEmitter<ElementRef>();
  @Output() actualizar = new EventEmitter<any>();
  @Output() formulario = new EventEmitter<any>();


  cargando = false;
  listadoGirosRespaldo: Giro[] = [];
  listadoGiros: Giro[] = [];
  listadoGirosEmpresa: GiroEmpresaSelect[];
  idEmpresa: string;
  formGiro: FormGroup;
  mensajeGiro = {
    mostrar: false,
    mensaje: ''
  };


  constructor(
    private giroService: GiroService,
    private root: RootService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.inicializarFormulario();
  }

  inicializarFormulario() {
    this.formGiro = this.fb.group({
      giros: this.fb.array([])
    });
  }

  listarGiros() {
    this.inicializarFormulario();
    this.giroService.obtenerGiros().subscribe((r: RespuestaApi) => {
      this.listadoGiros = r.data;
      this.listadoGirosRespaldo = r.data;
    }, e => {
      this.root.validaError(e);
    });
  }

  listarGirosEmpresa() {
    this.giroService.obtenerGirosEmpresa({ empresa_id: this.idEmpresa }).subscribe((r: RespuestaApi) => {
      let i = 0;
      this.listadoGirosEmpresa = r.data;
      this.crearFormulario(this.listadoGirosEmpresa);
      this.verificarGirosDisponibles();
      this.formulario.emit(this.formGiro);

    }, e => {
      this.root.validaError(e);
    });
  }

  private crearFormulario(girosEmpresa: GiroEmpresaSelect[]) {

    const giros = this.formGiro.controls.giros as FormArray;

    girosEmpresa.map(item => {
      let giro = this.fb.group({
        ...item,
        giro_id: [item.giro_id, Validators.required],
      });
      giros.push(giro);
    });
  }

  nuevo() {
    const giros = this.formGiro.controls.giros as FormArray;
    let giroPrincipal = false;
    if (giros.length === 0) {
      giroPrincipal = true;
    }

    const nuevo = this.fb.group({
      id: null,
      codigo: '-',
      nombre: null,
      guardar: true,
      giro_id: [null, Validators.required],
      principal: giroPrincipal
    });

    giros.push(nuevo);
    this.verificarGirosDisponibles();


  }
  quitar(posicion: number) {
    const giros = this.formGiro.controls.giros as FormArray;
    giros.removeAt(posicion);
    this.verificarGirosDisponibles();
  }

  giroSeleccionado(e, posicion: number) {
    const giros = this.formGiro.controls.giros as FormArray;
    const codigo = (typeof e === 'undefined') ? '-' : e.codigo;
    giros.controls[posicion].patchValue({ codigo });
    this.verificarGirosDisponibles();
  }

  private verificarGirosDisponibles() {
    const giroDisponibles = [];
    this.listadoGirosRespaldo.map(i => {
      const existe = this.formGiro.controls.giros.value.find(ge => ge.giro_id === i.id) || false;
      if (existe === false) {
        giroDisponibles.push(i);
      }
    });

    this.listadoGiros = giroDisponibles;
  }

  guardar() {
    if (this.formGiro.invalid) {
      this.toastr.error(mensajes.formularioConErrores, mensajes.tituloError);
      return;
    }

    const data = {
      ...this.formGiro.value,
      empresa_id: this.idEmpresa
    };

    this.cargando = true;
    this.giroService.crearGiroEmpresa(data).subscribe((r: RespuestaApi) => {
      this.cargando = false;
      this.toastr.success(r.mensaje, mensajes.tituloCorrecto);
      this.actualizar.emit(true);

    }, e => {
      this.cargando = false;
      this.root.validaError(e);
    });


  }

  quitarCheckboxs(event: MatCheckboxChange, position: number) {
    const giros = this.formGiro.controls.giros as FormArray;
    giros.controls.map(item => {
      item.patchValue({ principal: false });
    });
  }
  limpiarSelect(e) {
    console.log(e);

  }

  validar() {
    this.mensajeGiro.mostrar = false;

    if (this.formGiro.value.giros.length === 0) {
      this.mensajeGiro.mostrar = true;
      this.mensajeGiro.mensaje = 'Debe ingresar al menos un giro';
    }


    this.formulario.emit(this.formGiro);
    if (this.formGiro.invalid) {
      this.formGiro.markAllAsTouched();
      return;
    }
  }

  busquedaGiro(term: string, item: Giro) {
    term = term.toLocaleLowerCase();
    return item.codigo.toLocaleLowerCase().indexOf(term) > -1 || item.nombre.toLocaleLowerCase().indexOf(term) > -1;
  }

}

