import { Component, Input, OnInit } from '@angular/core';
import { Link } from '../../../../shared/interfaces/link';
import { LoginService } from '../../../../shared/services/login.service';
import { Usuario } from '../../../../shared/interfaces/login';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import { RootService } from '../../../../shared/services/root.service';

@Component({
    selector: 'app-footer-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {

    @Input() header: string;
    @Input() links: Link[] = [];
    usuario: Usuario;

    constructor(
        private router: Router,
        private loginService: LoginService,
        private localStorage: LocalStorageService,
        private root: RootService) { }

    async ngOnInit() {
        this.usuario = await this.root.getDataSesionUsuario();

        this.loginService.loginSessionObs$.pipe().subscribe(usuario => {
            this.usuario = usuario;
        });
    }

    async validarCuenta(link) {
        console.log(link)

        this.localStorage.set('ruta', link.url);
        let usuario = this.usuario;

        if (usuario == null) {
            this.router.navigate(['sitio', 'iniciar-sesion'])
        }

        if (usuario.hasOwnProperty('login_temp') && usuario.login_temp === true) {
            return this.router.navigate(['sitio', 'iniciar-sesion']);
        }

        this.usuario = usuario;

        if (link.label != 'Cerrar sesión') {

            this.router.navigate(['/mi-cuenta', link.url]);
        } else {
            this.router.navigate(['sitio', 'iniciar-sesion'])
        }

    }
}

