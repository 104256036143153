import { Slide } from '../app/shared/interfaces/slide';

export const slides: Slide[] = [
    {
    order: 0,
    title: 'Coronavirus medidas',
    text: 'Coronavirus medidas',
    alt: 'Especial Higiene y Protección',
    image_classic: 'assets/images/slides/1.jpg',
    image_full: 'assets/images/slides/1.jpg',
    image_tablet: 'assets/images/slides/1.jpg',
    image_mobile: 'assets/images/slides/1.jpg',
},
{
    order: 0,
    title: 'Coronavirus medidas',
    text: 'Coronavirus medidas',
    alt: 'Especial Higiene y Protección',
    image_classic: 'assets/images/slides/2.jpg',
    image_full: 'assets/images/slides/2.jpg',
    image_tablet: 'assets/images/slides/2.jpg',
    image_mobile: 'assets/images/slides/2.jpg',
},

{
    order: 0,
    title: 'Coronavirus medidas',
    text: 'Coronavirus medidas',
    alt: 'Especial Higiene y Protección',
    image_classic: 'assets/images/slides/3.jpg',
    image_full: 'assets/images/slides/3.jpg',
    image_tablet: 'assets/images/slides/3.jpg',
    image_mobile: 'assets/images/slides/3.jpg',
},

];

