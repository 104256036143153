import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private urlApi = environment.apiImplementosCatalogo;

  constructor(
    private http: HttpClient
  ) { }

  obtieneCategorias() {
    return this.http.get(this.urlApi + 'categorias/listado');
  }

  obtieneCategoriasHeader() {
    return this.http.get(environment.apiMountain + 'categorias?tipo=jerarquia&productos=1&tp.slug=producto');
  }

}
