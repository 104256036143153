import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EmpresaClasificacion, EmpresaClasificacionFinanciera } from '../../../shared/interfaces/empresa-clasificacion';
import { EmpresaSubsegmento, EmpresaBloqueo } from '../../../shared/interfaces/empresa';
import { RootService } from '../../services/root.service';
import { EmpresaSubsegmentosService } from '../../services/empresa-subsegmentos.service';
import { EmpresaClasificacionFinancieraService } from '../../services/empresa-clasificacion-financiera.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RespuestaApi } from '../../../shared/interfaces/respuesta-api';
import { CarroCompraEstadoService } from "../../services/carro-compra-estado.service";


@Component({
  selector: 'app-filtro-historial-compras',
  templateUrl: './filtro-historial-compras.component.html',
  styleUrls: ['./filtro-historial-compras.component.scss']
})
export class FiltroHistorialComprasComponent implements OnInit {

  @Input() tipoListado: 'clientes' | 'cobranzas' = 'clientes';
  @Input() claseFiltros = 'col-md-3';
  @Input() url = ['mi-cuenta', 'historial-de-compras'];
  @Input() grupoFiltros: 'clientes' | 'cobranzas' = 'clientes';
  @Input() rut: any;


  empresaClasificacion: EmpresaClasificacion[] = [];
  empresaClasificacionFinanciera: EmpresaClasificacionFinanciera[] = [];
  empresaSubsegmento: EmpresaSubsegmento[] = [];
  empresaBloqueos: EmpresaBloqueo[] = [];
  mostrarFiltros = true;
  carroCompraEstado: any[] = [];


  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private empresaSubsegmentosService: EmpresaSubsegmentosService,
    private empresaClasificacionFinancieraService: EmpresaClasificacionFinancieraService,
    private root: RootService,
    private router: Router,
    private route: ActivatedRoute,
    private carroCompraEstadoService: CarroCompraEstadoService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      rut: this.rut,
      razon_social: null,
      empresa_bloqueo_id: null,
      empresa_clasificacion_id: null,
      empresa_clasificacion_financiera_id: null,
      carro_compra_estado_id: null,
      empresa_sub_segmento_id: null,
      empresa_segmento_id: null,
      deuda_morosa: false
    });

    this.form.controls.empresa_segmento_id.disable();

    this.actualizarGrupoFiltros();
    this.obtenerCarroCompraEstado();

    this.route.queryParams.subscribe(params => {
      this.form.patchValue(params);
    });

    this.filtrar();
  }

  actualizarGrupoFiltros() {
    if (this.grupoFiltros === 'cobranzas') {
      this.form.removeControl('empresa_clasificacion_id');
      this.form.removeControl('empresa_clasificacion_financiera_id');
      this.form.removeControl('empresa_sub_segmento_id');
      this.form.removeControl('empresa_segmento_id');
      this.form.removeControl('carro_compra_estado_id');
    } else {
      this.form.removeControl('deuda_morosa');
    }
  }

  private obtenerCarroCompraEstado() {
    this.carroCompraEstadoService.listar().subscribe(
      (r: RespuestaApi) => {
        this.carroCompraEstado = r.data;
      },
      (e) => {
        this.root.validaError(e);
      }
    );
  }

  limpiar() {
    this.form = this.fb.group({
      rut: this.rut,
      razon_social: null,
      carro_compra_estado_id: null,      
    });

    this.router.navigate(this.url);
  }

  filtrar() {
    this.router.navigate(this.url, { queryParams: this.form.value });
  }

}
