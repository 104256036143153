import { Component, OnInit, Input } from '@angular/core';
import { ProductCart } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { CartService } from '../../../../shared/services/cart.service';


@Component({
  selector: 'app-detalle-carro-productos',
  templateUrl: './detalle-carro-productos.component.html',
  styleUrls: ['./detalle-carro-productos.component.scss']
})
export class DetalleCarroProductosComponent implements OnInit {

  @Input() productCart: ProductCart[];
  @Input() seeProducts = true;

  constructor(private root: RootService, public cart: CartService) { }

  ngOnInit() {

  }

}
