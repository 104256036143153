import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfiguracionesModel } from 'src/app/models/configuraciones.model';
import { ConfiguracionesService } from 'src/app/shared/services/configuraciones.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
    selector: 'app-block-map',
    templateUrl: './block-map.component.html',
    styleUrls: ['./block-map.component.scss']
})
export class BlockMapComponent implements OnInit {
    @ViewChild('mapaDiv', { static: true }) mapa;

    configuraciones: ConfiguracionesModel;

    countries = [
        {
            id: 'CL',
            nombre: 'CHILE',
        },
        {
            id: 'BR',
            nombre: 'BRASIL',
        },
        {
            id: 'AR',
            nombre: 'ARGENTINA',
        }
    ];
    country = 'CL';

    constructor(
        private configuracionesService: ConfiguracionesService,
        public sanitizer: DomSanitizer
    ) {

    }

    ngOnInit() {
        this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
        this.configuracionesService.$variables.subscribe(data => {
            if (data != null) {
                this.configuraciones = data;

            }
        });

    }
}
