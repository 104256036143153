import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules
import { SharedModule } from '../../shared/shared.module';
import { WidgetsModule } from '../widgets/widgets.module';

// components
import { DepartmentsComponent } from './components/departments/departments.component';
import { DropcartComponent } from './components/dropcart/dropcart.component';
import { HeaderComponent } from './header.component';
import { LinksComponent } from './components/links/links.component';
import { MegamenuComponent } from './components/megamenu/megamenu.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavComponent } from './components/nav/nav.component';
import { SearchComponent } from './components/search/search.component';
import { TopbarComponent } from './components/topbar/topbar.component';


import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { SearchVehicleApplicationComponent } from './components/search-vehicle-application/search-vehicle-application.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalStoresComponent } from './components/modal-stores/modal-stores.component';
import { HeaderAComponent } from './plantillas/header-a/header-a.component';
import { HeaderBComponent } from './plantillas/header-b/header-b.component';


@NgModule({
    declarations: [
        // components
        DepartmentsComponent,
        DropcartComponent,
        HeaderComponent,
        LinksComponent,
        MegamenuComponent,
        MenuComponent,
        NavComponent,
        SearchComponent,
        TopbarComponent,
        // LoginComponent,
        SearchVehicleApplicationComponent,
        ModalStoresComponent,
        HeaderAComponent,
        HeaderBComponent
        
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        // modules
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetsModule,
        NgSelectModule
        // LocalStorageModule
    ],
    exports: [
        // components
        HeaderComponent,
        MenuComponent,
        MegamenuComponent,
        // LoginComponent

    ]
})
export class HeaderModule { }
