import { Injectable } from '@angular/core';
import { Plantilla } from '../interfaces/plantilla';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlantillasService {

  constructor() { }

  obtenerPlantilla(): Plantilla {
    return environment.plantilla;
  }

}
