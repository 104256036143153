import { Component, Inject, Input, PLATFORM_ID, Output, EventEmitter, TemplateRef, HostListener } from '@angular/core';
import { ProductFilter, FiltrosCatalogoPrecio } from '../../../shared/interfaces/product-filter';
import { isPlatformBrowser } from '@angular/common';
import { DirectionService } from '../../../shared/services/direction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../../shared/services/products.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as uuid from 'uuid/v4';
import { CurrencyFormatPipe } from '../../../shared/pipes/currency-format.pipe';


@Component({
    selector: 'app-widget-filters',
    templateUrl: './widget-filters.component.html',
    styleUrls: ['./widget-filters.component.scss']
})
export class WidgetFiltersComponent {
    @Input() filters: ProductFilter[] = [];
    // @Input() id: ProductFilter[] = [];

    @Input() filtrosOculto: boolean;
    @Input() mensaje: string;


    @Input() set removableFilters(arr) {
        this.removableFiltersArray = [];

        Object.keys(arr).map((field, index) => {

            let valor = arr[field];

            if (field === 'precioMinimo') {
                valor = 'desde ' + this.currency.transform(arr[field]);
                this.filtroPrecio.min = arr[field];
            }
            if (field === 'precioMaximo') {
                valor = 'hasta ' + this.currency.transform(arr[field]);
                this.filtroPrecio.max = arr[field];

            }

            const obj = {
                field,
                value: valor
            };
            this.removableFiltersArray.push(obj);
        });
    }

    @Input() removableCategory: any = [];

    @Output() filtersSelected: EventEmitter<any> = new EventEmitter();
    @Output() clearCategory: EventEmitter<any> = new EventEmitter();
    @Output() clearAll: EventEmitter<any> = new EventEmitter();
    filtroPrecio: FiltrosCatalogoPrecio = {
        min: 0,
        max: 0,
    };


    removableFiltersArray: any = [];

    isPlatformBrowser = isPlatformBrowser(this.platformId);
    rightToLeft = false;
    isCollapsed = false;
    id: string;

    modalRef: BsModalRef;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private direction: DirectionService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,
        private productsService: ProductsService,
        private currency: CurrencyFormatPipe
    ) {
        this.rightToLeft = this.direction.isRTL();
        this.id = uuid();
    }

    openUserForm(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    }

    marcaCheckBox() {
        const prefix = 'filter_';
        let filters = {};

        this.filters.map(item => {
            if (item.type === 'checkbox') {
                let value = [];
                item.options.items.map(v => {
                    if (v.checked === true) {
                        value.push(v.label);
                        filters[prefix + item.name] = value;
                    }
                });

            } else if (item.type === 'price') {

                console.log(this.filtroPrecio);

                if (this.filtroPrecio.min != null) {
                    filters['precioMinimo'] = [this.filtroPrecio.min];
                }
                if (this.filtroPrecio.max != null) {
                    filters['precioMaximo'] = [this.filtroPrecio.max];
                }


            }

        });

        const obj = {
            selected: filters,
            all: this.filters
        }

        console.log(obj);
        this.filtersSelected.emit(obj);
    }

    marcarCategorias(item) {
        item.checked = true;
    }

    filtrar() {

        const urlQuery: any = {};
        const buscar = this.route.snapshot.queryParamMap.get('buscar');

        urlQuery.buscar = buscar;

        const categorias = (this.filters[0].options as any).items;
        const marcas = (this.filters[2].options as any).items;


        const categoriasSeleccionadas = categorias.filters(item => {
            if (item.checked === true) {
                return item;
            }
        });

        const marcasSeleccionadas = marcas.filters(item => {
            if (item.checked === true) {
                return item;
            }
        });

        this.router.navigate(['inicio/productos'], { queryParams: urlQuery });
    }

    removeCategory(category) {
        // this.router.navigate(['inicio/productos']);
        this.clearCategory.emit(true);
    }

    removeFilter(filter) {

        if (filter.field === 'precioMinimo') {
            this.filtroPrecio.min = null;

        }

        if (filter.field === 'precioMaximo') {
            this.filtroPrecio.max = null;

        }


        this.filters.map(item => {
            if (item.type === 'checkbox') {
                if (filter.field === 'filter_' + item.name) {
                    item.options.items.map(values => {
                        if (values.label === filter.value) {
                            values.checked = false;
                        }
                    });
                }
            }
        });
        this.marcaCheckBox();
    }

    removeAllFilter() {
        this.clearAll.emit(true);
    }

    actualizaFiltroPrecio(e) {

        this.filtroPrecio = {
            min: e.value,
            max: e.highValue
        };
        this.marcaCheckBox();

    }

}

