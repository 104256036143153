import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-newsletter',
  templateUrl: './block-newsletter.component.html',
  styleUrls: ['./block-newsletter.component.scss']
})
export class BlockNewsletterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
