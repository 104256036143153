import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'angular-2-local-storage';
import { GeoLocation, TiendaLocation } from '../interfaces/geo-location';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseApi } from 'src/app/shared/interfaces/response-api';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  private localizacion$: Subject<any> = new Subject();
  readonly localizacionObs$: Observable<any> = this.localizacion$.asObservable();

  constructor(
    private toastr: ToastrService,
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) { }

  geoLocation: GeoLocation;

  getGeoLocation() {

    // rescatamos la ubicacion de la sesion
    let geo: GeoLocation = this.localStorage.get('geolocalizacion');
 


    if (geo != null) {
      this.geoLocation = geo;
    } else {
      geo = this.datoPorDefecto();
    }

    console.log('2', geo);


    if (navigator.geolocation) {

      // si el cliente no ha seleccionado manualmente la coordenadas no entra aca
      if (geo.esSeleccionaPorCliente === false) {
        this.estableceTiendaAutomaticamente();
      } else {
        this.localizacion$.next(this.geoLocation);

      }
    } else {
      console.warn('El navegador no soporta la ubicacion automatica');
      this.localizacion$.next(this.geoLocation);

    }

  }

  getDistanceMatrix(params) {
    console.log('getDistanceMatrix');
    const url = environment.apiImplementosLogistica + 'maps/tienda-mas-cercana';
    return this.http.get(url, { params });
  }

  estableceTiendaAutomaticamente() {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const geoLocationActual = this.geoLocation;

      // ontenemos la tienda mas cercana a la ubicacion
      this.getDistanceMatrix({ lat: latitude, lon: longitude }).subscribe((r: ResponseApi) => {

        if (r.error === false) {
          const tienda: TiendaLocation = r.data.tienda;


          console.log(geoLocationActual.tiendaSelecciona.codigo, tienda.codigo)
          console.log(geoLocationActual.tiendaSelecciona.codigo != tienda.codigo)

          if (geoLocationActual.tiendaSelecciona.codigo !== tienda.codigo) {
            console.log('entra');
            this.geoLocation.esNuevaUbicacion = true;
          } else {
            this.geoLocation.esNuevaUbicacion = false;
          }

          this.geoLocation.obtenida = true;
          this.geoLocation.esSeleccionaPorCliente = false;
          this.geoLocation.tiendaSelecciona = tienda;
          this.localStorage.set('geolocalizacion', this.geoLocation);
          this.localizacion$.next(this.geoLocation);
        }
      }, e => {
        console.warn('No se ha podido establecer la tienda mas cercana ');
        this.localizacion$.next(this.geoLocation);

      });



    }, (e) => {
      console.warn('No se ha podido obtener su ubicación. \n  Puede cambiar manualmente la tienda', 'Información');
    });
  }


  datoPorDefecto() {
    console.log('sucursal por defecto');
    this.geoLocation = {
      esNuevaUbicacion: false,
      obtenida: false,
      esSeleccionaPorCliente: false,
      actual: { lat: 0, lon: 0 },
      tiendaSelecciona: {
        zona: 'San Bernardo',
        codigo: 'SAN BRNRDO'
      }
    };
    this.localStorage.set('geolocalizacion', this.geoLocation);
    return this.geoLocation;
  }

  cambiarTiendaCliente(coordenadas, tienda) {
    this.geoLocation = {
      esNuevaUbicacion: false,
      obtenida: false,
      esSeleccionaPorCliente: true,
      actual: coordenadas,
      tiendaSelecciona: tienda
    };
    this.localStorage.set('geolocalizacion', this.geoLocation);
    this.localizacion$.next(this.geoLocation);
  }


}
