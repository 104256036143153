import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Login, Usuario } from '../interfaces/login';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'angular-2-local-storage';
import { RootService } from './root.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  private loginSession$: Subject<any> = new Subject();
  readonly loginSessionObs$: Observable<any> = this.loginSession$.asObservable();
  linkMiCuenta: any = [];

  constructor(
    private http: HttpClient,
    private localS: LocalStorageService,
    private root: RootService
  ) { }

  isLogin() {
    if (this.localS.get('usuario') == null) {
      return false;
    } else {
      const user: Usuario = this.localS.get('usuario');

      if (user.login_temp) {
        return false;
      } else {
        return true;
      }
    }
  }


  iniciarSesion(data: Login) {
    return this.http.post(environment.apiMountain + 'login/iniciar-session-web', data);
  }

  notify(data) {
    this.loginSession$.next(data);
  }

  public setRoles(profile = 'compradorb2c') {
    if (profile === 'superadmin') {
      this.linkMiCuenta = [

        { type: 'link', label: 'Órdenes de Venta', url: ['/', 'mi-cuenta', 'ordenes'], icon: 'far fa-file-alt' },
        { type: 'link', label: 'Usuarios', url: ['/', 'mi-cuenta', 'usuarios'], icon: 'fas fa-users' },
        { type: 'link', label: 'Tiendas', url: ['/', 'mi-cuenta', 'tiendas'], icon: 'fas fa-store-alt' },
        { type: 'link', label: 'Diapositivas', url: ['/', 'mi-cuenta', 'diapositivas'], icon: 'fas fa-tv' },
        { type: 'link', label: 'Imágenes', url: ['/', 'mi-cuenta', 'imagenes'], icon: 'fas fa-images' },
        { type: 'link', label: 'Blog', url: ['/', 'mi-cuenta', 'blog'], icon: 'fab fa-blogger' },
        { type: 'link', label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off' }
      ];
    } else if (profile === 'supervisor') {
      this.linkMiCuenta = [

        { type: 'link', label: 'Resumen', url: ['/', 'mi-cuenta', 'resumen'], icon: 'fas fa-tachometer-alt' },
        { type: 'link', label: 'Mi perfil', url: ['/', 'mi-cuenta', 'mi-perfil'], icon: 'fas fa-user' },
        { type: 'link', label: 'Portal de pagos', url: ['/', 'mi-cuenta', 'portal-de-pagos'], icon: 'fas fa-money-check-alt' },
        { type: 'link', label: 'Solicitudes de compras', url: ['/', 'mi-cuenta', 'solicitudes-de-compras'], icon: 'far fa-thumbs-up' },
        { type: 'link', label: 'Pedidos', url: ['/', 'mi-cuenta', 'pedidos-pendientes'], icon: 'far fa-clock' },
        { type: 'link', label: 'Cotizaciones', url: ['/', 'mi-cuenta', 'cotizaciones'], icon: 'fas fa-file-invoice' },
        { type: 'link', label: 'Historial de compras', url: ['/', 'mi-cuenta', 'historial-de-compras'], icon: 'fas fa-history' },
        { type: 'link', label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off' }
      ];
    } else if (profile === 'comprador') {

      this.linkMiCuenta = [
        { type: 'link', label: 'Resumen', url: ['/', 'mi-cuenta', 'resumen'], icon: 'fas fa-tachometer-alt' },
        { type: 'link', label: 'Mi perfil', url: ['/', 'mi-cuenta', 'mi-perfil'], icon: 'fas fa-user' },
        { type: 'link', label: 'Solicitudes de compras', url: ['/', 'mi-cuenta', 'solicitudes-de-compras'], icon: 'far fa-thumbs-up' },
        { type: 'link', label: 'Pedidos', url: ['/', 'mi-cuenta', 'pedidos-pendientes'], icon: 'far fa-clock' },
        { type: 'link', label: 'Cotizaciones', url: ['/', 'mi-cuenta', 'cotizaciones'], icon: 'fas fa-file-invoice' },
        { type: 'link', label: 'Historial de compras', url: ['/', 'mi-cuenta', 'historial-de-compras'], icon: 'fas fa-history' },
        { type: 'link', label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off' }
      ];

    } else if (profile === 'cms') {

      this.linkMiCuenta = [
        { type: 'link', label: 'Tiendas', url: ['/', 'mi-cuenta', 'tiendas'], icon: 'fas fa-store-alt' },
        { type: 'link', label: 'Diapositivas', url: ['/', 'mi-cuenta', 'diapositivas'], icon: 'fas fa-tv' },
        { type: 'link', label: 'Imágenes', url: ['/', 'mi-cuenta', 'imagenes'], icon: 'fas fa-images' },
        { type: 'link', label: 'Blog', url: ['/', 'mi-cuenta', 'blog'], icon: 'fab fa-blogger' },
        { type: 'link', label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off' }
      ];

    } else if (profile === 'compradorb2c') {

      this.linkMiCuenta = [
        { type: 'link', label: 'Resumen', url: ['/', 'mi-cuenta', 'resumen'], icon: 'fas fa-tachometer-alt' },
        { type: 'link', label: 'Mi perfil', url: ['/', 'mi-cuenta', 'mi-perfil'], icon: 'fas fa-user' },
        { type: 'link', label: 'Portal de pagos', url: ['/', 'mi-cuenta', 'portal-de-pagos'], icon: 'fas fa-money-check-alt' },
        { type: 'link', label: 'Cotizaciones', url: ['/', 'mi-cuenta', 'cotizaciones'], icon: 'fas fa-file-invoice' },
        { type: 'link', label: 'Historial de compras', url: ['/', 'mi-cuenta', 'historial-de-compras'], icon: 'fas fa-history' },
        { type: 'link', label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off' }
      ];

    } else {

      this.linkMiCuenta = [];

    }

    return this.linkMiCuenta;
  }

}
