import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Ng2Rut, RutValidator } from 'ng2-rut';
import { AgmCoreModule } from '@agm/core';

// directives
import { ClickDirective } from './directives/click.directive';
import {
    CollapseContentDirective,
    CollapseDirective,
    CollapseItemDirective,
} from './directives/collapse.directive';
import { DepartmentsAreaDirective } from './directives/departments-area.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { FakeSlidesDirective } from './directives/fake-slides.directive';
import { OwlPreventClickDirective } from './directives/owl-prevent-click.directive';
import { DragDropDirective } from './directives/drag-drop.directive';

// components
import { AlertComponent } from './components/alert/alert.component';
import { IconComponent } from './components/icon/icon.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductComponent } from './components/product/product.component';
import { QuickviewComponent } from './components/quickview/quickview.component';
import { RatingComponent } from './components/rating/rating.component';

// pipes
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { CapitalizeFirstPipe } from './pipes/capitalize.pipe';

import { ProductLineBossComponent } from './components/product-line-boss/product-line-boss.component';
import { ProductFeatureSpecialComponent } from './components/product-feature-special/product-feature-special.component';
import { LoadingElementComponent } from './loading-element/loading-element.component';
import { SocialReviewsComponent } from './components/social-reviews/social-reviews.component';
import { ProductsViewComponent } from '../modules/shop/components/products-view/products-view.component';
import { ProductSliderComponent } from './components/product-slider/product-slider.component';
import { FiltersProductsComponent } from './components/filters-products/filters-products.component';
import { TopMenuB2bComponent } from './components/top-menu-b2b/top-menu-b2b.component';
import { BlockHeaderComponent } from '../modules/blocks/components/block-header/block-header.component';
import { AlertCartComponent } from './components/alert-cart/alert-cart.component';
import { GuidedStepsComponent } from './components/guided-steps/guided-steps.component';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProductStockComponent } from './components/product-stock/product-stock.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UploadExcelCartComponent } from './components/upload-excel-cart/upload-excel-cart.component';
import { AlertCartMinComponent } from './components/alert-cart-min/alert-cart-min.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ProductTabsComponent } from '../modules/shop/components/product-tabs/product-tabs.component';
import { CardDashboardComponent } from './components/card-dashboard/card-dashboard.component';
import { CardDashboardChartComponent } from './components/card-dashboard-chart/card-dashboard-chart.component';

import { DataTablesModule } from 'angular-datatables';
import { ViewPdfComponent } from './components/view-pdf/view-pdf.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { InlineSVGModule } from 'ng-inline-svg';

// RECOMMENDED
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { LoginComponent } from '../modules/header/components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { WidgetProductsComponent } from '../modules/widgets/widget-products/widget-products.component';
import { ChatComponent } from './components/chat/chat.component';
import { MapComponent } from './components/map/map.component';
import { PhoneCharDirective } from './directives/phone-char.directive';
import { LettersCharDirective } from './directives/letters-char.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { RutCharsDirective } from './directives/rut-chars.directive';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { ProductSlideshowComponent } from './components/product-slideshow/product-slideshow.component';
import { AgregarDireccionesComponent } from './components/agregar-direcciones/agregar-direcciones.component';
import { PageCartComponent } from '../modules/cart/page/page-cart/page-cart.component';
import { DetalleCarroProductosComponent } from '../modules/cart/components/detalle-carro-productos/detalle-carro-productos.component';
import { SafePipe } from './pipes/safe.pipe';
import { DireccionEmpresaComponent } from './components/direccion-empresa/direccion-empresa.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GiroEmpresaComponent } from './components/giro-empresa/giro-empresa.component';
import { ContactosEmpresaComponent } from './components/contactos-empresa/contactos-empresa.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { DetalleCompraComponent } from './components/detalle-compra/detalle-compra.component';
import { FiltroHistorialComprasComponent } from './components/filtro-historial-compras/filtro-historial-compras.component';

//widget

@NgModule({
    declarations: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        DragDropDirective,
        PhoneCharDirective,
        LettersCharDirective,
        OnlyNumbersDirective,
        DisableControlDirective,
        RutCharsDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        // pipes
        CurrencyFormatPipe,
        SlugifyPipe,
        TitleCasePipe,
        SafePipe,
        CapitalizeFirstPipe,
        ProductLineBossComponent,
        ProductFeatureSpecialComponent,
        LoadingElementComponent,
        SocialReviewsComponent,
        ProductsViewComponent,
        ProductSliderComponent,
        FiltersProductsComponent,
        TopMenuB2bComponent,
        BlockHeaderComponent,
        AlertCartComponent,
        AlertCartMinComponent,
        GuidedStepsComponent,
        ProductStockComponent,
        UploadExcelCartComponent,
        ProductTabsComponent,
        CardDashboardComponent,
        CardDashboardChartComponent,
        ViewPdfComponent,
        OrderDetailsComponent,
        LoginRegisterComponent,
        LoginComponent,
        RegisterComponent,
        WidgetProductsComponent,
        ChatComponent,
        MapComponent,
        AddressModalComponent,
        ProductSlideshowComponent,
        AgregarDireccionesComponent,
        PageCartComponent,
        DetalleCarroProductosComponent,
        DireccionEmpresaComponent,
        GiroEmpresaComponent,
        ContactosEmpresaComponent,
        DetalleCompraComponent,
        FiltroHistorialComprasComponent,
    ],
    imports: [
        // modules (angular)
        CommonModule,
        NgSelectModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // modules (third-party)
        CarouselModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        ScrollToModule.forRoot(),
        DataTablesModule,
        CollapseModule.forRoot(),
        InlineSVGModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD_HuwF5F8X8fOSR_1Ai_hFT115caUq4vI',
        }),
        Ng2Rut,
        MatCheckboxModule,
        //GeocoderModule
    ],
    exports: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        PhoneCharDirective,
        LettersCharDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        LoadingElementComponent,
        ModalModule,
        TabsModule,
        SocialReviewsComponent,
        ProductsViewComponent,
        ProductSliderComponent,
        FiltersProductsComponent,
        TopMenuB2bComponent,
        BlockHeaderComponent,
        AlertCartComponent,
        AlertCartMinComponent,
        GuidedStepsComponent,
        ProductLineBossComponent,
        ProductFeatureSpecialComponent,
        ProductStockComponent,
        UploadExcelCartComponent,
        ProductTabsComponent,
        CardDashboardComponent,
        CardDashboardChartComponent,
        ViewPdfComponent,
        OrderDetailsComponent,
        CollapseModule,
        LoginRegisterComponent,
        LoginComponent,
        RegisterComponent,
        WidgetProductsComponent,
        ChatComponent,
        MapComponent,
        AddressModalComponent,
        ProductSlideshowComponent,
        AgregarDireccionesComponent,
        PageCartComponent,
        DetalleCarroProductosComponent,
        // pipes
        CurrencyFormatPipe,
        SlugifyPipe,
        DecimalPipe,
        TitleCasePipe,
        CapitalizeFirstPipe,
        SafePipe,
        DireccionEmpresaComponent,
        GiroEmpresaComponent,
        ContactosEmpresaComponent,
        FiltroHistorialComprasComponent
    ],
    providers: [
        SlugifyPipe,
        DecimalPipe,
        CapitalizeFirstPipe,
        DatePipe,
        CurrencyFormatPipe,
        RutValidator,
        NumberFormatPipe,
    ],
})
export class SharedModule {}
