import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ClientsService } from '../../shared/services/clients.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  private urlApi = environment;

  constructor(
    private http: HttpClient
  ) { }

  obtieneTiendas() {
    // return this.http.get(environment.apiImplementosCMS + 'tiendas/');
    return this.http.get(environment.apiCMS + 'stores/');
  }

  crearTienda(data) {
    var call = environment.apiCMS + `stores`;
    return this.http.post(call, data);
  }

  updateTienda(data) {
    var call = environment.apiCMS + `stores/` + data['_id'];
    return this.http.patch(call, data);
  }

  deleteTienda(data) {
    var call = environment.apiCMS + `stores/` + data['_id'];
    return this.http.delete(call, data);
  }


}
