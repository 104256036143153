import { Component, OnInit, ElementRef, Input, ViewChild, EventEmitter, AfterViewInit, Output } from '@angular/core';
import { LogisticsService } from '../../../../shared/services/logistics.service';
import { ResponseApi } from 'src/app/shared/interfaces/response-api';
import { TiendaLocation } from '../../../../shared/interfaces/geo-location';
import { BsModalRef } from 'ngx-bootstrap';
import { GeoLocationService } from '../../../../shared/services/geo-location.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-stores',
  templateUrl: './modal-stores.component.html',
  styleUrls: ['./modal-stores.component.scss']
})

export class ModalStoresComponent implements OnInit, AfterViewInit {

  @ViewChild('modalTemplate', { static: false }) modalTemplate: ElementRef;
  @Input() modalRef: BsModalRef;
  @Output() template = new EventEmitter<any>();


  tiendas: TiendaLocation[];
  tienda: TiendaLocation;
  tiendaTemporal: TiendaLocation;


  constructor(
    private logisticsService: LogisticsService,
    private geoLocationService: GeoLocationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.tienda = this.geoLocationService.geoLocation.tiendaSelecciona;

    this.logisticsService.obtenerTiendas().subscribe((r: ResponseApi) => {
      this.tiendas = r.data;
    });
  }

  ngAfterViewInit(): void {
    this.template.emit(this.modalTemplate);
  }

  estebleceTienda(item) {
    console.log(item);
    this.tiendaTemporal = item;
  }

  cambiarTienda() {
    const tienda = this.tiendaTemporal;
    const coord = {
      lat: this.tiendaTemporal.lat,
      lon: this.tiendaTemporal.lon,

    };
    this.geoLocationService.cambiarTiendaCliente(coord, tienda);
    this.modalRef.hide();
    // window.location.reload();

  }

  cambiarTiendaMasCercana() {
  }


}
