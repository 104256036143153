import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { Product, ProductPrecio, ProductoPrecios } from '../../interfaces/product';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { QuickviewService } from '../../services/quickview.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'angular-2-local-storage';
import { CartData } from '../../interfaces/cart-item';
import { Usuario } from '../../interfaces/login';
import { GeoLocationService } from '../../services/geo-location.service';
import { CalcularTotalComprasService } from '../../../shared/services/calcular-total-compras.service'



@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    @Input() set product(value: Product) {
        value = this.calcularTotalComprasService.calcularPrecio(value);

        this.productData = value;
        this.productData.atributos = [...this.root.ObjectToArrayAtributos(value.atributos)];

        console.log('value');
        console.log(value);
        // this.cargaPrecio(value);
        this.quality = this.root.setQuality(this.productData);
        // this.root.limpiaAtributos(value);
    }

    @Input() layout: 'grid-sm' | 'grid-nl' | 'grid-lg' | 'list' | 'horizontal' | null = null;
    @Input() grid;
    @Input() paramsCategory;

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    productData: Product;
    quality: any = 0;
    precioProducto = 0;


    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        public compare: CompareService,
        public quickview: QuickviewService,
        public currency: CurrencyService,
        private toast: ToastrService,
        private localS: LocalStorageService,
        private geoLocationService: GeoLocationService,
        private calcularTotalComprasService: CalcularTotalComprasService,
    ) { }

    ngOnInit(): void {
        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    //cargaPrecio(producto: Product) {
    //    // verificamos si esta la session iniciada
    //    const usuario: Usuario = this.localS.get('usuario');
    //    let rut = 0;
//
    //    if (usuario != null) {
    //        rut = usuario.rut;
    //    }
    //    // this.productData.precio = {
    //    //     rut,
    //    //     precio: producto.precios[0].precio
    //    // };
    //}

    addToCart(): void {

        // const usuario = this.root.getDataSesionUsuario();
        // if (usuario == null) {
        //     this.toast.warning('Debe iniciar sesion para poder comprar', 'Información');
        //     return;
        // }

        if (this.addingToCart) {
            return;
        }



        this.addingToCart = true;
        
        this.cart.add(this.productData, 1).subscribe({
            complete: () => {
                this.addingToCart = false;
                this.cd.markForCheck();
            }
        });
    }

    addToWishlist(): void {
        if (this.addingToWishlist) {
            return;
        }

        this.addingToWishlist = true;
        console.log(this.productData);


        this.wishlist.add(this.productData).subscribe({
            complete: () => {
                this.addingToWishlist = false;
                this.cd.markForCheck();
            }
        });
    }

    addToCompare(): void {
        if (this.addingToCompare) {
            return;
        }

        this.addingToCompare = true;
        this.compare.add(this.productData).subscribe({
            complete: () => {
                this.addingToCompare = false;
                this.cd.markForCheck();
            }
        });
    }

    showQuickview(): void {
        if (this.showingQuickview) {
            return;
        }

        this.showingQuickview = true;
        this.quickview.show(this.productData).subscribe({
            complete: () => {
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }

}
