import { AfterViewInit, Component, Inject, NgZone, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CartService } from './shared/services/cart.service';
import { CompareService } from './shared/services/compare.service';
import { WishlistService } from './shared/services/wishlist.service';
import { CurrencyService } from './shared/services/currency.service';
import { ProductCart } from './shared/interfaces/cart-item';
import { AlertCartMinComponent } from './shared/components/alert-cart-min/alert-cart-min.component';
import { SeoService } from './shared/services/seo.service';
import { GeoLocationService } from './shared/services/geo-location.service';
import { NotificacionesWsService } from './shared/services/notificaciones-ws.service';
import { Notificaciones } from './shared/interfaces/notificaciones';
import { ConfiguracionesModel } from './models/configuraciones.model';
import { ConfiguracionesService } from './shared/services/configuraciones.service';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
    modalRef: BsModalRef;
    productCard: ProductCart;
    showAlertCartStatus = false;

    @ViewChild('alertCartModal', { read: TemplateRef, static: false }) template: TemplateRef<any>;
    // @ViewChild('AlertCartMinComponent', { read: AlertCartMinComponent, static: false }) alertCartMinComponent: AlertCartMinComponent;
    @ViewChild(AlertCartMinComponent, { read: AlertCartMinComponent, static: false }) alert: AlertCartMinComponent;

    dataNotificaciones: Notificaciones;
    configuraciones: ConfiguracionesModel;


    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private toastr: ToastrService,
        private cart: CartService,
        private compare: CompareService,
        private wishlist: WishlistService,
        private zone: NgZone,
        private scroller: ViewportScroller,
        private currency: CurrencyService,
        private modalService: BsModalService,
        private seoService: SeoService,
        private geoService: GeoLocationService,
        private notificacionesWsService: NotificacionesWsService,
        private configuracionesService: ConfiguracionesService

    ) { }

    ngOnInit(): void {
        this.configuracionesService.actualizarVariablesMemoria();

        this.seoService.generarMetaTag({});

        // properties of the CurrencyFormatOptions interface fully complies
        // with the arguments of the built-in pipe "currency"
        // https://angular.io/api/common/CurrencyPipe
        this.currency.options = {
            code: 'CLP',
            // display: 'symbol',
            digitsInfo: '1.0-0',
            // locale: 'en-US'
            // locale: 'CLP'
        };



        // this.router.events.subscribe((event) => {
        //     if ((event instanceof NavigationEnd)) {
        //         this.scroller.scrollToPosition([0, 0]);
        //     }
        // });

        this.cart.onAdding$.subscribe((product: ProductCart) => {
            this.productCard = product;
            //    this.openModal(this.template);
            this.showAlertCart(true);
        });
        this.compare.onAdding$.subscribe(product => {
            this.toastr.success(`Producto "${product.nombre}" agregado para comparar!`);
        });
        this.wishlist.onAdding$.subscribe(product => {
            this.toastr.success(`Producto "${product.nombre}" agregado a la lista de deseos!`);
        });

        // Notificaciones
        // this.notificacionesWsService.conexionWs();

        this.configuracionesService.$variables.subscribe(data => {
            console.log(data)
            if (data != null) {

                this.seoService.generarMetaTag({
                    title: `${data.sitio_web_nombre} - ${data.sitio_web_subtitulo}`,
                    description: data.sitio_web_descripcion,
                    keywords: data.sitio_web_keywords
                });
            }

        });
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            // this.geoService.getGeoLocation();

            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    const preloader = document.querySelector('.site-preloader');

                    preloader.addEventListener('transitionend', (event: TransitionEvent) => {
                        if (event.propertyName === 'opacity') {
                            preloader.remove();
                        }
                    });
                    preloader.classList.add('site-preloader__fade');
                }, 300);
            });
        }

        // setTimeout(() => {
        //     this.openModalWithComponent();
        // }, 4000);
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-alert-cart-dialog' }));
    }
    showAlertCart(status) {

        this.alert.hide();
        this.alert.show(status)
    }
}
