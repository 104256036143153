import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: any, money: any = ''): any {
        let resultado = '';

        if (typeof value === 'undefined' || value == null) {
            value = 0;
        }

        if (typeof value === 'string') {
            value = parseInt(value);
        }

        if (value < 0) {
            const nuevoValor = value * -1;
            resultado = nuevoValor.toLocaleString('es-CL');
            return '-' + money + resultado;
        } else {
            resultado = value.toLocaleString('es-CL');
            return money + resultado;
        }
    }
}
