import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-concepts',
  templateUrl: './block-concepts.component.html',
  styleUrls: ['./block-concepts.component.scss']
})
export class BlockConceptsComponent implements OnInit {
  public conceptos = [

    {
      hijos: [
        // {
        //   image: 'assets/images/concepts/500X500/CUADRADO-ACCESORIOS-REMOLQUE.png',
        //   url: '#/inicio/productos/todos/categoria/componentes-remolques-y-semiremolques',
        //   type: 'alta'
        // },

        {
          image: 'assets/images/concepts/500X1000/VERTICAL-CUIDADO-DEL-VEHICULO.png',
          url: ['/', 'inicio', 'productos', 'todos', 'categoria', 'accesorios-y-limpieza-automotriz'],
          type: 'normal',
          text: 'Cuidado vehiculo',
          bg: '#fd0001',
          color: '#fff'

        },

      ]
    },
    {
      hijos: [
        {
          image: 'assets/images/concepts/500X500/CUADRADO-ALT-MOT.png',
          url: ['/', 'inicio', 'productos', 'todos', 'categoria', 'motor-y-transmision', 'motores-de-partida-y-componentes'],
          type: 'normal',
          text: 'Motor de partida',
          bg: '#006cc4'

        },
        {
          image: 'assets/images/concepts/500X500/CUADRADO-AMARRE-CARGA.png',
          url: ['/', 'inicio', 'productos', 'todos', 'categoria', 'amarre-de-carga'],
          type: 'normal',
          text: 'Carga y amarre',
          bg: '#fb7400'

        },
      ]
    },
    {
      hijos: [
        {
          image: 'assets/images/concepts/500X1000/VERTICAL-EMBRAGUE.png',
          url: ['/', 'inicio', 'productos', 'todos', 'categoria', 'motor-y-transmision', 'discos-de-embrague'],

          type: 'normal',
          text: 'discos de embrague',
          bg: '#5D471E'
        },
        // {
        //   image: 'assets/images/concepts/500X500/CUADRADO-ILUMINACIÓN-REMOLQUE.png',
        //   url: '#/inicio/productos/todos/categoria/accesorios-de-iluminacion',
        //   type: 'normal'
        // },
      ]
    }, {
      hijos: [
        {
          image: 'assets/images/concepts/500X500/CUADRADO-OPTICO-Y-RETRO-B.png',
          url: ['/', 'inicio', 'productos', 'todos', 'categoria', 'iluminacion', 'focos'],
          type: 'normal',
          text: 'Opticos',
          bg: '#002d53'
        },
        {
          image: 'assets/images/concepts/500X500/CUADRADO-ORGANIZACION.png',
          url: ['/', 'inicio', 'productos', 'todos', 'categoria', 'seguridad-herramientas-y-tiempo-libre', 'cajas-y-organizadores'],
          type: 'normal',
          text: 'Organización',
          bg: '#c0dff0',
          color: '#ad2910'

        }
      ]
    }

  ];


  constructor() { }

  ngOnInit() {

    //   const arr = [];
    //   let cont = 0;
    //   let columnas = 0;
    //   const numeroColumnas = 4;
    //   const imagenesPorColumnasDef = 2
    //   arr.push({ hijos: [] });

    //   this.items.map((item, index) => {
    //     let imagenesPorColumnas = imagenesPorColumnasDef;
    //     if (item.type == 'alta') {
    //       imagenesPorColumnas = 1

    //     } else {
    //       imagenesPorColumnas = 2;
    //     }

    //     const r = (index + 1) / imagenesPorColumnasDef;
    //     const entero = Number.isInteger(r);

    //     if (index <= numeroColumnas) {
    //       arr[columnas].hijos.push(item);
    //     }

    //     if (entero && index < (this.items.length - 1)) {
    //       arr.push({ hijos: [] });
    //       columnas++;
    //     }

    //     // cont

    //   });

    //   this.conceptos = arr;
  }

}
