import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlidesService {

  constructor(
    private http: HttpClient
  ) { }

  obtieneSlides() {
    return this.http.get(environment.apiCMS + 'slides/');
  }

  crearSlide(data) {
    var call = environment.apiCMS + `slides`;
    return this.http.post(call, data);
  }

  updateSlide(data) {
    var call = environment.apiCMS + `slides/` + data['_id'];
    return this.http.patch(call, data);
  }

  deleteSlide(data) {
    var call = environment.apiCMS + `slides/` + data['_id'];
    return this.http.delete(call, data);
  }


}
