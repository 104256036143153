import { Component, OnInit } from "@angular/core";
import { GmailCargoService } from "src/app/shared/services/gmail-cargo.service";
import * as _ from 'lodash';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { isUndefined } from "util";
import { RootService } from '../../../../shared/services/root.service';

@Component({
    selector: "app-rarama-page-cargo",
    templateUrl: "./rarama-page-cargo.component.html",
    styleUrls: ["./rarama-page-cargo.component.scss"],
})
export class RaramaPageCargoComponent implements OnInit {
    status = false;
    loading = false;
    form: FormGroup;
    trackingNow = "";
    arrayTracking = [];
    positioTracking = 0;
    dateTracking = [];
    orden : any;
    ordenTransporteDetalles : any[any];
    notData = false;

    constructor(
        private gmailCargoService: GmailCargoService,
        private formBuilder: FormBuilder,
        private root: RootService
    ) {
        this.createForm();
    }

    async createForm() {
        this.form = this.formBuilder.group({
            id: ["", [Validators.required]],
        });
    }

    ngOnInit() { }
    public listaEstados = ['registrado','preparado','enviado','recibido','entregado'];
    public async info() {
        if(!this.form.value.id||this.form.value.id==''){
            return;
        }
        try {
            this.form.value.id = parseInt(this.form.value.id);
        }catch(e){
            return;
        }
        if(!(this.form.value.id>=0)){
            return;
        }

        this.loading = true;
        this.dateTracking = [];
        this.arrayTracking = [];
        this.positioTracking = 0;
        this.notData = false;

        await this.gmailCargoService
            .getData(this.form.value.id, 'numero_orden')
            .toPromise()
            .then((data: any) => {

                this.status = true;

                console.log(data)

                if (data.data.length == 0 || this.form.invalid) {
                    this.notData = true;
                    this.loading = false;
                    return; 
                }

                this.orden = data.data[0];
                this.ordenTransporteDetalles = (this.orden)?this.orden.ordenTransporteDetalle: [];
                this.ordenTransporteDetalles = this.ordenTransporteDetalles.filter((item) => item.codigo_tracking!=null);
                this.ordenTransporteDetalles = (this.ordenTransporteDetalles.length > 0)?  _.orderBy(this.ordenTransporteDetalles,['codigo_tracking'], ['asc'] ) : [];

                console.log(this.ordenTransporteDetalles)

                this.ordenTransporteDetalles.map((item) => {
                    item.arrayTracking = item.ordenTransporteDetalleHistorialEstados;
                    item.dateTracking = [];
                    item.trackingNow = '';
                    item.tracking = '';
                    for (let i = 0; i < item.arrayTracking.length; i++) {
                        item.trackingNow = item.arrayTracking[i].ordenTransporteDetalleEstado.slug;
                        item.dateTracking.push(
                            item.arrayTracking[i].created_at.substring(0, 10)
                        );
                    }
                    item.positioTracking = item.arrayTracking.length;
                    return item;
                });
                console.log(this.ordenTransporteDetalles);

                console.log(this.ordenTransporteDetalles)

                /*for (let i = 0; i < this.arrayTracking.length; i++) {
                    this.trackingNow = this.arrayTracking[i].slug;
                    this.dateTracking.push(
                        this.arrayTracking[i].created_at.substring(0, 10)
                    );
                }
                this.positioTracking = this.arrayTracking.length;*/

                this.loading = false;


            }, e => {
                this.root.validaError(e);
                this.loading = false;

            });
    }
    public geIdEstado(item){
        return this.listaEstados.indexOf(item);
    }
}
