import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { ProductCart } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { Subject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface Item {
    ProductCart: ProductCart;
    quantity: number;
    quantityControl: FormControl;
}

@Component({
    selector: 'app-header-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss'],
})
export class DropcartComponent implements OnInit, OnDestroy {
    removedItems: ProductCart[] = [];

    private destroy$: Subject<void> = new Subject();
    @Input() cartDropdown: any;

    items: Item[] = [];
    updating = false;
    saveTimer: any;
    saveTimerLocalCart: any;

    products: ProductCart[] = [];

    constructor(
        public cart: CartService,
        public root: RootService,
        private toast: ToastrService
    ) {}

    ngOnInit(): void {
        this.cart.items$
            .pipe(
                takeUntil(this.destroy$),
                map((ProductCarts) =>
                    ProductCarts.map((item) => {
                        return {
                            ProductCart: item,
                            quantity: item.cantidad,
                            quantityControl: new FormControl(
                                item.cantidad,
                                Validators.required
                            ),
                        };
                    })
                )
            )
            .subscribe((items) => {
                this.items = items;
            });
    }

    remove(item: ProductCart): void {
        item.cargandoEliminarBtn = true;
        console.log(item);
        this.cart.remove(item).subscribe(
            (r) => {
                item.cargandoEliminarBtn = false;
                this.toast.success('Producto eliminado correctamente', 'Proceso correcto');
            },
            (e) => {
                console.log(e);
                this.toast.error('Ha ocurrido un error al eliminar el producto', 'Error');
            }
        );
    }

    ngOnDestroy(): void {
        // this.destroy$.next();
        // this.destroy$.complete();
    }

    updateCart(cantidad: number, item: any) {
        console.log('1212');
        // return

        if (cantidad < 1) {
            cantidad = 1;
            this.toast.error('No se permiten números negativos en la cantidad');
        }

        item.ProductCart.cantidad = cantidad;
        this.products = [];
        this.items.map((r) => {
            this.products.push(r.ProductCart);
        });

        clearTimeout(this.saveTimerLocalCart);
        this.saveTimerLocalCart = setTimeout(() => {
            this.cart.updateCart(this.products);
        }, 100);

        this.saveCart();
    }

    saveCart() {
        clearTimeout(this.saveTimer);
        this.saveTimer = setTimeout(() => {
            const productos = this.items.map((item) => {
                return {
                    id: item.ProductCart.id,
                    cantidad: item.quantity,
                };
            });
            this.cart.saveCart(productos).subscribe((r) => {});
        }, 700);
    }
}
