import { ProductReview } from '../app/shared/interfaces/product';

export const reviews: ProductReview[] = [
    {
        avatar: 'assets/images/avatars/avatar-1.png',
        author: 'Rolando Perez',
        rating: 4,
        date: '11/08/2019',
        text: 'Excelente servicio de atención al público. Inicialmente no supe la pieza que necesitaba, pero me asesoraron hasta encontrarla.'
    },
    {
        avatar: 'assets/images/avatars/avatar-2.png',
        author: 'Maicol Ortiz',
        rating: 4,
        date: '12/08/2019',
        text: 'El producto es de excelente calidad pese a ser alternativo. El despacho fue rápido.'
    },
    {
        avatar: 'assets/images/avatars/avatar-3.png',
        author: 'Esteban Basaez',
        rating: 5,
        date: '10/08/2019',
        text: 'El productos cumple con lo que promete, lo recomiendo no los defraudará.'
    },
    {
        avatar: 'assets/images/avatars/avatar-4.png',
        author: 'Kristina Kuppers',
        rating: 5,
        date: '12/07/2019',
        text: 'Precios excelentes. Y si te registras en su B2B, muuuuucho mejor aún.. A quienes no lo hayan hecho, los invito a hacerlo.'
    }
];
