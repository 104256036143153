import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-search-aplication-slider',
  templateUrl: './block-search-aplication-slider.component.html',
  styleUrls: ['./block-search-aplication-slider.component.sass']
})
export class BlockSearchAplicationSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
