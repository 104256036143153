import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LocalStorageService } from 'angular-2-local-storage';
import { VariablesAgrupadas } from '../interfaces/variables';
import { BehaviorSubject } from 'rxjs';
import { ConfiguracionesModel } from '../../models/configuraciones.model';
import { environment } from 'src/environments/environment';
import { RootService } from './root.service';
import { RespuestaApi } from '../interfaces/respuesta-api';
import { FormGroup } from '@angular/forms';
import { DatosPorDefecto } from '../../shared/interfaces/datos-por-defecto';


@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private root: RootService
  ) { }

  private variablesSubject = new BehaviorSubject<any>(null);
  public $variables = this.variablesSubject.asObservable();

  nombres() {
    return {
      categoria_uno: "Categoria uno",
      categoria_dos: "Categoria dos",
      categoria_tres: "Categoria tres",
      precio_minimo: 10,
      marca: "Laboratorio",
    };
  }

  /**
 * Carga las variables y las graba en memoria cuando se actualiza la pagina
 */
  actualizarVariablesMemoria() {
    const parametros = { formato: 'agrupado' };
    let variablesAgrupadas;
    this.obtenerVariables(parametros).subscribe((r: RespuestaApi) => {
      variablesAgrupadas = r.data;
      this.guardarVariablesEnMemoria(variablesAgrupadas);
    }, e => {
      this.root.validaError(e);
    });

  }
  guardarVariablesEnMemoria(variables: VariablesAgrupadas[]) {
    const procesadas = this.procesarVariables(variables);
    this.localStorageService.set('variablesSistema', procesadas);
    this.variablesSubject.next(procesadas);

  }

  obtenerVariablesEnMemoria(): ConfiguracionesModel {

    if (
      typeof this.localStorageService.get('variablesSistema') === 'undefined'
      || this.localStorageService.get('variablesSistema') === null
      || Object.keys(this.localStorageService.get('variablesSistema')).length === 0
    ) {
      const configuraciones: ConfiguracionesModel = new ConfiguracionesModel();
      return configuraciones;
    }

    const configuracionesModel: ConfiguracionesModel = new ConfiguracionesModel();
    return { ...configuracionesModel, ...this.localStorageService.get('variablesSistema') };
  }

  private procesarVariables(variables: VariablesAgrupadas[]) {
    let obj = {};
    for (const item of variables) {
      for (const variable of item.configuracionVariable) {
        obj[variable.slug] = variable.valor;
      }
    }
    return obj;
  }

  obtenerVariables(params) {
    return this.http.get(`${environment.apiMountain}configuraciones`, { params });
  }

  obtenerDatosPorDefecto(slugSubgrupo: string) {
    return this.http.get<RespuestaApi<DatosPorDefecto>>(
      `${environment.apiMountain}configuraciones/datos-defecto`,
      { params: { slug: slugSubgrupo } }
    );
  }

  deshabilitarCampos(form: FormGroup, datos, accion = 'crear') {

    let deshabilitarAccion = 'deshabilitado_crear';
    if (accion == 'editar') {
      deshabilitarAccion = 'deshabilitado_editar';
    }
    for (let item of Object.keys(datos)) {
      if (datos[item][deshabilitarAccion]) {
        form.controls[item].disable();
      }
    }
  }

  obtenerVariablesPorModulo(moduloRuta: Array<any>) {
    const params = { grupos: moduloRuta };
    return this.http.get(`${environment.apiMountain}configuraciones`, { params });
  }

  verificarExisteMenu(moduloRuta) {
    const params = { grupos: moduloRuta, formato: 'verficacion' };
    return this.http.get(`${environment.apiMountain}configuraciones`, { params })
  }

  guardarVariables(data) {
    return this.http.post(`${environment.apiMountain}configuraciones`, data);
  }


}
