import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-favorites',
  templateUrl: './btn-favorites.component.html',
  styleUrls: ['./btn-favorites.component.scss']
})
export class BtnFavoritesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
