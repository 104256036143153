import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Product } from "../interfaces/product";
import {
    CartData,
    ProductCart,
    CartTotal,
    Shipping,
    CarroApiParametros,
} from "../interfaces/cart-item";
import {
    BehaviorSubject,
    Observable,
    Observer,
    Subject,
    timer,
    throwError,
} from "rxjs";
import { map, filter } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RootService } from "./root.service";
import { error } from "@angular/compiler/src/util";
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "angular-2-local-storage";
import { Usuario } from "../interfaces/login";
import { DatePipe } from "@angular/common";
import { CapitalizeFirstPipe } from "../pipes/capitalize.pipe";
// import { ConsoleReporter } from 'jasmine';
import { GeoLocationService } from "./geo-location.service";
import { TiendaLocation, GeoLocation } from "../interfaces/geo-location";
import { ResponseApi } from "../interfaces/response-api";

@Injectable({
    providedIn: "root",
})
export class CartService {
    private urlApi = environment.apiMountain;

    private data: CartData = {
        productos: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0,
    };

    private itemsSubject$: BehaviorSubject<ProductCart[]> = new BehaviorSubject(
        this.data.productos
    );
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.quantity
    );
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.subtotal
    );
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(
        this.data.totals
    );
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.total
    );
    private onAddingSubject$: Subject<ProductCart> = new Subject();
    private CartData: CartData;
    cartSession: CartData;

    // muestra el dropdown carro de compras flotante
    public dropCartActive$: BehaviorSubject<boolean> = new BehaviorSubject(
        true
    );
    public CartDataSubject$: BehaviorSubject<CartData> = new BehaviorSubject(
        this.CartData
    );

    private shipping: CartTotal = null;
    private discount: CartTotal = null;
    loadingCart = false;

    get items(): ReadonlyArray<ProductCart> {
        return this.data.productos;
        return [];
    }

    get quantity(): number {
        return this.data.quantity;
    }

    readonly items$: Observable<
        ProductCart[]
    > = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<
        CartTotal[]
    > = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();
    readonly onAdding$: Observable<ProductCart> = this.onAddingSubject$.asObservable();
    readonly isDropCartActive$: Observable<any> = this.dropCartActive$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private http: HttpClient,
        private root: RootService,
        private localS: LocalStorageService,
        private toast: ToastrService,
        private datePipe: DatePipe,
        private capitalizePipe: CapitalizeFirstPipe,
        private geoLocationService: GeoLocationService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.geoLocationService.localizacionObs$.subscribe(
                (r: GeoLocation) => {
                    // console.log(r);
                    this.load();
                }
            );
        }

        this.load();
    }

    add(
        producto: Product,
        cantidad: number,
        options: { name: string; value: string }[] = []
    ): Observable<any> {
        // return;

        this.cartSession = this.localS.get("carroCompraB2B");
        const usuario = this.root.getDataSesionUsuario();
        let productoCarro;

        if (this.cartSession == null) {
            productoCarro = { cantidad: 0 };
        } else {
            productoCarro = this.cartSession.productos.find(
                (item) => item.id === producto.id
            ) || { cantidad: 0 };
        }

        const data: CarroApiParametros = {
            sessionId: usuario.id,
            productos: [
                {
                    id: producto.id,
                    cantidad: productoCarro.cantidad + cantidad,
                },
            ],
        };

        return this.http
            .post(`${environment.apiMountain}carro-compras`, data)
            .pipe(
                map((r: ResponseApi) => {
                    this.CartData = r.data;
                    const productoCart: ProductCart = {
                        nombre: producto.nombre,
                        subtitulo: producto.subtitulo,
                        codigo_interno: producto.codigo_interno,
                        cantidad,
                        imagen: this.root.getUrlImagenMiniatura(producto),
                    };

                    if (r.error !== true) {
                        this.onAddingSubject$.next(productoCart);
                        this.data.productos = this.CartData.productos;
                        this.save();
                        this.calc();
                    } else {
                        this.toast.error(r.msg);
                    }

                    return r.data;
                })
            );
    }

    public calc(): void {
        let quantity = 0;
        let subtotal = 0;
        let totalNeto = 0;

        this.data.productos.forEach((item) => {
            // quantity += item.cantidad;
            subtotal += item.valor_unitario * item.cantidad;
        });

        const totals: CartTotal[] = [];
        totalNeto = subtotal;
        subtotal = subtotal / 1.19;

        if (this.shipping != null) {
            totals.push(this.shipping);
        }

        if (this.discount != null) {
            totals.push(this.discount);
        }

        totals.push({
            title: "IVA",
            price: totalNeto - subtotal,
            type: "tax",
        });

        const total =
            subtotal +
            totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);
        this.data.quantity = this.data.productos.length;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.productos);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
    }


    guardarDespacho(data: any) {
        return this.http.post(
            `${environment.apiMountain}Despachos`,
            data
        );
    }

    guardarDespachoEnTienda(data: any) {
        return this.http.post(
            `${environment.apiMountain}Despachos/enTienda`,
            data
        );
    }

    private save(): void {
        this.localS.set("carroCompraB2B", this.CartData);
    }

    load(): void {
        if (this.loadingCart) {
            return;
        }
        this.loadingCart = true;

        const usuario: Usuario = this.localS.get("usuario");
        if (usuario == null) {
            return;
        }

        // console.log(usuario.id);
        this.http
            .get(
                environment.apiMountain +
                    `carro-compras?sessionId=${usuario.id}`
            )
            .pipe(
                map((r: ResponseApi) => {
                    this.loadingCart = false;
                    this.CartData = r.data;
                    this.CartDataSubject$.next(this.CartData);

                    if (!r.error) {
                        this.data.productos = this.CartData.productos;

                        // obtenemos el despacho desde el carro
                        // if (this.CartData.despacho) {

                        //     let nombre = '';
                        //     if (this.CartData.despacho.tipo == 'TIENDA') {
                        // nombre = `Retiro en tienda ` + this.datePipe.transform(this.CartData.despacho.fechaDespacho, 'EEEE dd MMM');
                        //     } else {
                        //         nombre = `Despacho ` + this.datePipe.transform(this.CartData.despacho.fechaDespacho, 'EEEE dd MMM');
                        //     }

                        //     this.shipping = {
                        //         price: this.CartData.despacho.precio,
                        //         title: nombre,
                        //         type: 'shipping'
                        //     };

                        //     // si existe descuento de despacho se agrega
                        //     if (this.CartData.despacho.descuento > 0) {
                        //         this.discount = {
                        //             price: this.CartData.despacho.descuento * -1,
                        //             title: 'Descuentos',
                        //             type: 'discount'
                        //         };

                        //     }

                        // }
                    } else {
                        this.data.productos = [];
                    }
                    this.calc();
                    this.save();
                })
            )
            .subscribe(
                (r) => {},
                (e) => {
                    console.log("error", e);
                }
            );
    }

    remove(item): Observable<any> {
        const carro: CartData = this.localS.get("carroCompraB2B");
        const usuario: Usuario = this.localS.get("usuario");

        const options = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
            body: {
                producto_id: item.id,
                web_session_id: usuario.id,
            },
        };

        return this.http
            .delete(environment.apiMountain + `carro-compra-detalles`, options)
            .pipe(
                map((r: any) => {
                    if (!r.error) {
                        if (r.data == null) {
                            this.CartData.productos = [];
                        } else {
                            this.CartData = r.data;
                        }

                        this.data.productos = this.CartData.productos;
                        this.save();
                        this.calc();
                    }
                    return r;
                })
            );
    }

    public updateCart(items) {
        this.CartData.productos = items;
        this.calc();
        this.save();
    }

    public saveCart(productos) {
        const usuario = this.root.getDataSesionUsuario();

        const data: CarroApiParametros = {
            sessionId: usuario.id,
            productos,
        };
        return this.http
            .post(`${environment.apiMountain}carro-compras`, data)
            .pipe(
                map((r: ResponseApi) => {
                    return r;
                })
            );
    }

    public updateShipping(despacho) {
        // const carro: CartData = this.localS.get('carroCompraB2B');
        // const usuario: Usuario = this.root.getDataSesionUsuario();
        // const productos = carro.productos.map(item => {
        //     return {
        //         id: item.id,
        //         cantidad: item.cantidad
        //     };
        // });
        // const data = {
        //     usuario: usuario.username,
        //     rut: usuario.rut,
        //     productos,
        //     despacho
        // };
        // // const data = data
        // return this.http.post(environment.apiImplementosCarro + 'articulo', data).pipe(map((r: any) => {
        //     return r;
        // }));
    }

    generaOrdenDeCompra(data) {
        return this.http.post(
            environment.apiImplementosCarro + `generar`,
            data
        );
    }

    purchaseRequest(data) {
        return this.http.put(
            environment.apiImplementosCarro + `solicitaraprobacion`,
            data
        );
    }

    refuseOrder(data) {
        return this.http.post(
            environment.apiImplementosCarro + `rechazarcarro`,
            data
        );
    }

    subeOrdenDeCompra(data) {
        const optionsHeader = {
            headers: new HttpHeaders({
                "Content-Type": "multipart/form-data",
            }),
        };
        const formData: FormData = new FormData();
        // tslint:disable-next-line: forin
        for (const key in data) {
            formData.append(key, data[key]);
        }

        return this.http.post(
            environment.apiImplementosCarro + "cargaoc",
            formData
        );
    }

    public addTotalShipping(envio) {
        this.shipping = envio;
        this.calc();
    }
    public removeTotalShipping() {
        this.shipping = null;
        this.calc();
    }

    public addTotaldiscount(envio) {
        this.discount = envio;
        this.calc();
    }

    public removeTotalDiscount() {
        this.discount = null;
        this.calc();
    }

    public uploadExcel(data) {
        const optionsHeader = {
            headers: new HttpHeaders({
                "Content-Type": "multipart/form-data",
            }),
        };
        const formData: FormData = new FormData();
        // tslint:disable-next-line: forin
        for (const key in data) {
            formData.append(key, data[key]);
        }

        return this.http.post(
            environment.apiImplementosCarro + "cargarlistado",
            formData
        );
    }

    public pendingOrders(data) {
        return this.http.post(
            environment.apiImplementosCarro + "listadoPedidos",
            data
        );
    }

    public getOrderDetail(id) {
        return this.http.get(
            environment.apiImplementosCarro + `detallePedido/${id}`
        );
    }

    public cartTransfer(data) {
        return this.http.post(
            environment.apiMountain + `carro-compras/transpaso`,
            data
        );
    }

    public getPriceProduct(params) {
        return this.http.get(environment.apiImplementosCarro + `buscaprecio`, {
            params,
        });
    }
}
