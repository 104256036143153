import { Component, OnInit } from '@angular/core';
import { RootService } from '../../../../shared/services/root.service';

@Component({
  selector: 'app-header-b',
  templateUrl: './header-b.component.html',
  styleUrls: ['./header-b.component.scss']
})
export class HeaderBComponent implements OnInit {

  constructor(
    public root: RootService
  ) { }

  ngOnInit() {
  }

}
