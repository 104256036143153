import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { ResponseApi } from '../../interfaces/response-api';
import { RootService } from '../../services/root.service';
import { CartData } from '../../interfaces/cart-item';


@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  @Input() set id(value) {
    this.getData(value);
  }
  @Input() title = 'Detalle';

  data: any;
  deliveryText: any;

  constructor(
    private cartService: CartService,
    private root: RootService
  ) { }

  ngOnInit() {
  }

  getData(id) {
    this.cartService.getOrderDetail(id).subscribe((r: ResponseApi) => {
      if (!r.error) {
        this.data = r.data;

        if (this.data.despacho.tipo === 'REP') {
          this.deliveryText = 'Despacho';

        } else {
          this.deliveryText = 'Retiro en tienda ' + this.data.despacho.origen;
        }
      }
    });
  }

}
