import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LogisticsService } from '../../services/logistics.service';
import { ResponseApi, ErrorApi } from '../../interfaces/response-api';
import { ToastrService } from 'ngx-toastr';
import { Comuna, FormNuevaDireccion } from '../../interfaces/address';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DireccionEmpresa } from '../../interfaces/empresa';
import { ClientsService } from '../../services/clients.service';
import { Usuario } from '../../interfaces/login';
import { RootService } from '../../services/root.service';

@Component({
  selector: 'app-agregar-direcciones',
  templateUrl: './agregar-direcciones.component.html',
  styleUrls: ['./agregar-direcciones.component.scss']
})
export class AgregarDireccionesComponent implements OnInit {

  @Output() actualizar: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelar: EventEmitter<boolean> = new EventEmitter();

  comunas: Comuna[] = [];
  form: FormGroup;
  usuario: Usuario;
  guardando = false;

  constructor(
    private toastr: ToastrService,
    private logisticsService: LogisticsService,
    private fb: FormBuilder,
    private clienteService: ClientsService,
    private root: RootService
  ) { }

  ngOnInit() {
    this.usuario = this.root.getDataSesionUsuario();

    this.form = this.fb.group({
      calle: ['', Validators.required],
      numero: ['', Validators.required],
      oficina: [''],
      comuna_id: [null, Validators.required]
    });

    this.cargarComunas();
  }

  cargarComunas() {
    this.logisticsService.obtieneComunas().subscribe((r: ResponseApi) => {
      if (r.error) {
        this.toastr.error(r.mensaje);
        return;
      }

      this.comunas = r.data;

    }, (e: ErrorApi) => {
      this.toastr.error(e.error.mensaje);
    });
  }

  cerrar() {
    this.cancelar.emit();
  }

  agregarDireccion() {


    const form = this.form.value;
    const direccion: DireccionEmpresa = {
      calle: form.calle,
      numero: form.numero,
      oficina: form.oficina,
      comuna_id: form.comuna_id,
      principal: true,
      empresa_id: this.usuario.empresa.id,
      direccion_completa: form.calle + ' ' + form.numero
    };
    this.guardando = true;
    this.clienteService.agregarDireccion(direccion).subscribe((r: ResponseApi) => {
      this.guardando = false;

      if (r.error) {
        this.toastr.error(r.mensaje);
        return;
      }

      this.actualizar.emit();

    }, (e: ErrorApi) => {
      this.toastr.error(e.error.mensaje);

    });


  }



}
