import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GiroService {

  constructor(private http: HttpClient) { }

  obtenerGiros() {
    return this.http.get(environment.apiMountain + `giros`);
  }
  obtenerGirosEmpresa(params) {
    return this.http.get(environment.apiMountain + `giros-empresas`, { params });
  }

  crearGiroEmpresa(data) {
    return this.http.post(environment.apiMountain + `giros-empresas`, data);
  }
}
