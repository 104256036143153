import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ConfiguracionesService } from './configuraciones.service';
import { ConfiguracionesModel } from '../../models/configuraciones.model';


@Injectable({
  providedIn: 'root'
})


export class SeoService {

  configuraciones: ConfiguracionesModel;

  constructor(
    private meta: Meta,
    private title: Title,
    private configuracionesService: ConfiguracionesService
  ) {

    this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
  }

  generarMetaTag(config) {

    // tslint:disable-next-line: max-line-length
    const desc = this.configuraciones.sitio_web_descripcion;

    config = {
      title: `${this.configuraciones.sitio_web_nombre} - ${this.configuraciones.sitio_web_subtitulo}`,
      description: desc,
      image: 'http://farmacia.ingydev.cl/assets/images/homepage.jpg',
      imageAlt: desc,
      imageType: null,
      type: 'website',
      slug: '',
      keywords: 'Medicamentos e insumos',
      ...config
    };

    this.title.setTitle(config.title);

    // this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    // this.meta.updateTag({ name: 'twitter:site', content: '@MiTwitter' });
    // this.meta.updateTag({ name: 'twitter:title', content: config.title });
    // this.meta.updateTag({ name: 'twitter:description', content: config.description });
    // this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: config.type });
    this.meta.updateTag({ property: 'og:site_name', content: this.configuraciones.sitio_web_nombre });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:image:alt', content: config.imageAlt });
    this.meta.updateTag({ property: 'og:locale', content: 'es_CL' });

    if (config.imageType != null) {
      this.meta.updateTag({ property: 'og:image:type', content: config.imageType });
    }

    this.meta.updateTag({ property: 'og:url', content: `http://farmacia.ingydev.cl/${config.slug}` });

  }
}
