import { Brand } from '../app/shared/interfaces/brand';

export const brands: any = [
    [
        { brand: 'agrale', image: 'assets/images/marcas/AGRALE_Mesa de trabajo 1.png' },
        { brand: 'alcoa', image: 'assets/images/marcas/ALCOA_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'automann', image: 'assets/images/marcas/AUTOMANN_Mesa de trabajo 1.png' },
        { brand: 'blacksmith', image: 'assets/images/marcas/BLACKSMITH_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'eco alu', image: 'assets/images/marcas/ECO ALU_Mesa de trabajo 1.png' },
        { brand: 'frasle', image: 'assets/images/marcas/FRASLE_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'frum', image: 'assets/images/marcas/FRUM_Mesa de trabajo 1.png' },
        { brand: 'haldex', image: 'assets/images/marcas/HALDEX_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'jost', image: 'assets/images/marcas/JOST_Mesa de trabajo 1.png' },
        { brand: 'luk', image: 'assets/images/marcas/LUK_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'marcopolo', image: 'assets/images/marcas/MARCOPOLO_Mesa de trabajo 1.png' },
        { brand: 'randon', image: 'assets/images/marcas/RANDON_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'sorl', image: 'assets/images/marcas/SORL_Mesa de trabajo 1.png' },
        { brand: 'stark', image: 'assets/images/marcas/STARK_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'suntech', image: 'assets/images/marcas/SUNTECH_Mesa de trabajo 1.png' },
        { brand: 'suspensys', image: 'assets/images/marcas/SUSPENSYS_Mesa de trabajo 1.png' },

    ],
    [
        { brand: 'trogrim', image: 'assets/images/marcas/TROGRIM_Mesa de trabajo 1.png' },
        { brand: 'jost', image: 'assets/images/marcas/JOST_Mesa de trabajo 1.png' },

    ]
];
