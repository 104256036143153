import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RootService } from '../../shared/services/root.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-b2c',
  templateUrl: './b2c.component.html',
  styleUrls: ['./b2c.component.scss']
})
export class B2cComponent  {
  headerLayout: string;

  constructor(
      public root: RootService,
      public router: Router,
      public route: ActivatedRoute
  ) {
      this.route.data.subscribe(data => this.headerLayout = data.headerLayout);
      this.root.path = this.router.createUrlTree(['./'], {relativeTo: route}).toString();

  }


}
