import { Component, TemplateRef, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ProductsService } from '../../../../shared/services/products.service';
import { RootService } from '../../../../shared/services/root.service';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { ToastrService, ToastrModule } from 'ngx-toastr';

import { FormControl, SelectControlValueAccessor } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CartService } from '../../../../shared/services/cart.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { Usuario } from '../../../../shared/interfaces/login';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DropdownDirective } from '../../../../shared/directives/dropdown.directive';
import { LoginService } from '../../../../shared/services/login.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { GeoLocationService } from '../../../../shared/services/geo-location.service';
import { GeoLocation, TiendaLocation } from '../../../../shared/interfaces/geo-location';
import { ResponseApi } from '../../../../shared/interfaces/response-api';
import { ProductSearchSimple } from 'src/app/shared/interfaces/product';


@Component({
    selector: 'app-header-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

    @ViewChild('modalSearch', { read: TemplateRef, static: false }) template: TemplateRef<any>;
    @ViewChild('menuSearch', { static: false }) listSearch: ElementRef;
    @ViewChild('menuTienda', { static: false }) menuTienda: DropdownDirective;
    @ViewChild(DropdownDirective, { static: false }) dropdown: DropdownDirective;

    mostrarMenu = false;
    showWelcome = false;
    usuario: Usuario;
    destroy$: Subject<boolean> = new Subject<boolean>();

    linkMiCuenta = [
        { label: 'Mi perfil', url: ['/', 'mi-cuenta', 'resumen'], icon: 'far fa-user' },
        // { label: 'Mis Pedidos', url: ['/', 'mi-cuenta', 'mis-compras'], icon: 'fas fa-shopping-cart' },
        // { label: 'Configuraciones', url: ['/', 'mi-cuenta', 'mis-direcciones'], icon: 'fas fa-cog' },
        // { label: 'Servicios delivery', url: ['/', 'mi-cuenta', 'mis-direcciones'], icon: 'fas fa-crown' },
        // { label: 'Cerrar sesión', url: ['/', 'mi-cuenta', 'login'], icon: 'fas fa-power-off', dark: true }
    ];

    modalRef: BsModalRef;

    // Modal Tienda
    templateTiendaModal: TemplateRef<any>;
    modalRefTienda: BsModalRef;

    public texto = '';
    public textToSearch = '';
    public categorias = [];
    public marcas = [];
    public productosEncontrados: ProductSearchSimple[] = [];
    public mostrarContenido = false;
    public mostrarCargando = false;
    public linkBusquedaProductos = '#';
    public searchControl: FormControl;
    private debounce = 200;
    public buscando = true;
    public mostrarResultados = false;
    public sessionNotStarted = false;
    public tiendaSeleccionada: TiendaLocation;

    public nombreLogin: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private productsService: ProductsService,
        public root: RootService,
        private slugify: SlugifyPipe,
        private toastr: ToastrService,
        public cart: CartService,
        public wishlist: WishlistService,
        public loginService: LoginService,
        public localS: LocalStorageService,
        private geoLocationService: GeoLocationService

    ) {
        this.route.queryParams.subscribe(params => {
            if (params.buscar) {
                // this.texto = params.buscar;
            }
        });

    }


    ngOnInit() {

        this.searchControl = new FormControl('');
        this.searchControl.valueChanges
            .pipe(debounceTime(this.debounce), distinctUntilChanged())
            .subscribe(query => {

                if (query.trim() !== '') {
                    this.textToSearch = query;
                    this.buscarSelect();
                } else {
                    this.categorias = [];
                    this.productosEncontrados = [];
                }
            });

        this.usuario = this.localS.get('usuario');
        this.mostrarNombreLogin();

        this.loginService.loginSessionObs$.pipe().subscribe(usuario => {
            this.usuario = usuario;
            this.mostrarNombreLogin();
            this.mostrarMenu = true;
            this.showWelcome = true;
            this.linkMiCuenta = this.loginService.setRoles();

        });

        if (this.usuario != null) {
            this.linkMiCuenta = this.loginService.setRoles();
        }


        // Tienda seleccionada
        // const geo: GeoLocation = this.localS.get('geolocalizacion');
        // this.tiendaSeleccionada = geo.tiendaSelecciona;

        // this.geoLocationService.localizacionObs$.subscribe((r: GeoLocation) => {
        //     this.tiendaSeleccionada = r.tiendaSelecciona;
        //     if (r.esNuevaUbicacion) {
        //         setTimeout(() => {
        //             this.menuTienda.open();
        //         }, 700);
        //     }
        // });



    }

    mostrarNombreLogin() {
        if (this.usuario.login_temp === false) {
            this.nombreLogin = this.usuario.persona.nombres;
        }
    }


    ngOnDestroy() {
        this.destroy();
    }

    destroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public reset() {
        this.buscando = true;

    }

    public buscar() {

        if (this.textToSearch.trim() === '') {
            this.toastr.info('Debe ingresar un texto para buscar', 'Información');
            return;
        }

        this.router.navigate([`inicio/productos/${this.textToSearch}`]);
        this.mostrarContenido = true;
        this.mostrarCargando = true;
        this.mostrarResultados = false;



        setTimeout(() => {
            this.dropdown.close();
        }, 500);

    }

    public buscarSelect() {

        this.mostrarContenido = true;
        this.mostrarCargando = true;
        this.linkBusquedaProductos = this.textToSearch;

        this.productsService.buscarProductosSimple(this.textToSearch).pipe(takeUntil(this.destroy$)).subscribe((r: ResponseApi) => {
            this.dropdown.open();

            if (r.error) {
                this.toastr.error('Error de conexión con el servidor');
                return;
            }
            this.mostrarCargando = false;
            this.productosEncontrados = r.data.productos;
            this.categorias = this.root.obtenerUrlCategorias(r.data.categorias, this.textToSearch);

        }, error => {
            this.toastr.error('Error de conexión con el servidor');

        });
    }

    public mostraModalBuscador() {
        this.modalRef = this.modalService.show(this.template, { class: 'modal-xl modal-buscador' });
        this.root.setModalRefBuscador(this.modalRef);
    }

    cambiaElementosMenu(value) {
        this.mostrarMenu = value;
    }

    closeWelcome() {
        this.showWelcome = false;
    }

    // Mostrar client
    abrirModalTiendas() {
        this.modalRefTienda = this.modalService.show(this.templateTiendaModal);
    }

    estableceModalTienda(template) {
        this.templateTiendaModal = template;
    }


}
