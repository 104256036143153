import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren, TemplateRef } from '@angular/core';
import { Product, AtributosEspeciales, ProductoFichaImagenes } from '../../interfaces/product';
import { CarouselComponent, SlidesOutputData } from 'ngx-owl-carousel-o';
import { FormControl } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { isPlatformBrowser } from '@angular/common';
import { OwlCarouselOConfig } from 'ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config';
import { PhotoSwipeService } from '../../services/photo-swipe.service';
import { DirectionService } from '../../services/direction.service';


import { RootService } from '../../services/root.service';
import { ToastrService } from 'ngx-toastr';
import { QuickviewService } from '../../services/quickview.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductsService } from '../../services/products.service';
import { ResponseApi } from '../../../shared/interfaces/response-api';

import { ActivatedRoute, Router } from '@angular/router';
import { GeoLocationService } from '../../services/geo-location.service';
import { GeoLocation } from '../../interfaces/geo-location';
import { environment } from '../../../../environments/environment';
import { CalcularTotalComprasService } from '../../../shared/services/calcular-total-compras.service'


export type Layout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
    private dataProduct: Product;
    private dataLayout: Layout = 'standard';

    showGallery = true;
    showGalleryTimeout: number;
    atributosEspeciales: AtributosEspeciales[];
    imageFichaCargada = false;
    quality: any;
    products: Product[] = [];

    @ViewChild('featuredCarousel', { read: CarouselComponent, static: false }) featuredCarousel: CarouselComponent;
    @ViewChild('thumbnailsCarousel', { read: CarouselComponent, static: false }) thumbnailsCarousel: CarouselComponent;
    @ViewChildren('imageElement', { read: ElementRef }) imageElements: QueryList<ElementRef>;
    @ViewChild('modalStock', { read: TemplateRef, static: false }) modalTemplateStock: TemplateRef<any>;

    modalRefStock: BsModalRef;
    @Input() stock: boolean;
    @Input() set layout(value: Layout) {
        this.dataLayout = value;

        if (isPlatformBrowser(this.platformId)) {
            // this dirty hack is needed to re-initialize the gallery after changing the layout
            clearTimeout(this.showGalleryTimeout);
            this.showGallery = false;
            this.showGalleryTimeout = window.setTimeout(() => {
                this.showGallery = true;
            }, 0);
        }
    }
    get layout(): Layout {
        return this.dataLayout;
    }

    @Input() set product(value: Product) {
        if (typeof value === 'undefined') {
            return;
        }

        console.log(value);
        // this.getPopularProducts(value.sku);


        this.quantity.setValue(1);
        this.imageFichaCargada = false;
        this.images = [];
        if (value) {
            this.formatImageSlider(value);
            value = this.calcularTotalComprasService.calcularPrecio(value);
            console.log('sdadasads');
            console.log(value);
            this.dataProduct = value;
        }

        // value.atributos = [atr]


        // this.quality = this.root.setQuality(value);
        // this.root.limpiaAtributos(value);

    }



    get product(): Product {
        return this.dataProduct;
    }

    images: ProductoFichaImagenes[] = [];
    imagesThumbs: ProductoFichaImagenes[] = [];


    carouselOptions: Partial<OwlCarouselOConfig> = {
        // nav: false,
        autoplay: false,
        loop: true,
        responsive: {
            0: { items: 1 }
        },
        rtl: this.direction.isRTL()
    };

    thumbnailsCarouselOptions: Partial<OwlCarouselOConfig> = {
        // dots: true,
        // nav: true,
        autoplay: false,
        margin: 10,
        items: 4,
        responsive: {
            480: { items: 4 },
            380: { items: 4 },
            0: { items: 4 }
        },
        rtl: this.direction.isRTL()
    };

    quantity: FormControl = new FormControl(1);

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;

    headerLayout: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        private photoSwipe: PhotoSwipeService,
        private direction: DirectionService,
        private root: RootService,
        public toast: ToastrService,
        private quickService: QuickviewService,
        private modalService: BsModalService,
        private productsService: ProductsService,
        public router: Router,
        public route: ActivatedRoute,
        public geoLocationService: GeoLocationService,
        private calcularTotalComprasService: CalcularTotalComprasService


    ) {

        // cambio de sucursal
        this.geoLocationService.localizacionObs$.subscribe((r: GeoLocation) => {
            this.getPopularProducts(this.dataProduct.sku);
        });


        this.route.data.subscribe(data => this.headerLayout = data.headerLayout);
        this.root.path = this.router.createUrlTree(['./'], { relativeTo: route }).toString();
    }

    ngOnInit(): void {
        if (this.layout !== 'quickview' && isPlatformBrowser(this.platformId)) {
            this.photoSwipe.load().subscribe();
        }
    }

   

    getPopularProducts(sku) {
        const params = {
            sku
        };
        this.productsService.getPropularProducts(params).subscribe((r: ResponseApi) => {
            if (r.error) {
                this.toast.error(r.msg);
                return;
            }

            this.products = r.data;

        }, err => {
            this.toast.error('Error de conexion con el servidor');
        });
    }

    setActiveImage(image: ProductoFichaImagenes): void {
        this.images.forEach(eachImage => eachImage.active = eachImage === image);
    }

    featuredCarouselTranslated(event: SlidesOutputData): void {
        if (event.slides.length) {
            const activeImageId = event.slides[0].id;

            this.images.forEach(eachImage => eachImage.active = eachImage.id === activeImageId);

            if (!this.thumbnailsCarousel.slidesData.find(slide => slide.id === event.slides[0].id && slide.isActive)) {
                this.thumbnailsCarousel.to(event.slides[0].id);
            }
        }
    }

    addToCart(): void {

        const usuario = this.root.getDataSesionUsuario();

        if (usuario == null) {
            this.toast.warning('Debe iniciar sesion para poder comprar', 'Información');
            return;
        }

        if (!this.addingToCart && this.product && this.quantity.value > 0) {

            this.addingToCart = true;
            this.cart.add(this.product, this.quantity.value).subscribe(r => {
                console.log(r);
                // if (r.error === false) {
                //     this.quickService.hide();
                // }

            }, e => {
                this.toast.warning('Ha ocurrido un error en el proceso', 'Información');
                this.addingToCart = false;


            }, () => {
                this.addingToCart = false;
            }
            );
        }
    }

    addToWishlist(): void {
        if (!this.addingToWishlist && this.product) {
            this.addingToWishlist = true;

            this.wishlist.add(this.product).subscribe({ complete: () => this.addingToWishlist = false });
        }
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare.add(this.product).subscribe({ complete: () => this.addingToCompare = false });
        }
    }

    openPhotoSwipe(event: MouseEvent, image: ProductoFichaImagenes): void {
        if (this.layout !== 'quickview') {
            event.preventDefault();

            const images = this.images.map(eachImage => {
                return {
                    src: eachImage.url,
                    msrc: eachImage.url,
                    w: 700,
                    h: 700
                };
            });
            const options = {
                getThumbBoundsFn: index => {
                    const imageElement = this.imageElements.toArray()[index].nativeElement;
                    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    const rect = imageElement.getBoundingClientRect();

                    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                },
                index: this.images.indexOf(image),
                bgOpacity: .9,
                history: false
            };

            this.photoSwipe.open(images, options).subscribe(galleryRef => {
                galleryRef.listen('beforeChange', () => {
                    this.featuredCarousel.to(this.images[galleryRef.getCurrentIndex()].id);
                });
            });
        }
    }


    onLoadImage() {
        this.imageFichaCargada = true;
    }

    formatImageSlider(product: Product) {
        if (typeof product.productoImagenes === 'undefined') {
            return;
        }


        let index = 0;
        this.images = [];
        this.imagesThumbs = [];

        let key = 0;
        for (const item of product.productoImagenes) {
            console.log(item);
            const image = {
                id: product.codigo_interno.toString() + '_' + index++,
                url: environment.apiMountain + item.file_url,
                urlThumbs: environment.apiMountain + item.file_url,
                active: (index === 1) ? true : false
            };
            this.images.push(image);
            key++;
        }


        if (this.images.length === 0) {
            const image = {
                id: product.codigo_interno.toString() + '_' + index++,
                url: this.root.returnUrlNoImagen(),
                urlThumbs: this.root.returnUrlNoImagen(),
                active: (index === 1) ? true : false
            };
            this.images.push(image);
        }

    }

    showStock() {
        this.modalRefStock = this.modalService.show(this.modalTemplateStock, Object.assign({}, { class: 'modal-xl' }));
    }

}
