import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filters-products',
  templateUrl: './filters-products.component.html',
  styleUrls: ['./filters-products.component.scss']
})
export class FiltersProductsComponent implements OnInit {

  anios = [];
  actual = 2019;

  constructor() {


    // simulamos los años
    for (let index = 0; index < 20; index++) {
      this.anios.push({ nombre: this.actual, id: this.actual });
      this.actual--;
    }
  }

  ngOnInit() {
  }

}
