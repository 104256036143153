import { Component, OnInit } from '@angular/core';
import { reviews } from '../../../../data/shop-product-reviews';

@Component({
  selector: 'app-social-reviews-component',
  templateUrl: './social-reviews.component.html',
  styleUrls: ['./social-reviews.component.scss']
})
export class SocialReviewsComponent implements OnInit {

  socialReviews = reviews;
  
  constructor() { }

  ngOnInit() {
  }

}
