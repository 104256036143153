import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/services/login.service';
import { PlantillasService } from '../../shared/services/plantillas.service';
import { Plantilla } from 'src/app/shared/interfaces/plantilla';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    plantilla: Plantilla;
    constructor(
        public plantillasService: PlantillasService
    ) {

        this.plantilla = this.plantillasService.obtenerPlantilla();
    }


}
