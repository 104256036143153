import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
declare var google: any;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {
  @Input() tienda :any;

  lat: number = 0;
  lng: number = 0;
  zoom: number = 15;
  geocoder: any;
  @Output() public geolocalizacion = new EventEmitter<any>();
  constructor( private mapLoader: MapsAPILoader,private ref: ChangeDetectorRef) { }

  ngOnInit() {
  }
  async ngOnChanges(){
    if(this.tienda){
      this.geocodeAddress(`${this.tienda.direccion} ${this.tienda.zona}`).subscribe((location) => {
        this.lat= location.lat;
        this.lng= location.lng;
        this.geolocalizacion.emit({lat:this.lat,lng:this.lng});
        
      }      
    ); 
    }
    
  }

  geocodeAddress(location: string): Observable<any> {

    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
       
              observer.next({
                lat: results[0].geometry.location.lat(), 
                lng: results[0].geometry.location.lng()
              });
            } else {
             
                observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        })        
      })
    )
  }

  private initGeocoder() {
 
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if(!this.geocoder) {
      return fromPromise(this.mapLoader.load())
      .pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }
}
