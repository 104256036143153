import { Component, ɵConsole, OnInit } from '@angular/core';
import { posts } from '../../../data/blog-posts';
import { brands } from '../../../data/shop-brands';
import { products } from '../../../data/shop-products';
import { categories } from '../../../data/shop-block-categories';
import { ProductsService } from '../../shared/services/products.service';
import { Category } from '../../shared/interfaces/category';
import { BlockHeaderGroup } from '../../shared/interfaces/block-header-group';
import { GeoLocationService } from '../../shared/services/geo-location.service';
import { GeoLocation } from 'src/app/shared/interfaces/geo-location';

@Component({
    selector: 'app-home',
    templateUrl: './page-home-one.component.html',
    styleUrls: ['./page-home-one.component.scss']
})
export class PageHomeOneComponent implements OnInit {
    products = products;
    categories: Category[] = categories;
    posts = posts;
    brands = brands;
    inputCategorias = {
        loading: true,
        categories: null,
        groups: [],
        timeout: null,
        groupChange: (group: BlockHeaderGroup) => {

            console.log(this.categories);
            const selectedCategory = [];
            this.categories.map(r => {

                console.log(r.title + '===' + group.name);

                if (r.title.trim() === group.name.trim()) {

                    r.subcategories.map(item => {

                        selectedCategory.push(item);
                    });
                }

            });

            this.inputCategorias.loading = true;
            clearTimeout(this.inputCategorias.timeout);
            this.inputCategorias.timeout = setTimeout(() => {

                this.inputCategorias.categories = selectedCategory;
                this.inputCategorias.loading = false;
                console.log(selectedCategory);
            }, 500);
        }
    };

    inputCatalogo = {
        loading: true,
        categories: null,
        groups: [],
        timeout: null,
        groupChange: (group: BlockHeaderGroup) => {

            console.log(this.categories);
            const selectedCategory = [];
            this.categories.map(r => {

                if (r.title.trim() === group.name.trim()) {
                    r.subcategories.map(item => {
                        selectedCategory.push(item);
                    });
                }
            });

            this.inputCatalogo.loading = true;
            clearTimeout(this.inputCatalogo.timeout);

            this.inputCatalogo.timeout = setTimeout(() => {

                this.inputCatalogo.categories = selectedCategory;
                this.inputCatalogo.loading = false;
                console.log(selectedCategory);
            }, 500);
        }
    };

    newArrivals = {
        loading: true,
        products: products.slice(0, 3),
        timeout: null
    };

    constructor(
        private productSevice: ProductsService,
        private geoLocationService: GeoLocationService
    ) {
    }

    ngOnInit() {
    }


    private categoriaInicio() {

        const gruposCategorias = categories.map(item => {
            return { name: item.title, current: false };
        });

        // Cargamos la primera categoria por defecto
        gruposCategorias[0].current = true;
        this.inputCategorias.categories = [...this.categories[0].subcategories];
        this.inputCategorias.groups = gruposCategorias;
        this.inputCategorias.loading = false;

    }

    private catalagoProductos() {

        const gruposCategorias = categories.map(item => {
            return { name: item.title, current: false };
        });

        // Cargamos la primera categoria por defecto
        gruposCategorias[0].current = true;
        this.inputCatalogo.categories = [...this.categories[0].subcategories];
        this.inputCatalogo.groups = gruposCategorias;
        this.inputCatalogo.loading = false;
    }






}
